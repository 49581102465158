@import "../global/theme.css";

.pageTopContainer {
  margin-bottom: 30px;
  margin-top: 30px;
}

.pageContentContainer {
  margin-bottom: 20px;
}

.filterRow {
  display: flex;
  margin-bottom: 20px;
}

.newCustomEventButton{
  background-color: var(--primaryColor);
  color: var(--white);
  margin-left: auto;
  border: none;
}

.eventEditorContainer {
  margin-bottom: 20px;
}

.eventEditorContainer:last-child {
  margin-bottom: 0;
}

.modal{
  background-color: var(--bodyBackgroundColor);
}

.search {
  flex-basis: 600px;
  margin-right: 20px;
}

.emptyStateBottomContainer {
    max-width: 1000px;
    color: var(--white);
    font-weight: 600;
    text-align: center;
    justify-content: center;
    background-color: var(--gray400);
    margin: 130px 75px;
    padding: 24px 0;
    opacity: 0.7;
    border-radius: 10px;
}

.emptyStateImage {
    width: 94%;
    padding-top: 30px;
}

.tabs {
    margin-bottom: 20px;
}

.eventSectionTitle {
    display: flex;
    align-items: center;
    margin-top: 21px;
    margin-bottom: 18px;
}

.titleAndEventsCountWrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.eventSectionLabel {
    margin-right: 8px;
}

.eventItemBorder {
    border-left-width: 7px;
    border-left-style: solid;
}

.eventContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: var(--white);
    padding-left: 59px;
}

.eventHeaderContainer {
    height: 62px;
}

.eventHeaderContainer {
    height: 62px;
}

.eventSection {
    margin-bottom: 35px;
}

.eventSectionAction {
    margin-top: 15px;
    display: flex;
}

.standardEventItemBorder {
    border-left-color: var(--purple300);
}

.customEventItemBorder {
    border-left-color: var(--green400);
}

.builtInEventItemBorder {
    border-left-color: var(--turquoise200);
}