:root {
  --blue50: #F2F9FF;
  --blue100: #DEF0FF;
  --blue150: #C3DEF7;
  --blue200: #A3C7F0;
  --blue300: #3A92E5;
  --blue400: #3074B7;
  --blue500: #2B5687;
  --blue600: #1E3F5E;
  --blue800: #0F3384;

  --purple100: #EBE5F1;
  --purple200: #C496ED;
  --purple300: #9B6DCF;
  --indigo: #4C4FEE;

  --green100: #E6F6D4;
  --green200: #A0D697;
  --green300: #5CB85C;
  --green400: #17A38F;

  --turquoise100: #00D0B2;
  --turquoise200: #0BB5D2;

  --orange100: #FCF8E3;
  --orange200: #FFD797;
  --orange300: #FFAA20;
  --orange400: #FF783F;

  --red100: #FBDBDF;
  --red200: #F7C1C8;
  --red300: #E64D5F;
  --red400: #D0021B;

  --pink100: #EB3D89;
  --pink200: #D3367B;
  --pink300: #BC306D;

  --white: #FFFFFF;
  --gray50: #FAFAFA;
  --gray100: #F8F8F8;
  --gray200: #EDEDED;
  --gray150: #F1F1F1;
  --gray300: #E1E1E1;
  --gray400: #CCCCCC;
  --gray500: #AAAAAA;
  --gray600: #777777;
  --gray700: #515864;
  --gray800: #191e21;
  --gray900: #525862;
  --black: #000000;

  --teal100: #DEF6F6;
  --teal300: #017D7D;

  --highlightColor: #FFFF00;
  --pastel-green: #CDE0E4;
  --marketing-gradient: #E1E7FF;
  --bodyBackgroundColor: var(--gray150);
  --sidenavBackgroundColor: var(--blue600);

  --primaryColor: var(--blue300);
  --hoverColor: var(--blue100);
  --textColor: var(--gray700);
  --textColorLight: var(--gray500);
  --textSuccess: var(--green400);
  --textDanger: var(--red400);

  --skanPlusGradientStart: var(--green400);
  --skanPlusGradientEnd: var(--turquoise200);

  --warning1BGColor: var(--orange100);
  --warning1Color: var(--orange300);
  --warning1BorderColor: var(--orange200);

  --error1BGColor: var(--red100);
  --error1Color: var(--red400);
  --error1BorderColor: var(--red200);

  --info1BGColor: var(--blue100);
  --info1Color: var(--blue300);
  --info1BorderColor: var(--blue200);
    
  --success1BGColor: var(--green100);
  --success1Color: var(--green300);
  --success1BorderColor: var(--green200);

  --primaryButtonBackground: var(--blue300);
  --primaryButtonText: var(--white);
  --primaryButtonBackgroundHover: var(--blue400);
  --primaryButtonTextHover: var(--white);
  --primaryButtonBackgroundActive: var(--blue500);
  --primaryButtonTextActive: var(--white);
  --primaryButtonBackgroundDisabled: var(--gray300);
  --primaryButtonTextDisabled: var(--gray600);
  --primaryButtonBackgroundDisabledDark: var(--gray400);
  --primaryButtonTextDisabledDark: var(--gray500);


  --secondaryButtonBackground: var(--white);
  --secondaryButtonText: var(--gray700);
  --secondaryButtonBackgroundHover: var(--gray700);
  --secondaryButtonTextHover: var(--white);
  --secondaryButtonBackgroundActive: var(--gray800);
  --secondaryButtonTextActive: var(--white);
  --secondaryButtonBackgroundDisabled: var(--white);
  --secondaryButtonTextDisabled: var(--gray400);

  --flatButtonBackground: transparent;
  --flatButtonText: var(--gray700);
  --flatButtonBackgroundHover: var(--gray200);
  --flatButtonTextHover: var(--gray700);
  --flatButtonBackgroundActive: var(--gray400);
  --flatButtonTextActive: var(--gray700);
  --flatButtonBackgroundDisabled: transparent;
  --flatButtonTextDisabled: var(--gray400);

  --attention: var(--red400);
  --attentionButtonBackground: var(--red300);
  --attentionButtonText: var(--white);
  --attentionButtonTextHover: var(--white);
  --attentionButtonBackgroundActive: var(--red400);
  --attentionButtonBackgroundHover: var(--red400);
  --attentionButtonTextActive: var(--white);
  --attentionButtonBackgroundDisabled: var(--gray400);
  --attentionButtonTextDisabled: var(--gray200);

  --linkButtonBackground: transparent;
  --linkButtonText: var(--blue300);
  --linkButtonBackgroundHover: transparent;
  --linkButtonTextHover: var(--blue300);
  --linkButtonBackgroundActive: transparent;
  --linkButtonTextActive: var(--blue300);
  --linkButtonBackgroundDisabled: transparent;
  --linkButtonTextDisabled: var(--gray400);

  --newButtonBackground: var(--pink100);
  --newButtonText: var(--white);
  --newButtonBackgroundHover: var(--pink200);
  --newButtonTextHover: var(--white);
  --newButtonBackgroundActive: var(--pink300);
  --newButtonTextActive: var(--white);
  --newButtonBackgroundDisabled: var(--gray400);
  --newButtonTextDisabled: var(--pink100);


  --success1ColorButtonBackground: var(--success1Color);
  --success1ColorButtonText: var(--white);
  --success1ColorButtonBackgroundHover: var(--success1Color);
  --success1ColorButtonTextHover: var(--white);
  --success1ColorButtonBackgroundActive: var(--success1Color);
  --success1ColorButtonTextActive: var(--white);
  --success1ColorButtonBackgroundDisabled: var(--gray300);
  --success1ColorButtonTextDisabled: var(--gray700);


  --skanPlusButtonBackgroundImage: linear-gradient(105deg, var(--skanPlusGradientStart), var(--skanPlusGradientEnd));
  --skanPlusButtonText: var(--white);
  --skanPlusButtonTextHover: var(--white);
  --skanPlusButtonTextActive: var(--white);
  --skanPlusButtonBackgroundDisabled: var(--gray300);
  --skanPlusButtonTextDisabled: var(--gray700);


  --jungleGreenButtonBackground: var(--turquoise100);
  --jungleGreenButtonText: var(--white);
  --jungleGreenButtonBackgroundHover: var(--turquoise100);
  --jungleGreenButtonTextHover: var(--white);
  --jungleGreenButtonBackgroundActive: var(--turquoise100);
  --jungleGreenButtonTextActive: var(--white);
  --jungleGreenButtonBackgroundDisabled: var(--gray300);
  --jungleGreenButtonTextDisabled: var(--gray700);

  --invertedPrimaryButtonBackground: transparent;
  --invertedPrimaryButtonText: var(--primaryColor);
  --invertedPrimaryButtonBackgroundHover: var(--primaryColor);
  --invertedPrimaryButtonTextHover: var(--white);
  --invertedPrimaryButtonBackgroundActive: var(--primaryColor);
  --invertedPrimaryButtonTextActive: var(--white);
  --invertedPrimaryButtonBackgroundDisabled: var(--gray300);
  --invertedPrimaryButtonTextDisabled: var(--gray700);

  --primaryLight: white;
  --primaryDisabledDark: var(--gray400);
  --primaryDisabledLight: var(--gray100);

  --secondaryLight: white;
  --secondaryDark: var(--gray700);
  --secondaryHover: var(--gray700);
  --secondaryActive: var(--gray800);
  --secondaryDisabled: var(--gray400);

  --flatHover: var(--gray200);
  --flatDisabled: var(--gray400);


  --generalLabelLight: var(--gray700);

  --buttonBorderRadius: 3px;
  --inputBorderRadius: 2px;

  --pageWrapperLeftPadding: 40px;
  --pageWrapperTopPadding: 30px;

  --stickyZIndex: 100;

  --container-border: 1px solid var(--gray400);

  --grayReactCodeBlockText: #a9b7c6;

  --blackBgReactCodeBLockTab: #282b2e;

  --single-text-margin: 8px;

  --double-text-margin: 16px;

  --onboardingPlanningHeight: 60vh;

  --icon-size-small: 18px;

  --font-size-small: {
    font-size: 13px;
    font-weight: 400;
  };

    --1st-title : {
        font-size: 30px;
        font-weight: 300;
    };
    --2nd-title : {
        font-size: 24px;
        font-weight: 400;
    };
    --3rd-title : {
        font-size: 18px;
        font-weight: 400;
    };
    --4th-title : {
        font-size: 16px;
        font-weight: 400;
    };

  --font-size-medium: {
    font-size: 16px;
    font-weight: 500;
  };

  --font-size-medium-light: {
    font-size: 16px;
    font-weight: 300;
  };

  --font-size-medium-large: {
    font-size: 20px;
    font-weight: 300;
  };

  --font-size-large: {
    font-size: 30px;
    font-weight: 300;
  };

  --font-tooltip: {
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    color: var(--gray700)
  };

   --font-paragraph: {
     font-size: 14px;
     font-weight: 400;
     color: var(--gray700)
   };

   --font-button: {
     font-size: 13px;
     font-weight: 600;
   };

  --font-dropdown-item: {
    font-weight: 400;
    font-size: 13px;
  };

  --font-input: {
     font-size: 14px;
     font-weight: 400;
     color: var(--gray700)
   };

  --font-input-label: {
     font-size: 13px;
     font-weight: 400;
     color: var(--gray600);
     line-height: 26px;
     cursor: default;
  };
}

.primaryButton {
  background-color: var(--blue300);
  color: var(--primaryLight);
  &:hover {
    background-color: var(--blue400);
  }
  &:focus {
    background-color: var(--blue500);
  }
}

@custom-media --non-mobile (min-width: 600px);
@custom-media --mobile (max-width: 600px);
@custom-media --mobile-narrow (max-width: 340px);
@custom-media --mobile-short (max-height: 570px);

@media (--non-mobile) {
  :global(.showInMobile) {
    display: none;
  }
}

@media (--mobile) {
  :global(.showInNonMobile) {
    display: none;
  }
}