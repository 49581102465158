@import "../../global/theme.css";
@import "../../global/general.css";

.container {
    padding: 16px 44px;
}

.fieldGroupHolder {
    margin-bottom: 25px;
}

.stickyCollapsedSector {
    position: sticky;
    top: 95px;
    z-index: 99;
    background-color: inherit;
    padding: 0 var(--pageWrapperLeftPadding);
}

.fieldGroupHolderCollapse {
    padding-bottom: 15px;
}

.collapsedIcon {
    margin-right: 8px;
    fill: var(--blue300);
}

.collapsedText {
    width: 76px;
    display: inline-block;
}

.chevronIcon {
    margin-left: 6px;
    fill: var(--blue300);
    width: 15px;

    &.collapsed {
        transform: rotate(180deg);
    }

    &.expanded {
        height: 10px;
    }
}


.divider {
    height: 1px;
    width: 100%;
    background-color: var(--gray100);
}

.shelfContainer {
    padding: 25px var(--pageWrapperLeftPadding);
}

.unifiedBanner {
    margin-top: 20px;
    display: flex;
    width: 100%;
}

.dataContainer {
    /* min-height - in case there's no data in the table and we hide the chart, the datepicker is being cut when opened */
    min-height: 350px;
    padding: 20px var(--pageWrapperLeftPadding);
}

.hideElementDivider {
    text-align: center;
    width: 100%;
    color: var(--blue300);
    border-bottom: 1px solid var(--gray400);
    height: 10px;
    margin-top: 10px;
    margin-bottom: 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    &:hover {
        color: var(--blue400);
    }

    & span {
        background-color: var(--bodyBackgroundColor);
        padding: 0 10px;
        font-size: 14px;
        cursor: pointer;
    }
}

.gridContainer {
    padding-top: 15px;
}

.gridContainerWhenNoChart {
    padding-top: -5px;
}

.loadingContainer {
    margin-bottom: 10px;
}

.fieldsAvailabilityShelfIcon {
    fill: var(--white);
    margin: 0 16px 2px 0;
    vertical-align: middle;
}

.innerInfoMessages {
    margin-top: 5px;
}
