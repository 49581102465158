@import "../../global/theme.css";

.singleValue {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .value {
        display: flex;
        overflow: hidden;
    }

    & .icon {
        padding-right: 10px;

        &.disabled {
            opacity: 0.5;
        }
    }

    & .checkmark {
        display: block;
        fill: var(--green300)
    }
}