@import "../../../global/theme.css";

.dropdownWithLabel {
    display: flex;
    justify-content: space-between;
    &:first-child{
        margin-right: 20px;
    }
}

.dropdownContainer {
    width: 438px;
    margin-bottom: 26px;
}

.dropdownIcon {
    color: var(--gray500);
}

.labelContainer {
    margin-right: 10px;
}