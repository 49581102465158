@import "../../global/theme.css";
@import "../styles.css";

.preamble {
  margin: 0;
  flex-grow: 1;
}

.contentArea {
  flex-shrink: 1;
  overflow: auto;
}

.expandableGroupHolder {
  margin-bottom: 2px;
}

.groupContentHolder{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.groupContentHolder:after {
  content: '';
  flex: 1 0 31%;
  max-width: 31%;
}

.iconItemHolder {
  margin-bottom: 10px;
  flex: 1 0 31%;
  max-width: 31%;
}

.iconItemHolder:last-child {
  margin-bottom: 0;
}

.userDetailsArea {
  display: flex;
  font-weight: 500;
}

.userDetailsItem {
  margin-right: 40px;
}

.userDetailsLabel {
  font-weight: 300;
  color: var(--textColorLight);
}

.iconItemContainer {
  display: flex;
  align-items: center;
}

.iconItemIcon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.iconItemLabel {
  display: inline;
  font-weight: 300;
  color: var(--gray700);
  margin-left: 10px;
}

.section {
  font-weight: 500;
}

.sectionArea {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.screen {
  display: flex;
  align-items: center;
  margin-left: 10px;
  & span {
    margin-left: 5px;
    color: var(--gray700)
  }
  & .screenIcon {
    width: 10px;
    height: 10px;
    color: var(--textColorLight);
  }
}

.metricsList {
  margin-left: 5px;
  color: var(--gray700)
}

.addFutureMessage {
  color: var(--gray700);
  font-size: 13px;
  margin-left: 10px;
}

.userTypeText {
  color: var(--textColorLight);
  font-weight: 500;
  font-size: 24px;
}

.agencyText {
  font-size: 24px;
}
