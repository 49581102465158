@import "../../global/theme.css";

.container {
    display: inline-flex;
    font-weight: 400;
    font-size: 16px;
    color: var(--gray700);
    text-decoration: none;

    & :global(.link-underline) {
        color: var(--blue300);
        font-weight: 500;
        text-decoration: none;

        &:hover:not([disabled]):not(:active) {
            color: var(--blue400);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    & .tooltip {
        margin-left: 10px;
    }

    & .icon {
        width: 12px;
        margin-right: 8px;
        align-self: center;
    }
}

.h1, .h2, .h3, .h4 {
    display: block;
}

.h1 {
    font-size: 21px;
}

.h2 {
    font-size: 18px;
    font-weight: 500;

    @media (--mobile) {
        font-size: 14px;
    }
}

.h3 {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.h4 {
    font-size: 12px;
    font-weight: 400;
}

.shelfHeader {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
    color: var(--gray700);
    line-height: 24px;
}

.p {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray700);
    line-height: 1.43;

    @media (--mobile) {
        font-size: 12px;
    }
}

.subtitle {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray800);
    padding-bottom: 10px;
}

.fieldLabel {
    font-size: 13px;
    font-weight: 400;
    color: var(--gray600);
    padding-bottom: 5px;
}

.tableTd {
    font-size: 13px;
    font-weight: 400;
    color: var(--gray700)
}

.tableTh {
    font-size: 13px;
    font-weight: 600;
    color: var(--gray700)
}

.dialogTitle {
    font-size: 24px;
    font-weight: 600;
    color: var(--gray700);
    text-align: center;
    width: 100%;
}

.dialogBody {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray700);
}

.sideLabel {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray700);
    line-height: 2.5;
}

.mandatory::before {
    content: "* ";
    color: var(--red300);
    margin-right: 3px;
}

.error {
    color: var(--red300);
    font-size: 12px;
}

.header {
    font-size: 24px;
    font-weight: 600;
    color: var(--gray800);
    text-align: left;
    text-transform: capitalize;
}
