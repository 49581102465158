@import "../../global/theme.css";

:root {
  --collapsedItemMarginX: 4px;
  --collapsedItemMarginY: 6px;
  --collapsedContainerPaddingRight: 40px;
  --collapsedContainerPaddingOther: 20px;
  --admonBackgroundColor: #ead4fb;
  --creativeBackgroundColor: #c2e4ec;
}

.deleteDimensionContainer {
  color: var(--gray400);
  cursor: pointer;

  & path {
    fill: var(--gray400);
  }

  &:hover {
    & path {
      fill: var(--red300);
    }
  }
}

.customDimensionValue {
  height: 50px;
  background-color: var(--blue100 );
  display: flex;
  padding: 16px 30px;
  position: sticky;
  align-items: center;
  z-index: 2;

  .trashIcon {
    cursor: pointer;

  }

  &.admon {
    background-color: var(--admonBackgroundColor) !important;
  }

  &.creative {
    background-color: var(--creativeBackgroundColor) !important;
  }
}

.deleteText {
  color: var(--gray600);
  font-size: 14px;
  margin-right: 8px;
  margin-top: 1px;
  opacity: 0;
  transition: opacity 0.2s linear;
  &.visible {
    opacity: 1;
  }
}

.dot:after {
  content: '•';
}

.rule0dotFirst {
  color: var(--gray700);
  font-size: 18px;
  margin-right: 50px;
  margin-top: 6px;
  margin-left: -1px;
  z-index: 1;
  &.markBeforeDeletion {
    color: var(--red300);
  }
}

.rule0dotLast {
  color: var(--gray700);
  font-size: 18px;
  margin-right: 12px;
  position: absolute;
  bottom: 6px;
  margin-left: -1px;
  z-index: 1;
  &.markBeforeDeletion {
    color: var(--red300);
  }
}

.rule1dot {
  color: #B26AF1;
  position: absolute;
  font-size: 24px;
  margin-top: -6px;
  margin-left: -19px;
  z-index: 1;
  &.markBeforeDeletion {
    color: var(--red300);
  }
}

.rule2dot {
  color: var(--turquoise100);
  position: absolute;
  font-size: 30px;
  margin-top: -12px;
  margin-left: 86px;
  z-index: 1;
}

.level1rule {
  margin-left: 30px;
  padding-bottom: 40px;
  &:hover {
    & .addRuleContainer {
      opacity: 1;
    }
  }
}

.level0ruleContainer {
  padding-left: 70px;
}

.conditionBox {
  margin-top: 24px;
  margin-bottom: 24px;
  width: calc(100% - 100px);
  padding-top: 2px;
  margin-left: 16px;
  height: 26px;
  background-color: var(--gray200);
  border: 1px solid var(--gray300);
  display: flex;

  &:hover {
    & .xButton {
      opacity: 1;
    }
  }
  & .xButton {
    opacity: 0;
    margin-right: 16px;
    margin-top: 5px;

    & path {
      fill: var(--gray600);
    }
  }
}

.orConditionBox {
  height: 32px;
  padding-top: 5px;
  margin-left: -20px;
  width: calc(100% - 14px);
}

.addRuleContainer {
  opacity: 0;
  position: absolute;
  bottom: 0;
  display: inline-flex;
  margin-left: -18px;
  transition: opacity 0.2s linear;
}

.addRuleButtonContainer {
  position: absolute;
  bottom: 0;
  right: -98px;
}

.blackLine {
  width: 1px;
  border-left: 1px solid var(--gray700);
  position: absolute;
  height: calc(100% - 35px);
  margin-top: -34px;
  margin-left: 2px;

  &.markBeforeDeletion {
    border-left: 1px dashed var(--red300);
  }

  &.blackLineFirstElement {
    height: calc(100% - 39px);
    margin-top: 20px;
  }
  &.blackLineConnectToNextElement {
    height: 58px;
    bottom: -39px;
  }
}

.dashedLine {
  width: 1px;
  border-left: 1px dashed var(--gray700);
  position: absolute;
  height: 30px;
  bottom: 23px;
  margin-left: 90px;
  transition: opacity 0.2s linear;
}

.xButton {
  height: 12px !important;
  width: 12px !important;
  cursor: pointer;

  & path {
    fill: var(--gray400);
  }

  &:hover {
    & path {
      fill: var(--red300);
    }
  }
}

.footerBlock {
  background-color: var(--gray100);
  padding: 16px 32px;
  position: relative;
  margin-top: 8px;
}

.valuesActionsButtonsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .addValueButton {
    padding: 12px 16px;
    align-items: center;

    .addValueButtonContent {
      gap: 8px;

      .addValueIcon {
        height: 14px;
        width: 14px;
        fill: var(--white);
        margin: unset;
      }

    }
  }

  .reorderValuesButton {
    display: flex;
    gap: 8px;
    align-items: center;

    .reorderValuesIcon {
      fill: var(--blue300);
    }
  }
}

.footerButtonContainer {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
}

.andOrIcon {
  margin-top: 3px;
  height: 15px;
  width: 15px;
}

.collapsedContentContainer {
  padding: calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginY))
           calc(var(--collapsedContainerPaddingRight) - var(--collapsedItemMarginX))
           calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginY))
           calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginX));
  display: flex;
  background-color: var(--gray100);
  flex-flow: wrap row;
}

.collapsedValueItem {
  padding: 8px;
  margin: var(--collapsedItemMarginY) var(--collapsedItemMarginX) var(--collapsedItemMarginY) var(--collapsedItemMarginX);
  background-color: var(--blue150);
  font-size: 12px;
  border-radius: 2px;
  &.admon {
    background-color: var(--admonBackgroundColor) !important;
  }
  &.creative {
    background-color: var(--creativeBackgroundColor) !important;
  }
}

.defaultValueContainer {
  width: 313px;
  margin-top: 8px;
}

.level0rule {
  display: flex;
  width: calc(100% - 50px);
}

.customDimensionCalendarIcon {
  margin-right: 15px;
  margin-top: 3px;
  fill: var(--orange300);
}

.overrideArrowArea {
  margin-right: 10px;
}
