@import '../../global/theme.css';

.button {
}

.item {
}


.container {
    position: relative;
    display: inline-block;
    outline: none;
}

.dropdown {
    & * {
        outline: none;
    }

    cursor: pointer;
    list-style: none;
    padding: 0;
    border: 1px solid;
    border-color: var(--gray400);
    font-weight: 300;
    background-color: var(--white);
    width: 190px;
    margin: 0;

    & li {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px;
        border-bottom: 1px solid;
        border-color: var(--gray200);
        line-height: 20px;

        & .sideBadge {
            color: var(--blue400);
            text-transform: capitalize;
            font-weight: 500;
            font-size: 10px;
            padding-left: 3px;
            vertical-align: super;
        }

        &:hover {
            background-color: var(--hoverColor);
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
