@import "../../../global/theme.css";

.container {
    width: 100%;

    & > span {
        border: 1px solid var(--gray300) !important;
        border-top: unset !important;
        border-radius: 0 !important;

         & code {
            border-top: unset;
            width: 100%;
            background-color: unset;
            color: var(--grayReactCodeBlockText);
            & span {
                user-select: unset !important;

                &:is(.label) {
                    color: var(--grayReactCodeBlockText);
                    padding: 0;
                }

                background-color: unset !important;
            }
        }
    }

    & > .codeBlockWrapper {
        border: unset !important;
        border-top: unset !important;
        border-radius: unset !important;
    }
}
