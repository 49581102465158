@import "../../../global/theme.css";

.adminModeContainer {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 65px;
    align-items: center;

    & .adminModeIcon {
        fill: var(--blue300);
        width: 16px;
        height: 16px;

        &.disabled {
            fill: var(--gray400);
        }
    }

    &.disabled {
        color: var(--gray400);
    }
}
