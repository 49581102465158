@import '../../global/theme.css';

.container {
    display: flex;
    margin-left: 15px;
    align-items: center;

    &.expanded .arrowArea {
        transform: rotate(0);
        margin-top: 8px;
    }
}

.logo {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    margin-right: 20px;
    &.emptyLogo {
        border: 1px solid var(--gray300);
    }
    &.customLogo {
        fill: #797d85;
        width: 26px;
        height: 26px;
        & * {
          fill: #797d85;
        }
    }
}

.title {
    color: var(--gray800);
}

.subTitle {
    font-size: 13px;
    color: var(--gray600);
}

.arrowArea {
    margin-left: auto;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
}

.arrow {
    color: var(--primaryColor) !important;
    width: 32px !important;
    height: 32px !important;
}