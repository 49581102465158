@import "../../global/theme.css";

.container{
  height: 36px;
  padding: 0 16px;
  background-color: var(--primaryColor);
  color: var(--white);
  display: inline-block;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  @apply --font-button;
}

.container:hover {
  background-color: var(--blue300);
}

.container:active {
  transform: translateY(2px);
}

.container.opened {
  background-color: var(--blue500);
}

.buttonInner{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}