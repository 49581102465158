@import "../../global/theme.css";

.logsEmptyStateWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    & .mainLoaderContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 15px;

        & .mainLoader {
            color: var(--gray400)
        }

        & .imgMain {
            width: 300px;
            height: 300px;
        }

        & .imgSub {
            width: 230px;
            height: 230px;
            transition: all 600ms ease-in-out;
        }

        & .label {
            margin: 15px 0;
            width: 700px;
            justify-content: center;
        }
    }

    & .faqText {
        display: flex;
        align-self: center;
        flex-direction: column;
        margin: 20px 0;

        & .header {
            align-self: center;
            margin-bottom: 5px;
        }

        & .accordion {
            width: 720px;
            text-align: left;
        }
    }

    & .emptyStateText {
        margin-top: 22px;
        margin-bottom: 10px;
    }
}
