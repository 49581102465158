@import "../../../../global/theme.css";

.urlOptions {
    margin-bottom: 5px;
}

.timesToDisplay {
    width: 100px;
}

.urlPatternContainer {
    display: flex;
    align-items: flex-end;
    cursor: default;
    margin: 5px 0;

    &:last-child {
        margin-bottom: 15px;
    }

    & .select {
        width: 130px;
        margin-right: 5px;
    }

    & .value {
        flex: 1;
        overflow-x: clip; /* can not be hidden - 'visible' will be interpreted as 'auto'*/
        overflow-y: visible;
    }

    & .iconsContainer {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        & .icon {
            cursor: pointer;
            margin-left: 10px;
            transition: opacity 0.3s ease-in-out;

            &.disabled {
                opacity: 0.3;
                cursor: default;
                pointer-events: none;
            }

            &.xIcon {
                height: 11px;
                width: 11px;

                & path {
                    fill: var(--gray600);
                }
                &:hover:not(.disabled) {
                    & path {
                        color: var(--red300);
                        fill: var(--red300);
                    }
                }
            }

            &.plusIcon {
                height: 15px;
                width: 15px;

                &:not(.disabled) {
                    color: var(--blue300);
                    fill: var(--blue300);
                }

                &:hover:not(.disabled) {
                    color: var(--blue400);
                    fill: var(--blue400);
                }
            }
        }
    }
}

.platformsContainer {
    display: flex;

    & .platformDeviceTypes {
        display: flex;
        margin-top: 8px;
        margin-right: 60px;

        & .platformLabel {
            font-size: 13px;
            font-weight: 600;
            color: var(--gray600);
        }

        & .deviceTypes {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            & .deviceTypeCheckbox {
                margin-top: 0;
                margin-bottom: 8px;
                font-size: 13px;
            }
        }
    }
}



