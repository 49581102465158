@import "../../global/theme.css";

.container {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  right: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: var(--white);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.container.open {
  visibility: visible;
  opacity: 1;
}

.arrow {
  position: absolute;
  top: -6px;
  right: 0;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
}