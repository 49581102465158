@import "../../global/theme.css";

.container {
  width: 247px;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @apply --font-size-small;
  color: black;
  & * {
    outline-width: 2px;
  }
  text-align: left;
}

.bookmarkRow {
  margin: 6px 0;
}

.nameInput {
  width: 100%;
  margin-top: 6px;
  border-radius: 3px;
  border: 1px solid var(--gray400);
  padding: 6px 12px;
}


.shareContainer{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.shareCheckbox{
  margin: 0 6px 0 0 !important;
  font-weight: 400;
}

.sharedReportLineButtons{
  display: flex;
  border-top: 1px solid #B8B7B3;
  width: 100%;
  padding-top: 12px;
}

.buttonSecondary{
  background-color: transparent !important;
  color: var(--blue400) !important;
  border-color: var(--blue400) !important;
}

.button:hover, .buttonSecondary:hover {
  background-color: color(var(--blue400) b(+10%));
}

.button:active, .buttonSecondary:active {
  transform: translateY(2px);
}

.button[disabled]:active, .buttonSecondary[disabled]:active {
  transform: translateY(0);
}

.radioLabel span{
  font-weight: 300;
  margin-left: 10px;
}

.radioLabel {
  margin-bottom: 0;
}

.radioLabel:last-child{
  margin-left: 20px;
}

.dateSelectionContainer{
  width: 100%;
  margin: 12px 0;
}

.numOfDaysInput{
  width: 30px;
  text-align: center;
  -moz-appearance:textfield;
  margin: 0 6px 0 0;
  border-radius: 3px;
  border: 1px solid var(--gray400);
}

.numOfDaysInput::-webkit-inner-spin-button,
.numOfDaysInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.numOfDaysContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.error {
  color: var(--textDanger);
  margin-bottom: 12px;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
