@import "../../global/theme.css";

.tableCellWithRightElement {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .rightAreaSection {
        text-align: right;

        & .rightAreaText {
            font-style: italic;
            margin-left: 5px;
        }
    }
}

.disabled {
    cursor: not-allowed;
    color: grey;
    opacity: 0.5;
}

.tooltipIcon {
    margin: 5px;
    width: 14px;
    fill: var(--gray700);
    cursor: pointer;
}
