@import '../../global/theme.css';

.container {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 16px;
    font-weight: 300;
    &:hover {
        & .linkName {
            border-bottom: 1px dashed var(--gray400);
            padding-top: 1px;
        }
        & .buttonsArea {
            opacity: 1;
        }
    }
}

.buttonsArea {
    margin-left: auto;
    opacity: 0;
    display: flex;
    align-items: center;
}

.buttonContainer {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 20px;
    &.edit * {
        fill: var(--gray600) !important;
        &:hover * {
            fill: var(--gray700) !important;
        }
        &.active * {
            fill: var(--gray800) !important;
        }
    }

    &.delete:hover * {
        fill: var(--error1Color) !important;
    }
}

.linkName {
    flex: 1;
    background-color: transparent;
    border: none;
    &.editing {
        border-bottom: 1px solid var(--gray400) !important;
    }
}
