@import "../../global/theme.css";

.qrCodeContainer {}

.qrCodeTopArea {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    & .qrCodeLabel {
        font-size: 13px;
        font-weight: 600;
    }

    & .downloadLink {
        background-color: transparent;
        border: none;
        color: var(--blue300);
        cursor: pointer;
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
        padding: 0 5px;
        text-align: left;
        text-decoration: none;
        &:hover:not([disabled]):not(:active) {
            color: var(--blue400);
        }
        &[disabled] {
            color: var(--blue100);
            cursor: default;
        }
        &:active:not([disabled]) {
            color: var(--blue500);
        }
        &:not(:last-child) {
            border-right: 1px solid black;
        }
    }
}

.qrCodeBox {
    background-color: white;
    border-color: var(--gray400);
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    margin: auto;
    padding: 6.5px;

    & .qrCodeInnerBox {
        height: 100px;
        margin: auto;
        width: 100px;
    }
}
