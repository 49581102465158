@import "../../global/theme.css";
@import "../styles.css";

.attributeValue {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 16px;
}

.tableWrapper {
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
  & table {
    & tbody {
      & tr {
        & td {
          height: 50px;
        }
      }
    }
  }
}

.tableHead {
  & .tableHeadRow {
    height: 40px;
    & th {
      background-color: var(--primaryDisabledLight);
      text-align: center;
      font-weight: 500;
    }
  }
}

.clickToCopyCell {
  & .clickToCopy {
    width: 100px;
    text-align: right;
    visibility: hidden;
    display: inline;
    float: right;
    cursor: pointer;
    color: var(--primaryColor);
    &:hover {
      color: var(--blue300);
      text-decoration: underline;
    }
  }
  &:hover {
    & .clickToCopy {
      visibility: visible;
    }
  }
}
