@import "../../global/theme.css";

.networks {
    display: flex;
    flex-wrap: wrap;
}

.networkContainer {
    width: 25%;
    &:last-child:after {
        content:'';
        flex-grow: 1;
    }
}

@media(max-width: 1073px) {
   .networkContainer {
       flex-basis: 33.33%;
  }
}
@media(max-width: 815px) {
  .networkContainer {
      flex-basis: 50%;
  }
}
@media(max-width: 555px) {
  .networkContainer {
      flex-basis: 100%;
  }
}

.network {
    padding: 10px;
}

.networkInner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #F5F5F5;
    height: 180px;
    border-radius: 2px;
    border: 8px solid white;
    cursor: pointer;
    & img {
        max-height: 75%;
        max-width: 75%;
        opacity: 0.4;
        filter: grayscale(100%);
    }
    & .networkConfigure {
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 16px;
        top: calc(50% - 8px);
        user-select: none;
        opacity: 0;
    }
    &:hover .networkConfigure {
        opacity: 1;
    }
    &:hover img {
        opacity: 0.1;
    }
}

.configured {
    background-color: white;
    & img {
        opacity: 1;
        filter: none;
    }
}

.activity {
    position: absolute;
    top: 8px;
    left: 8px;
}
.activityDot {
    border-radius: 100%;
    height: 14px;
    width: 14px;
    background-color: var(--orange300);
    display: inline-block;
    vertical-align: middle;
}
.active {
    background-color: var(--blue300);
}
.activityLabel {
    color: var(--gray700);
    font-size: 12px;
    font-weight: 200;
    display: inline-block;
    line-height: 14px;
    margin-left: 6px;
}
