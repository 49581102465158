@import "../styles.css";

:root {
  --checkDiameter: 26px;
  --innerCheckDiameter: 18px;
  --checkPadding: 20px;
}

.roleItem {
  height: 90px;
  background-color: var(--white);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.textContainer {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.roleType {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: var(--gray800);
  margin-bottom: 12px;
}

.roleDescription {
  height: 22px;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--gray700);
}

.page {
  & input {
    margin: 20px !important;
  }

  & [type="radio"]:checked,
  & [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding-left: calc(var(--checkDiameter) + 2 * var(--checkPadding));
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: var(--gray700);
    }

    & + label:before {
      content: '';
      position: absolute;
      width: var(--checkDiameter);
      height: var(--checkDiameter);
      border: 1px solid var(--gray300);
      border-radius: 100%;
      background: var(--white);

      left: calc(var(--checkPadding) + var(--checkDiameter) / 2);
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out;
    }

    & + label:after {
      content: '';
      width: var(--innerCheckDiameter);
      height: var(--innerCheckDiameter);
      background: var(--blue300);
      position: absolute;
      border-radius: 100%;
      left: calc(var(--checkPadding) + ((var(--checkDiameter) - var(--innerCheckDiameter)) / 2));
      top: calc(50% - (var(--innerCheckDiameter) / 2));
      transition: all 0.2s ease-in-out;
    }
  }

  & [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  & [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  & label {
    margin: 0;
  }
}
