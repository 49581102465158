@import "../../global/theme.css";

.container {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    color: var(--gray500);
}

.sliderContainer {
}

.label {
    font-weight: 300;
    color: var(--gray700);
    display: flex;
    align-items: center;
    & > * {
        margin-left: 6px;
    }
    & > *:first-child {
        margin-left: 0;
    }
}

.valueContainer {
    height: 25px;
    & div {
        position: absolute;
        transform: translate(-50%, 0%);
    }
}

.stepsContainer {
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
    & .extraStep {
        position: absolute;
    }
    & div {
        transform: translate(-50%, 0%);
    }
    & div:last-child {
        margin-left: auto;
        transform: translate(50%, 0);
    }
}

/* overrides */

.tooltip {
    font-size: 10px !important;
    margin: 7px !important;
}

.tooltipPopper {
    z-index: 9 !important;
}