@import '../global/theme.css';

.pageWrapper{
    min-width: 850px;

    & .tabs {
        margin-bottom: 20px;
    }
}

.emptyStateBox {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 29px;

    & .emptyStateBoxTitle {
        margin-bottom: 20px;
    }
}

.actionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .middleButtonPanel {

        & .auditToolTip {
            height: 30px;
            padding-left: 12px;
        }
    }

    .filters {
        width: 720px
    }

    .filtersHide {
        opacity: 0;
    }

    .addDeviceButton {
        margin-left: 20px;
    }
}

.tagLabelClass {
   color: var(--gray800);
}

.tagInputClass {
    min-width: 50%;
    width: initial;
}

.tagInputEmptyClass {
    border: 2px solid var(--blue300);
}

.appsiteIcon {
    width: 22px;
    height: 22px;
}

.appsiteSelection {
    height: 40px;
}

.osIcon {
    width: var(--icon-size-small);
    height: auto;
    fill: var(--gray500);
}
