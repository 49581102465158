@import "../../../global/theme.css";

.pageContainer {
    display: flex;
    border-radius: 8px;
    border: solid 2px var(--turquoise100);
    background-color: var(--white);
    padding: 10px 10px 14px 27px;
    justify-content: space-between;

    & .pipelineImage {
        width: 400px;
        user-select: none;
    }

    & .ladyImage {
        width: 207px;
        position: relative;
        top: 15px;
        right: 15px;
    }

    & .closeIcon {
        align-self: start;
        width: 11px;
        cursor: pointer;
    }

    @media (max-width: 1550px) {
        & .pipelineImage {
            display: none;
        }

        & > * {
            margin-bottom: 10px;
        }
    }

    & .title {
        display: block;
        font-size: 18px;
        font-weight: 600;
    }

    & .subTitle {
        display: block;
        font-size: 14px;
        margin-top: 6px;
    }

    & .todoList {
        display: block;

        & .taskInList {
            display: flex;
            margin-top: 17px;

            & .indexNumber {
                border-radius: 50%;
                width: 22px;
                height: 22px;
                background-color: var(--turquoise100);
                color: white;
                text-align: center;
                font-weight: bold;
                padding-top: 1px;
                font-size: 14px;
                margin-top: -1px;
            }

            & .text {
                list-style-type: none;
                margin-left: 13px;
            }

            & .goButton {
                margin-left: 8px;
                margin-top: -6px;
            }

            & .checked {
                margin-left: 8px;
                width: 20px;
                height: 20px;
                fill: var(--turquoise100);
            }

            & .textGoButton {
                margin: auto;
            }
        }
    }
}
