@import "../../global/theme.css";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stepperIconContainer {
    &.midStep {
        & .stepperIcon {
            top: -10px;
            width: 13px;
            height: 13px;
        }
    }

    & .stepperIcon {
        background-color: white;
        height: 17px;
        width: 17px;
        border-radius: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .stepperIconLine {
        height: 2px;
        position: absolute;
        background-color: var(--white);
        left: 0;
        top: -20px;
        bottom: 0;
        right: 0;
        margin: auto;

        &.stepperIconLineRight {
            left: 50%;
        }

        &.stepperIconLineLeft {
            right: 50%;
        }
    }

    &.completed {
        cursor: pointer;

        &.completedWithIcon {
            & .stepperIcon {
                border: unset;
            }
        }

        & .stepperIcon {
            background-color: var(--gray700);
        }

        & .stepperIconLine {
            background-color: var(--gray700);
        }
    }

    &.active {
        & .stepperIcon {
            border: solid 2px var(--gray700);
            background-color: white;
        }

        & .stepperIconLineLeft {
            background-color: var(--gray700);
        }
    }

    &.first {
        & .stepperIconLine {
            left: calc(100% / 2);
        }
    }

    &.last {
        & .stepperIconLine {
            right: calc(100% / 2);
        }
    }
}

.stepConnector {
    flex: 1 1 auto;
    background-color: white;
    margin-bottom: 10px;
    height: 2px;

    &.done {
        background-color: var(--gray700);

        /* Fixes issue with lines when going back to previous steps */

        & + div {
            & .stepperIconLineLeft {
                background-color: var(--gray700);
            }

            & .stepperIcon {
                border: solid 2px var(--gray700);
            }
        }
    }
}

.stepper {
    & :global(.MuiStepLabel-label) {
        font-size: 13px;
     }

    & :global(.MuiStep-root) {
        padding: 0;
      }
}

:global(.blueThemeWizard) {
   & :global(.MuiStep-horizontal) {
       height: 50px;
   }

  & .stepper {
    background-color: var(--white);
  }
  & .stepperIconContainer {
      margin-bottom: 10px;
    & .stepperIcon {
      background-color: var(--gray400);
      color: var(--white);
    }
    & .stepperIconLine {
      background-color: var(--gray400);
    }
    &.completed {
      & .stepperIcon {
        background-color: var(--blue300);
      }

      & .stepperIconLine {
        background-color: var(--blue300);
      }
    }

    &.active {
      & .stepperIcon {
        border: solid 2px var(--blue300);
        background-color: var(--blue300);
      }
      & .stepperIconLineLeft {
        background-color: var(--blue300);
      }
    }
  }

  .stepConnector {
    background-color: var(--gray400);

    &.done {
      background-color: var(--blue300);

      & + div {
        & .stepperIconLineLeft {
          background-color: var(--blue300);
        }

        & .stepperIcon {
          border: solid 2px var(--blue300);
          background-color: var(--blue300);
        }
      }
    }
  }
}

:global(.turquoiseThemeWizard) {
   & .stepper {
     background-color: var(--pastel-green);
   }

  & .stepperIconContainer {
    & .stepperIcon {
      color: var(--white);
    }

    & .stepperIconLine {
    }

    &.completed {
      & .stepperIcon {
        background-color: var(--green400);
      }

      & .stepperIconLine {
        background-color: var(--green400);
      }
    }

    &.active {
      & .stepperIcon {
        border: solid 2px var(--green400);
      }

      & .stepperIconLineLeft {
        background-color: var(--green400);
      }
    }
  }

  .stepConnector {
    &.done {
      background-color: var(--green400);

      & + div {
        & .stepperIconLineLeft {
          background-color: var(--green400);
        }

        & .stepperIcon {
          border: solid 2px var(--green400);
        }
      }
    }
  }
}

