@import '../../../global/theme.css';
@import '../../../global/general.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding: 0 10px;

    & .chip {
        height: 18px;
        font-size: 11px;
        border: unset;
        padding: unset;
    }

    & .warningChip {
        background-color: var(--orange100);
    }

    & .skanChip {
        background-color: var(--gray100);
        margin-bottom: 10px;
    }

    & .unoptimized {
        background-color: var(--turquoise200);
        color: white;
    }
}
