@import '../../global/theme.css';

[onclick]{
  cursor: pointer;
}
.ApiContainer{
  margin-top: 40px;
  padding: 30px;
  width: 855px;
  height: 157px;
  background-color: var(--gray100);
}

.KeyContainer{
  margin-top: 20px;
  background-color: var(--gray200);
  width: 100%;
  height: 57px;
  padding: 10px;
}
.KeyInput{
  background-color: transparent;
  border: 0;
  width: 80%;
  outline: none;
  margin: 0 10px;
}

.monospaceFont {
  font-family: Monospace;
}
