@import "../global/theme.css";

:global(.MuiTableRow-root) {
    height: 41px;

}

.tableWrapper {
    padding: 10px 25px 0;
    margin-top: 10px;
    background-color: var(--white);
}
