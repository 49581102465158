@import '../global/theme.css';

.pageWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    & .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        flex: 1 1 auto;
        background-color: var(--white);
        overflow-x: hidden;
        overflow-y: auto;
        overflow-anchor: none;
        scrollbar-width: none;
        padding: 0 40px;

        & .tutorialIcon {
            height: 80px;
            width: auto;
        }
    }
}

.creativePopupWrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    overflow: hidden;
}

.autocompleteOptionIcon {
    width: 18px;
    height: 18px;
    border-radius: 4px;
}

