@import "../../../../global/theme.css";

.labelContainer {
    margin-bottom: 30px;
}

.moreInfoContent {
    margin-top: 10px;
    display: flex;
    gap: 8px;

    & .infoIcon {
        display: block;
        fill: var(--gray700)
    }
}