@import '../../../global/theme.css';

.containerPopup {
    background-color: white;
    height: 100%;
    overflow-y: hidden;

    & .header {
        padding: 16px 0;
        margin: 0 24px;
        display: flex;
        align-items: center;
        gap: 10px;

        & .title {
            font-size: 20px;
            font-weight: 600;
            color: var(--gray800);
            line-height: 30px;
        }

        & .goBackContainer {
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
            padding-top: 6px;

            & .goBackText {
                font-size: 13px;
                color: var(--gray700);
            }

            & .goBackIcon {
                width: 12px;
                height: 12px;
                transform: rotate(90deg);
                fill: var(--gray700);
            }
        }
    }
}

.icon {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px;
}

.dropdownRow {
    padding: 2px 8px;
}