.listSectionHeader {
    font-size: 18px;
    margin-bottom: 15px;
}

.container {
    margin-bottom: 45px;

    & .itemContainer {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}