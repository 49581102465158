@import "../../global/theme.css";

:root {
    --clearIconHeight: 22px;
}
.field {
    position: relative;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    & label {
        @apply --font-input-label;
        display: block;
        margin-bottom: 0;
    }
    & .fieldInput {
        @apply --font-input;
        height: 36px;
        width: 100%;
        border: 1px solid var(--gray400);
        border-radius: 2px;
        line-height: 18px;
        padding: 8px 10px;
        outline: none;
        box-sizing: border-box;
    }
    & .inputContainer{
        position: relative;
      }
    & .clear {
        height: var(--clearIconHeight);
        position: absolute;
        right: 5px;
        top: calc(50% - var(--clearIconHeight) / 2);
        cursor: pointer;
    }
    & .errorColor {
        border-color: var(--red300);
        color: var(--red300);
    }
    & .errorMessage {
        color: var(--red300);
        position: absolute;
        right: 0;
        font-size: 11px;
    }

    & .labelText {
        display: flex;
        align-items: center;
        & > * {
            margin-left: 6px;
        }
        & > *:first-child {
            margin-left: 0;
        }
    }

    & ::placeholder {
        font-size: 13px!important;
    }
}

.mandatory::before {
    content:"* ";
    color: var(--red300);
}

input:disabled::-webkit-input-placeholder { /* WebKit browsers */
    color:    var(--white);
}

.disabled {
    & .fieldInput[disabled] {
        cursor: not-allowed;
        background: var(--primaryDisabledLight) !important;
        border: 1px solid var(--gray300);
        color: var(--primaryButtonTextDisabled) !important;
        &::placeholder {
            font-style: italic;
        }
    }
}

@media (--mobile) {
    .field {
        & .errorMessage {
            line-height: 13px;
        }

        & .labelText {
            line-height: 22px;
        }
    }
}
