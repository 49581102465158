@import "../global/theme.css";

.pageHeaderWrapper {
    background-color: white ;
    display: flex;
    justify-content: space-between;
}

.pageHeader {
    padding-left: 30px;
    padding-top: 15px;
}

.pageWrapper {
    overflow-x: initial;
}
