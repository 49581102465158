@import "../../../global/theme.css";

.keyAndTokenContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    max-width: 590px;
    line-height: 25px;

    & .keyAndTokenText {
        margin-right: 30px;

        & .inputBox {
            line-height: 30px;
        }
        & .inputText {
            user-select: none;
            display: inline-block;
            min-width: 90px;
        }
        & .disableSelect {
            user-select: none;
        }
    }
}

.keyAndTokenButtonContainer {
    flex-grow: 1;

    & .keyAndTokenButton {
        float: right;
        right: 50px;
    }
}

