@import "../../global/theme.css";

.contentArea {
    display: flex;
    flex-direction: column;
    & .paddedArea {
        padding: 30px;
    }

    & .spinnerArea {
        height: 390px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .separator {
        height: 1px;
        background-color: var(--gray400);
    }

    & .list {
        max-height: 380px;
        overflow: auto;
        & .listItem {
            height: 65px;
            background-color: var(--white);
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: var(--hoverColor);
            }
            &:last-child {
                margin-bottom: 0;
            }
            & .listItemIcon {
                width: 40px;
                height: 40px;
                margin: 0 12px;
            }
            & .listItemText {
                font-size: 18px;
            }
            & .active {
                margin-left: auto;
                margin-right: 12px;
                display: flex;
                align-items: center;
                & .activeIcon {
                    margin-right: 6px;
                }
            }
            & .highlighted {
                font-weight: bold;
                padding: 0;
                color: inherit;
                background-color: yellow;
            }
            &.disabled {
                opacity: 0.5;
                cursor: default;
                &:hover {
                    background-color: var(--white);
                }
            }
        }
    }

}