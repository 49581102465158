@import "../global/theme.css";

.pageWrapper {
  padding-bottom: 90px !important;
  height: 100%;
}

.primaryText {
  height: 25px;
  font-size: 27px;
  font-weight: 300;
  line-height: 0.93;
  color: var(--gray800);
  margin-bottom: 10px;
  margin-top: 40px;
}

.paragraph {
  height: 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--gray800);
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.warningMessage {
  margin-bottom: 30px;
}

.filterDimensionsWrapper {
  max-width: 830px;
  flex-grow: 1;
  padding-right: 50px;
}

.spinner {
    position: absolute;
    top: 50%;
    width: 100%;
}