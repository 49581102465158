@import "../../global/theme.css";

.switchContainer {
    margin-bottom: 20px;
}

.switchContainerSegment {
    margin-bottom: 20px;
}

.closedSegment {
    padding: 16px 24px !important;
}

.cardContainer{
    margin-top: 6px;
    overflow: visible!important;
    box-shadow: none !important;
    & * {
        outline: none;
    }
    &:first-child {
        margin-top: 0;
    }
    &.open {
        & .cardContent {
            margin-bottom: 20px;
        }
        & .arrow {
            transform: rotate(90deg);
            position: relative;
            top: 2px;
        }
    }
}

.cardContentContainer {
    padding: 16px !important;
}

.cardContent {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
}

.cardContentHeader {
    flex-grow: 1;
    font-weight: 500;
}

.cardActions {
    justify-content: flex-end;
    padding: 0 16px 16px 0 !important;
    & > * {
        margin: 0 !important;
        margin-left: 10px !important;
    }
}

.arrow {
    border-left: 6px solid var(--gray600);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    width: 8px;
    height: 8px;
    margin-right: 10px;
}

.onOffIndication {
    font-size: 14px;
    font-weight: 600;
    &.on {
        color: var(--blue300);
    }
}