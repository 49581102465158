@import "../../global/theme.css";


.container {
    & .adminLabel {
        font-size: 16px !important;
        text-transform: none;
    }

    & .tabRoot {
        background-color: var(--gray300) !important;
    }

    & .tabIndicator {
        height: 3px !important;
        background-color: var(--blue300) !important;
    }
    &.level2 {
        & .tabRoot {
            background-color: var(--gray400) !important;
        }
    }
}