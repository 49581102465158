@import "../../global/theme.css";

.conversionModelInnerContainer {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
    & .conversionModelInnerKey {
        margin-right: 5px;
    }
    & .conversionModelInnerValue {
        font-weight: 600;
    }
}

.conversionModelInnerClickMore {
    color: var(--blue300);
    font-weight: 600;
    cursor: default;
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: fit-content;

    & .chevron {
        fill: var(--blue300);
        transform: rotate(-90deg);
        height: 6px;
      }
}
