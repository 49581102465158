@import "../global/theme.css";

.metricCardsContainer {
    display: flex;
    width: 100%;
    min-width: 1100px;
    height: 100%;

    & .metricCard {
        width: 20%;
        min-width: 200px;
        margin-right: 40px;
        height: 100%;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 13px 7px;
        border-radius: 10px;

        &:last-child {
           margin-right: 0;
        }
    }

    & .dotsLoader {
        width: 30px;
        margin: auto;
    }

    & .metricName {
        height: 30%;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: var(--gray800);
    }

    & .metricValue {
        font-size: 34px;
        font-weight: 300;
        text-align: center;
        color: var(--gray700);

        & .metricValueTotalLabel {
            font-size: 14px;
            padding-left: 5px;
        }
    }

    & .noData {
        margin: auto;
        display: flex;
        flex-direction: column;
        height: 90px;

        &  .noDataLabel {
            font-size: 12px;
            color: var(--gray500);
        }

        & .noDataIcon {
            margin: auto;
        }
    }

    & .animatedMetricCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 67%;
    }

    & .changeValue {
        font-size: 16px;
        text-align: center;
        font-weight: 600;

        &.zero {
            color: var(--gray700);
        }

        &.positive {
            color: var(--success1Color);
        }

        &.negative {
            color: var(--red400);
        }
    }
}