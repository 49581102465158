@import "../../global/theme.css";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.locked {
    & .trash {
      cursor: default;
    }
  }
}

.eventName {
  font-size: 16px;
  font-weight: 500;
  min-width: 313px;
  margin-top: 1px;
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expanded .rightSide {
  flex-basis: auto;
}

.rightSideItem {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray500);
}

.rightSideItem:last-child {
  margin-right: 0;
}

.eventField{
  display: flex;
  width: 120px;
}

.eventDate, .eventAutoGenerated {
  width: 220px;
}

.eventAutoGeneratedText{
  font-weight: 500;
}

.spacer {
  margin: 0 4px;
}

.trash {
  padding: 8px;
  cursor: pointer;
}

.slotIndication {
  margin-left: 10px;
  font-size: 14px;
  color: var(--gray500);
  font-weight: 300;
}