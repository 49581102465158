@import '../../global/theme.css';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}

.header {
    color: var(--gray800);
    padding-bottom: 10px;
}

.editContainer {
  padding: 30px 30px 84px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: smooth;
}

.edit {
    & :not(input) {
        user-select: none;
    }
    position: relative;
    & .addButton {
        position: absolute;
        right: 0;
        top: -2px;
        height: 30px;
    }
}

.emptyShelfBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  & a {
    cursor: pointer;
  }
}