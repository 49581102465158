@import "../../global/theme.css";

.container {
    width: 100%;

    &:not(:hover) {
        & .filterContainer {
            &.forceHoverOnLast:last-child {
                & .showOnHover {
                    opacity: 1;
                }
            }
        }
    }
}

.filterContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;

    &:hover {
        & .showOnHover {
            opacity: 1;
        }
    }
}

.filterContainer:last-child {
    margin-bottom: 0;
}

.opsContainer {
    margin-left: 10px;
    flex: 3;
}

.tagsContainer {
    margin-left: 10px;
    flex: 9;
}

.xIcon {
    cursor: pointer;

    & path {
        fill: var(--gray600);
    }

    &:hover {
        & path {
            fill: var(--red300);
        }
    }
}

.disabledIcon {
    & .xIcon {
        cursor: default !important;

        & path {
            fill: var(--gray400) !important;
        }
    }
}

.hideXIcon {
    visibility: hidden;
}

.showOnHover {
    opacity: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.prefixText {
    margin: 6px 40px 0 6px;
}

.plusIconContainer {
    margin-left: 10px;
    display: flex;
    margin-top: 5px;
    color: var(--gray400);
    fill: var(--gray400);

    &:not(.shown) {
        visibility: hidden;
    }

    &.enabled {
        cursor: pointer;
        color: var(--blue300);
        fill: var(--blue300);

        &:hover {
            color: var(--blue400);
            fill: var(--blue400);
        }
    }
}
