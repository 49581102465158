@import "../global/theme.css";

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & .topArea {
    border-bottom: 1px solid var(--gray400);
  }

  & .mainArea {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    & .leftArea {
      flex-shrink: 0;
      width: 400px;
      background-color: var(--white);
      border-right: 1px solid var(--gray400);
      overflow: auto;
    }

    & .actionsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & .headerTopAreaContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & .rightArea {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 26px 0;
      overflow: auto;

      & .rightAreaTop {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 40px;

        & .mainText {
          flex: 1;
          margin-bottom: 10px;
        }
      }

      & .rightAreaMain {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 40px;

        & .spinner {
          margin-top: 120px;
        }

        & .emptyState {
          margin-top: 120px;
          text-align: center;

          & svg {
            width: 140px;
          }
        }
      }
    }
  }
}
