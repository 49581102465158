@import "../../global/theme.css";

.wrapper {
    margin-top: 27px;
    min-height: 40vh;
    text-align: center;

    & .emptyStateBottomContainer {
        width: 90%;
        color: var(--white);
        font-weight: 600;
        text-align: center;
        justify-content: center;
        background-color: var(--gray400);
        margin: 44px auto;
        padding: 24px 0;
        opacity: 0.7;
        border-radius: 10px;
    }

    & .emptyStateImage {
        width: 94%;
        padding-top: 30px;
    }

    & .emptyStateInstructionsWrapper {
        background-color: var(--white);
        margin-top: 27px;
        min-height: 50vh;
        text-align: center;
        min-width: 770px;
        }

        & .searchButtonWrapper {
            display: flex;

            & .filterLabel {
                line-height: 41px;
                padding-right: 10px;
            }

            & .appsiteIcon {
                align-items: center;
                padding-right: 10px;

                & img {
                    width: 47px;
                    height: 47px;
                    border-radius: 5px;
                }
            }
        }

        & .tableHeader {
            padding-left: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 70px;

            & .searchButton {
                width: 317px;
                font-size: 14px;
                margin-bottom: 0;
                line-height: 48px;
            }
        }

        & .fetchLogsStatus {
            margin-right: 37px;
            display: flex;
            align-items: center;
            font-weight: bold;
            & .booleanIcon {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                border-radius: 50%;
                display: inline-block;

                &.green {
                    background-color: var(--green300);
                }
                &.yellow {
                    background-color: var(--orange300);
                }
            }

            & .loadingIcon {
                margin: 0 15px 14px 0;
            }

            & .fetchLogsButton {
                margin-left: 15px;
            }
        }

        & .emptyStateText {
            margin-top: 55px;
            text-align: center;
        }

        & .tagInputClass {
            min-width: 400px;
            text-align: left;
            width: initial;
        }
    }
