@import '../global/theme.css';

:global(.stickyPageWrapper) {
    padding: 0 !important;

    & .headerFiltersWrapper {
        box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.15);
        z-index: 13;
    }

    & .chartsWrapper {
        margin: 0 40px 10px 40px;
    }

    & .spinner {
        margin: 0 40px;
    }
}

.pageWrapper {
    overflow-x: initial;
    //transition: padding 300ms ease-in-out;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
}

.header {
    padding-bottom: 34px;
}

.feedbackBtn {
    margin-top: 30px;
}

.chartsWrapper {
    min-height: 100vh;
    position: relative;
}

.fading {
    background-color: var(--gray200);;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 800ms ease-in-out;

    &.active {
        opacity: 0.85;
        z-index: 4;
    }
}

.headerFiltersWrapper {
    width: 100%;
    background-color: var(--white);
    padding: 10px 15px 25px 15px;
    margin-bottom: 20px;

    position: sticky;
    z-index: 5;
    top: 0;

    & .headerFilters {
        display: flex;
        justify-content: space-between;

        & .headerFilter {
            width: 100%;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    & :global(.Select__control) {
        align-items: start;
    }
}

.crosshair {
    opacity: 0.5;
    pointer-events: auto !important;
    cursor: pointer;
}

.yAxisTitle {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: var(--gray600);
}

// required as a workaround for a scrollablePlotArea bug in FF
:global(.chart) {
    position: relative;
}

:global(.noDataForCategory) {
    color: var(--gray600);
    margin-top: -8px;
    width: 195px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.chartContainer {
    margin-top: 25px;

    & .chartTitle {
        font-size: 16px;
        padding-bottom: 7px;
        color: var(--gray800);
    }
}

:global(.over_time_chart .highcharts-axis-labels.highcharts-xaxis-labels) {
    & span:first-child {
        margin-left: 17px !important;
    }

    & span:last-child {
        margin-left: -17px !important;
    }
}

:global(.highcharts-container) {
    z-index: auto !important;
}


:global(.highcharts-tooltip-container) {
    z-index: 12 !important;
}

:global(.network-xaxis-label-container) {
    display: flex;
    flex-direction: column;

    & :global(.network-xaxis-logo-container) {
        height: 38px;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-around;
    }

    & :global(.network-xaxis-logo) {
        width: 30px;
    }

    & :global(.network-xaxis-label-text) {
        font-size: 10px;
        color: var(--gray600);
        margin: 0 auto;
        text-align: center;
    }
}


:global(.app-xaxis-label-container) {
    display: flex;
    width: 115px;
    height: 30px;

    & :global(.app-xaxis-logo-container) {
        height: 30px;
        flex-direction: column;
        justify-content: space-around;
    }

    & :global(.app-xaxis-logo) {
        height: 30px;
        margin: 0 8px;
        border-radius: 2px;
    }

    & :global(.app-xaxis-label-text) {
        width: 70px;
        font-size: 12px;
        color: var(--gray600);
        margin: auto;
        line-height: 1.33;
        text-align: left;
    }
}

.emptyChartStateContainer {
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: var(--white);

    & .emptyChartStateImg {
        margin: auto;
        width: 200px;
        height: 134px;
    }

    & .emptyChartStateText {
        margin: 20px auto 0 auto;
        color: var(--gray800);
        font-size: 18px;
        font-weight: 400;
    }

    & .emptyChartStateSubtext {
        margin: 0 auto 20px auto;

    }
}

.emptyStateContainer {
    display: flex;
    flex-direction: column;
    height: 300px;
    margin-top: 150px;

    & .emptyStateImg {
        margin: auto;
        width: 300px;
        height: 200px;
    }

    & .emptyStateText {
        margin: auto;
        color: var(--gray800);
        font-size: 18px;
        font-weight: 400;
    }

    & .emptyStateSubtext {
        margin: auto;
    }
}

.noDataIcon {
  width: 40px;
}

.noDataCell {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray500)
}

.percentilesTable {
    & td, & th {
        border: 2px solid var(--bodyBackgroundColor);
        width: 16.6%;
    }

    & .tdNetwork {
        width: 200px;
    }

    & .tdNetworkWrapper {
        width: 180px;
        display: flex;
    }

    & .tdPercentile {
        padding: 0;
    }

    & .tdPercentileNoData {
        background-color: var(--gray100);
    }

    & .tdNetworkName {
        margin-left: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 140px;
        white-space: nowrap;
    }

    & .tdNetworkIcon {
        width: 20px;
        height: 20px;
        border-radius: 3px;
    }
}

.thPercentile {
    padding: 5px 0;

     & .thNetworkLabel {
        display: flex;
        justify-content: center;
    }

    & .thLabel {
        display: flex;
        justify-content: center;
        padding-bottom: 8px;
    }

    & .thPercentileRange {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1px;

        & .thPercentileRangeText {
            font-weight: 300;
            font-size: 12px;
            color: var(--gray600);
        }
    }

    & .thPercentileRangeBar {
        border-bottom: 3px solid var(--gray200);
    }
}
