@import "../../global/theme.css";

.tableCell {
    justify-content: space-between;
    /*border-right: 1px solid #E1E1E1;*/
    /*border-left: 1px solid #E1E1E1;*/

    & .labels {
        flex-direction: column;
        min-width: 100px;

        & .highlighted {
            padding: 0;
            background-color: var(--highlightColor);
        }

        & .subTextContainer {
            font-size: 11px;
        }
    }
}


