@import "../../global/theme.css";

@define-mixin container $type {
    .container.$(type) {
        user-select: none;
        color: var(--$(type)ButtonText);
        background-color: var(--$(type)ButtonBackground);
        background-image: var(--$(type)ButtonBackgroundImage);
        @mixin-content;
        &:hover:not(:active):not(.disabled):not(.disabledDark):not(.showSpinner):not(.showV):not(.forceActive) {
          color: var(--$(type)ButtonTextHover);
          background-color: var(--$(type)ButtonBackgroundHover);
          @mixin-content;
          & .buttonSpinner {
            & * {
              fill: var(--$(type)ButtonTextHover);
            }
          }
          @mixin-content;
          & .icon {
            fill: var(--$(type)ButtonTextHover);
          }
        }
        &:active:not(.disabled):not(.disabledDark):not(.showSpinner), &.showV, &.forceActive:not(.disabled):not(.disabledDark):not(.showSpinner) {
          color: var(--$(type)ButtonTextActive);
          background-color: var(--$(type)ButtonBackgroundActive);
          @mixin-content;
        }
        &.disabled:not(.showSpinner):not(.showV) {
          color: var(--$(type)ButtonTextDisabled);
          background-color: var(--$(type)ButtonBackgroundDisabled);
          border-color: var(--$(type)ButtonTextDisabled);

          & .icon {
            fill: var(--$(type)ButtonTextDisabled);
            & path {
               stroke: var(--$(type)ButtonTextDisabled);
            }
          }
        }
        &.disabledDark:not(.showSpinner):not(.showV) {
            color: var(--$(type)ButtonTextDisabledDark);
            background-color: var(--$(type)ButtonBackgroundDisabledDark);
            border-color: var(--$(type)ButtonTextDisabledDark);
         }
        & .buttonSpinner {
          & * {
            fill: var(--$(type)ButtonText);
          }
        }
    }
}

@define-mixin containerLevel $level, $padding, $height {
    .container.$(level) {
        padding: 0 $(padding);
        height: $(height);
    }
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.container {
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  height: 36px;
  @apply --font-button;
  border-radius: 2px;
  position: relative;
  flex-shrink: 0;
  & .buttonSpinner {
    width: 22px;
    height: 22px;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    & * {
      opacity: 1;
      fill: var(--white);
      filter: none;
    }
  }
  & .buttonVContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .buttonV {
    width: 22px;
    height: 22px;
    & * {
      opacity: 1;
      fill: var(--white);
    }
  }
  &.showSpinner {
    cursor: default;
    opacity: 0.7;
    & .textContainer {
      opacity: 0;
    }
    & .buttonSpinner {
      display: inline;
    }
  }
  &.showV {
    cursor: default;
    & .textContainer {
      opacity: 0;
    }
  }
  &.hide {
    display: none;
  }
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-bottom: 1px;
  vertical-align: sub;
}

.iconAlignedRight {
    direction: rtl;
}

.buttonContent {
  display: flex;
  align-items: center;
}


@mixin container primary {
  border: none;
}
@mixin container secondary {
  border: 1px solid var(--secondaryButtonText);
}
@mixin container flat {
  border: none;
}
@mixin container attention {
  border: none;
}
@mixin container new {
  border: none;
}
@mixin container link {
  border: none;
}

@mixin container jungleGreen {
  border: none;
}

@mixin container skanPlus {
    border: none;
}

@mixin container success1Color {
  border: none;
}

@mixin container invertedPrimary {
  border: 1px solid var(--primaryColor);
}

@mixin containerLevel level1, 15px, 36px {}
@mixin containerLevel level2, 15px, 30px {}
@mixin containerLevel important, 40px, 36px {}