@import "../../global/theme.css";

.topArea {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    & .label {
        font-size: 13px;
        font-weight: 600;
    }

    & .button {
        font-size: 11px;
        font-weight: 500;
        margin-left: auto;

        &[disabled] {
            color: var(--gray600);
            text-decoration: initial;
        }
    }
}
