.container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 600px;

    & .pieContainer {
        border: 1px solid #CCCCCC;
        border-radius: 8px;

    }
    & .formItem {
        gap: 6px;
        display: flex;
        flex-direction: column;

        & .title {
            font-weight: 600;
        }

        &.isRow {
            flex-direction: row;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                border-radius: 4px;
            }
        }
    }
}