.container {
  padding: 0;
  margin: 0;
  width: 125px;
}

.item {
  text-align: left;
  padding: 5px;
  list-style-type: none;
}

.item:hover {
  background-color: #f5f5f5;
}