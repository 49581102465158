@import "../../global/theme.css";
:root {
    --number-size: 21px;
}
.container {
    padding: 7px 13px 6px 7px;
    position: relative;
}

.expandableContainer {
    min-height: 85px;
}

.innerContainer {
    display: flex;
    align-items: baseline;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.titleFont {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray700);
}

.number {
    min-width: var(--number-size);
    min-height: var(--number-size);
    background-color: var(--primaryColor);
    border-radius: 50%;
    color: white;
    font-weight: 700;
    margin-right: 12px;
    text-align: center;
}

.expandButton {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 6px;
    right: 28px;
    cursor: pointer;
    & svg {
        width: 10px;
        height: 7px;
        fill: var(--blue300);
        &.collapsed {
            transform: rotate(180deg);
        }
    }
}

.expandButtonText {
    font-weight: 600;
    color: var(--primaryColor);
    margin-right: 3px;
}
