@import '../../../global/theme.css';

.creativePopup {
    position: relative;
    background-color: white;
    height: 100%;

    & .XIcon {
        position: absolute;
        right: 15px;
        top: 15px;
        fill: var(--gray700);
        cursor: pointer;
    }

    & .header {
        border-bottom: 1px solid var(--gray300);
        padding: 16px 0;
        margin: 0 24px;
        display: flex;
        align-items: center;
        gap: 10px;

        & .title {
            font-size: 20px;
            font-weight: 600;
            color: var(--gray800);
            line-height: 30px;
        }

        & .goBackContainer {
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
            padding-top: 6px;

            & .goBackText {
                font-size: 13px;
                color: var(--gray700);
            }

            & .goBackIcon {
                width: 12px;
                height: 12px;
                transform: rotate(90deg);
                fill: var(--gray700);
            }
        }
    }

    & .content {
        display: flex;
        height: 100%;

        & .creativeData {
            width: 50%;
            height: 100%;
            border-right: 1px solid var(--gray300);
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 24px;

            & .creativeAsset {
                border-radius: 8px;
                width: 100%;
                height: 60%;
                border: none;
                animation: none;
                overflow: hidden;
            }

            & .creativeRawData {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 0 10px;
                overflow-y: scroll;
                overflow-x: hidden;
                margin-bottom: 80px;
                overflow-wrap: break-word;

                &::-webkit-scrollbar {
                  -webkit-appearance: none;
                    width: 7px;
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 4px;
                  background-color: rgba(0, 0, 0, .5);
                  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
                }
            }
        }
    }
}