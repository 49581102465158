@import '../../global/theme.css';

.value {
    display: flex;
    padding: 6px;
    gap: 5px;
    border: 1px solid var(--gray400);
    font-size: 14px;
    width: 100%;
    overflow: unset;
    align-items: center;
    opacity: 1;

    &.dragging {
        opacity: 0.5;
        background-color: var(--blue100);
    }

    & .dragDropIcon {
        cursor: pointer;
        fill: var(--gray600);
    }
}