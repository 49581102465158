@import "../../global/theme.css";

.filtersContainer {
    display: flex;
    align-items: center;

    & .filterLabel {
        margin-right: 10px;
        font-weight: normal;
    }

    & .filterOption {
        width: 165px;
    }

    & .filterText {
        width: 400px;
        margin-left: 10px;
        margin-bottom: 0;
    }

    & .filterLoading {
        width: 25px;
        margin: 0 10px;
    }
}
