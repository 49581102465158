@import "../../../../global/theme.css";

.container {
    display: flex;
    gap: 8px;

    & .iconContainer {

        & svg {
            width: 20px;
            fill: var(--gray700);
            vertical-align: middle;
        }

    }
    & .textContainer {
        & .line {
        display: inline;

        &.appendedLine::after {
            content: ' ';
        }
    }

    & .link {
          display: inline-block;
        }
    }

}
