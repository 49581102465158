@import "../../global/theme.css";

.container {
    & .fieldTypeTitle {
        background: var(--gray600);
        color: var(--white);
        line-height: 32px;

        &.field {
            padding: 0 8px;
        }
    }

    & .fieldTypeContent {
        padding: 5px 0;

        & .fieldContainer {
            display: flex;
            padding: 0 8px;
            align-items: center;

            & .chevronIcon {
                width: 13px;
                height: 13px;
                margin: 0 8px 0 3px;
                cursor: pointer;
            }

            & .collapseIcon {
                transform: rotate(-90deg);
            }

            & .groupFieldName {
                font-weight: 600;
            }
        }

        & .groupFieldsSector {
            padding-left: 45px;
        }

        & .nonGroupFields {
            margin-left: 24px;
        }
    }

    & .field {
        line-height: 32px;
    }
}