@import '../../global/theme.css';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.panel {
    background-color: white;
    margin-bottom: 20px;
}

.header {
    background-color: var(--blue150);
    padding: 10px 20px;
    color: var(--gray800);
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
}

.body {
    padding: 16px 20px;
}

.editContainer {
  padding: 30px 30px 84px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: smooth;
}

.edit {
    & :not(input) {
        user-select: none;
    }
    position: relative;
    & .flexRow {
        display: flex;
        align-items: flex-end;
    }
    & .line {
        position: absolute;
        top: 18px;
        left: -15px;
        bottom: 55px;
        border-left: 1px solid var(--gray700);
    }
    & .dot {
        position: absolute;
        left: -16px;
        top: 18px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--gray700);
        &.bottomDot {
            left: -16px;
            top: -20px;
        }
    }
    & .filter {
        position: relative;
        & .dot {
            background-color: var(--purple300);
            width: 7px;
            height: 7px;
            left: -18px;
            top: 8px;
            &.exclude {
                background-color: var(--turquoise100);
            }
        }
        & .outerLogic {
            width: auto;
            margin-right: -5px;
            display: inline-block;
            height: 34px;
            & button {
                background-color: inherit;
                color: var(--gray700);
                width: auto;
                border: none;
                padding-left: 0;
                margin-top: -10px;
                margin-bottom: 10px;
                font-weight: 400;
                & svg {
                    fill: var(--gray700) !important;
                }
            }
        }
        & .outerLogicExtra {
            display: inline-block;
            color: var(--gray700);
            margin-right: 10px;
            height: 34px;
            line-height: 22px;
        }
        & .trash {
            float: right;
            margin-top: -3px;
            & * {
                fill: var(--gray500);
            }
            &:hover * {
                cursor: pointer;
                fill: var(--red300);
            }
        }

        & .condition {
            position: relative;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                & .labelGroup.eventBlock {
                    width: 100%;
                }
            }

            & .labelGroup {
                display: inline-block;
                position: relative;
                & .label {
                    @apply --font-input-label;
                    white-space: nowrap;
                }
                &.eventBlock {
                    width: 100%;
                    vertical-align: top;
                }
            }
            & .installLabel {
                display: inline-block;
                color: var(--gray700);
                margin-right: 5px;
                height: 30px;
            }
            & .conditionInput {
                display: inline-block;
                width: 90px;
                color: var(--gray700);
                border: 1px solid var(--gray400);
                border-radius: 2px;
                font-size: 16px;
                line-height: 16px;
                padding: 8px 10px;
                margin-right: 10px;
                height: 37px;
                vertical-align: top;
                &:focus {
                    outline: none;
                }
            }
            & .conditionTextField {
                display: inline-block;
                width: 90px;
                vertical-align: top;
                margin-right: 10px;
            }
            & .conditionDropdown {
                &.op button {
                    width: 135px;
                }
                &.blueDropdown button {
                    min-width: 0;
                    width: 75px;
                }

                &.installDropdown button{
                    width: 177px;
                }

                display: inline-block;
                width: auto;
                margin-right: 10px;
                vertical-align: top;
                & button {
                    border-radius: 2px;
                    width: auto;
                    min-width: 100px;
                }
            }
            & .conditionDropdownPopper {
                min-width: calc(100% - 10px) !important;
            }
            &:hover .conditionButtons {
                visibility: visible;
            }
            & .conditionButtons {
                display: inline-block;
                visibility: hidden;
                height: 28px;
                & .x:hover {
                    cursor: pointer;
                }
                & .plus {
                    margin-left: 8px;
                    position: relative;
                    top: 3px;
                    fill: var(--blue300);
                    
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    & .addFilter {
        position: relative;
        & .dottedLine {
            position: absolute;
            top: -20px;
            left: -15px;
            bottom: 27px;
            border-left: 1px dashed var(--gray700);
        }
        & .addDot {
            position: absolute;
            left: -23px;
            top: 10px;
            border-radius: 50%;
            font-size: 15px;
            line-height: 14px;
            text-align: center;
            user-select: none;
            & .addDotIcon {
                fill: var(--blue300);
            }
        }
        & .addFilterDropdown {
            & button, & button:focus, & button:hover {
                background-color: inherit;
                color: var(--gray700);
                padding-left: 2px;
            }
        }
        & .addFilterDropdownButton {
            color: var(--gray700);
        }
    }

}

.detailsFieldHolder {
    &:last-child {
        margin-bottom: 0;
    }
}