@import "../../global/theme.css";
@import "../../global/general.css";

:root {
  --single-group-btn-radius: 15px;
}

.container{
  width: 240px;
  height: 29px;
  background-color: var(--white);
  display: inline-block;
  border: none;
  border-radius: 0;
  outline: none;
  margin-right: 3px;
  margin-bottom: 3px;
  color: var(--gray800);
  @apply --font-size-small;

  &.single {
    margin-right: calc(-1 * (var(--single-group-btn-radius) + 10px));
    border-radius: var(--single-group-btn-radius);
  }

  &. glassesIcon {
     fill: var(--blue300);
  }
}

.container:hover {
  background-color: var(--gray100) !important;
  opacity: 1 !important;
  cursor: pointer;

  &.single {
    background-color: var(--white);

    &.checked {
      background-color: var(--blue100);
    }
  }

  & .infoIcon, & .glassesIcon {
    display: inline;
  }
}

.container.checked {
  background-color: var(--blue100) !important;
  color: var(--gray800);
  & .checkMark {
      height: var(--icon-size-small);
  }

  & .infoIcon path, & .glassesIcon {
    fill: var(--gray600);
  }

  &.single {
    position: relative;
  }

  & .infoIcon path {
    fill: var(--gray600)
  }
}

.container.disabled {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}

.container.checked.dataType__skan {
    background-color: var(--purple100) !important;
    border: solid 1px var(--purple300) !important;
    & .checkMark {
      fill: var(--purple300);
    }
}

.container.checked.dataType__tracker {
    background-color: var(--orange100) !important;
    border: solid 1px var(--orange300) !important;
    & .checkMark {
      fill: var(--orange300);
    }
}

.container.checked.dataType__unified {
    background-color: var(--green100) !important;
    border: solid 1px var(--green300) !important;
    & .checkMark {
     fill: var(--green300);
    }
}

.container.checked.dataType__network {
    background-color: var(--teal100) !important;
    border: solid 1px var(--teal300) !important;
    & .checkMark {
      fill: var(--teal300);
    }
}

.checkMark {
    margin: 5px 5px 0 auto;
}

.smallButton {
  width: 160px;
}


.buttonInner{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.tooltipWrapper {
  margin-left: auto;
}

.infoIcon {
  grid-column-start: 2;
  vertical-align: sub;
  display: none;
  height: 14px;
}

.glassesIcon {
  display: none;
  margin-left: auto;
  width: 21px;
  height: 14px;
}

.infoIcon path, .glassesIcon {
  fill: var(--gray600)
}

.buttonText{
  vertical-align: middle;
  grid-column-start: 2;
}

.persistentIcon {
  flex-direction: column;
  display: flex;
  align-items: center;
}