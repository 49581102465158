@import "../global/theme.css";

:root {
    --contentPaddingTop: 73px;
    --logoWidth: 100px;
    --logoMarginLeft: calc(var(--logoWidth) - var(--logoMarginRight));
    --logoMarginRight: 10px;
    --minViewWidth: 600px;
    --contentMaxWidth: 480px;
    --subtitleFontSize: 18px;
    --titleFontSize: 24px;
    --titleMargin: 50px auto 0 auto;
    --subtitleMargin: 20px auto 30px auto;

    --titleMarginMobile: 30px auto 0 auto;
    --contentMarginMobile: 40px auto;

    --titleFontSizeMobile: 18px;
    --subtitleFontSizeMobile: 16px;
}

@keyframes :global(css-slider-scroll-animation) {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-1 * calc(var(--logoWidth) * 2) * 14));
    }
}

.signupContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    min-height: 820px;
    background-color: var(--white);

    & .singularIcon {
        width: 100%;
        height: 42px;
        fill: var(--blue300);
    }

    & .footerLogo1 {
        width: var(--logoWidth);
        margin-right: var(--logoMarginRight);
        margin-left: var(--logoMarginLeft);
        fill: var(--gray800);
    }
}

.signupCopy {
    width: 35%;
    height: 100%;
    min-width: var(--minViewWidth);
    background-image: linear-gradient(to bottom, var(--blue300), #4c4fee);
    padding-top: var(--contentPaddingTop);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;

    & .signupCopyInner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-self: center;
    }

    & .singularCopyTitle {
        max-width: var(--contentMaxWidth);
        font-size: var(--subtitleFontSize);
        line-height: 1.57;
        text-align: center;
        color: var(--white);
        margin: 40px auto;
        font-weight: 600;
    }

    & .objectiveList {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var(--white);
        max-width: var(--contentMaxWidth);
        margin: 30px auto 40px auto;

        & .objective {
            display: flex;
            height: 70px;
        }

        & .objectiveText {
            font-size: 18px;
            letter-spacing: normal;
            color: var(--white);
            margin-left: 20px;
            vertical-align: middle;
        }

        & .objectiveIconWrapper {
            padding: 1px
        }

        & .objectiveIcon {
            width: 22px;
            height: 22px;
        }
    }

    & .signupCopyFooter {
        height: 20%;
        max-height: 220px;
    }
}

.signupForm {
    width: 100%;
    height: 100%;
    min-width: var(--minViewWidth);
    flex-direction: column;

    & .singularFormBody {
        position: relative;
    }
     & .singularFormBodySecondPage {
        position: relative;
        max-width: 400px;
        width: 90%;
        margin: 0px auto;
    }


    & .singularFormTitle {
        font-size: var(--titleFontSize);
        font-weight: 600;
        color: var(--gray800);
        margin: 30px auto 0 auto;
    }

    & .singularFormTitleSecondPage {
        font-size: 20px;
        font-weight: 600;
        color: var(--gray800);
        margin: 30px auto 0 auto;
    }

    & .signupFormInner {
        flex-direction: column;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -60px;
    }

    & .iframeMarketing {
        float: left;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        & iframe{
            border: none;
        }
    }
    & .rightSizeForm {
        float: right;
        width: 75%;
        height: 100%;
    }


    & .singularFormTopSection {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    & .formDropdown {
        width: 100%;
        margin-top: 5px;
    }
    & .placeholder {
        &::placeholder {
            color: var(--gray700) !important;
            font-size: 14px !important;
            font-style: inherit;

        }
    }
    & .passwordField {
        position: relative
    }

    & .placeholderStyle {
        & :global(.Select__value-container) {
      & :global(.Select__placeholder) {
          color: var(--gray700);
          font-style: italic;
            }
        }
    }


    & .dropDown {
        margin-top: 15px;
        font-size: 14px;
    }

    & .recaptcha {
        margin-top: 20px;
    }

    & .dropDownArrow {
        color: var(--gray700) !important;

    }

    & .singularFormSubtitle {

        font-size: 16px;
        line-height: 28px;
        color: var(--gray800);
        margin: 0 auto 0 auto;
        text-align: center;


        & .bold {
            font-size: 14px;
            font-weight: 700;
            line-height: 48px;
            text-align: center;
            display: block;
        }

        & a {
            color: var(--blue300);
            text-decoration: underline;
        }


    }

    & .footerText {
        font-size: 14px;
        line-height: 28px;
        color: var(--gray700);
        margin: 30px auto 0 auto;
        text-align: center;

        & a {
            color: var(--blue300);
            text-decoration: underline;
        }
    }

    & .textField {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    & .terms {
        margin-top: 15px;
        display: flex;
    }

    & .termsText {
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: var(--gray700);
        line-height: 22px;
        text-align: center;

        & a {
            color: var(--blue300);
        }
    }

    & .loginLink {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 600;
        color:  var(--gray700);
        text-align: center;

        & a {
            color: var(--blue300);
        }
    }


    & .submit {
        margin-top: 30px;

        & .submitButton {
            justify-content: center;
            width: 100%;

        }

        & .submitButtonText {
            margin: auto;
        }
    }

    & .footerSeparator {
        color: var(--gray700);
        border-top: 1px solid var(--gray500);
        margin: 15px auto 20px auto;
        width: 90%;
    }

    & .authTypesSeparator {
        display: flex;
        align-items: center;
        text-align: center;
        width: 50%;
        margin: 30px auto 10px auto;

        &::before, &::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid var(--gray500);
        }

        &::before {
            margin-right: 15px;
        }

        &::after {
            margin-left: 15px;
        }


    }



    & .tooltip {
        position: absolute;
        z-index: 9;
        padding-top: 4px;
        cursor: pointer;

        & svg {
            width: 12px;
        }
    }

    & .fullNameTooltip {
        left: 143px;
    }

    & .passwordTooltip {
        left: 120px;
    }

    & .showPasswordIcon {
        width: 20px;
        height: 12px;
        right: 20px;
        position: absolute;
        z-index: 9;
        top: 39px;
        cursor: pointer;
    }

    & .hidePasswordIcon {
        width: 22px;
        height: 19px;
        right: 20px;
        position: absolute;
        z-index: 9;
        top: 35px;
        cursor: pointer;
    }

    & .signupFormFooter {
        margin: auto;
        width: 65%;
        height: 10%;
    }

    & .signupFormFooterText {
        margin: 80px auto;
        text-transform: uppercase;
        color: var(--blue300);
        font-weight: 600;
        text-align: center;
    }

    & .googleSignupButton {
        margin-top: 25px;
        width: 100%;
    }
    & .googleContinueButton {
        margin-top: 17px;
        width: 100%;
    }

    & .warningMessage {
        margin-top: 15px;
    }

    & .errorMessage {
        color: var(--red300);
        position: absolute;
        font-size: 11px;
    }

      @media (max-width: 1850px) {
        & .iframeMarketing {
            width: 30%;
        }
        & .rightSizeForm {
            width: 70%;
        }
         & .singularFormTopSection {
             width: 55%;
         }
    }
    @media (max-width: 1440px) {
        & .iframeMarketing {
            width: 35%;
        }
        & .rightSizeForm {
            width: 65%;
        }
    }
     @media (max-width: 1284px) {
        & .iframeMarketing {
            display: none;
        }
        & .rightSizeForm {
            width: 100%;
        }
    }
}

.signupThankYou {
    width: 100%;
    height: 100%;
    padding-top: var(--contentPaddingTop);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;

    & .emailSent {
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding-left: 10px;
        font-size: 13px;
        color: var(--green300);
        width: 141px;
    }

    & .emailSentIcon {
        fill: var(--green300);
        padding-bottom: 3px;
        margin-left: 3px;
    }

    & .signupThankYouInner {
        display: flex;
        flex-direction: column;
        justify-self: center;
    }

    & .signupThankYouTitle {
        font-size: var(--titleFontSize);
        font-weight: 600;
        color: var(--gray800);
        margin: var(--titleMargin);
        margin-top: 20px;
    }

    & .signupThankYouSubtitle {
        max-width: var(--contentMaxWidth);
        font-size: var(--subtitleFontSize);
        line-height: 1.57;
        text-align: center;
        margin: var(--subtitleMargin);
    }

    & .signupThankYouResendLink {
        text-align: center;
        margin: auto;
    }

    & .resendButton {
        font-weight: 400;
        padding-left: 10px;
    }

    & .signupThankYouFooter {
        margin-bottom: 5%;
        display: flex;
        justify-content: space-around;
    }

    & .thankYouImg {
        width: 30%;
    }
    & .emailIcon {
        display: block;
        margin: auto;
        margin-bottom: 10px;
    }

    & .resendMobile {}
    & .resendButtonMobile {}
    & .signupTrialExpiredCTASectionMobile {}

}

.signupTrialExpired {
    width: 100%;
    height: 100%;
    padding-top: var(--contentPaddingTop);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;

    & .signupTrialExpiredInner {
        display: flex;
        flex-direction: column;
        justify-self: center;
    }

    & .signupTrialExpiredTitle {
        font-size: var(--titleFontSize);
        font-weight: 600;
        color: var(--gray800);
        margin: var(--titleMargin);
    }

    & .signupTrialExpiredSubtitle {
        max-width: 700px;
        font-size: var(--subtitleFontSize);
        line-height: 40px;
        text-align: center;
        color: var(--gray700);
        margin: var(--subtitleMargin);
    }

    & .signupTrialExpiredCTA {
        margin: auto;
        display: none;
    }

    & .signupTrialExpiredFooter {
        margin-bottom: 5%;
        display: flex;
        justify-content: space-around;
    }

    & .signupTrialExpiredImg {
        width: 750px;
    }
}

@media screen and (max-height: 1050px) {
    .signupFormFooter {
        display: none;
    }
}

@media (--mobile) {
    .signupContainer {
        height: 100%;
        min-height: 100vh;
    }

    .signupForm {
        min-width: inherit;

        & .singularFormTopSection {
            margin-top: 30px;
        }

        & .googleSignupButton {
            margin-top: 10px;
        }

        & .authTypesSeparator {
            margin: 15px auto 5px auto;
        }

        & .singularFormTitle {
            font-size: 14px;
            margin-top: 10px;

            @media (--mobile-narrow) {
                font-size: 12px;
            }
        }

        & .singularFormSubtitle {
            display: none;
        }

        & .singularFormBody {
            margin-top: 10px;
            max-width: 100%;
        }

        & .loginLink {
            margin-top: 10px;
            font-size: 12px;
        }

        & .footerSeparator {
             margin: 7px auto 15px auto;
        }

        & .submit {
            margin-top: 30px;

            @media (--mobile-short) {
                margin-top: 20px;
            }
        }

        & .textField {
            margin-bottom: 20px;

            @media (--mobile-short) {
                margin-bottom: 15px;
            }
        }

        & .termsText {
            margin-top: 15px;
        }

        & .showPasswordIcon {
            top: 35px;
        }

         & .tooltip {
             padding-top: 3px;
         }

    }

    .signupThankYou {
        width: 90%;
        margin: var(--contentMarginMobile);
        padding-top: 0;

        & .signupThankYouTitle {
            margin: var(--titleMarginMobile);
            font-size: var(--titleFontSizeMobile);
        }

        & .signupThankYouSubtitle {
            font-size: var(--subtitleFontSizeMobile);
        }

        & .resendMobile {
            margin-top: 20px;
        }

        & .resendButtonMobile {
            width: 100%;

            & .resendButtonText {
                margin: auto;
            }
        }

        & .thankYouImg {
            width: 100%;
        }
    }

    .signupTrialExpired {
        width: 90%;
        margin: var(--contentMarginMobile);
        padding-top: 0;

        & .signupTrialExpiredTitle {
            margin: var(--titleMarginMobile);
            font-size: var(--titleFontSizeMobile);
        }

        & .signupTrialExpiredSubtitle {
            font-size: var(--subtitleFontSizeMobile);
            line-height: 30px;
        }

        & .signupTrialExpiredImg {
            width: 100%;
        }

        & .signupTrialExpiredFooter {
            margin-bottom: 15%;
        }

        & .signupTrialExpiredCTASectionMobile {
            & .signupTrialExpiredCTAMobile {
                width: 100%;
            }

            & .signupTrialExpiredCTATextMobile {
                 margin: auto;
            }
        }
    }
}