@import "../global/theme.css";

.filterRow {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
}

.buttonsContainer {
    display: flex;
    margin-left: auto;
}

.exportTeamMembersButton {
    flex-shrink: 0;

    & .downloadIcon {
        width: var(--icon-size-small);
        height: var(--icon-size-small);
    }
}

.newMemberButton {
    margin-left: 10px;
    flex-shrink: 0;
}

.page {
    padding: 30px 30px 84px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.memberTypeTabs {
    margin-top: 10px;
}

.preamble {
    color: var(--gray800);
    margin: 0;

}

.headline {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;
}

.selectorContainer {
    font-size: 14px;
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.expandableGroupHolder {
    margin-bottom: 2px;
}

.sectionCheckbox {
    margin-bottom: 0;
    border-radius: 2px;
}

.contentArea {
    flex-shrink: 1;
    overflow: auto;
}

.fallbackIcon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.faqLine {
    line-height: 1.29;
    margin-top: 3px;
    color: var(--gray600);
    & a {
        font-weight: 500;
    }
}