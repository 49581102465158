@import "../../../global/theme.css";

.selectedFields {
    height: 100%;
    overflow: auto;
    margin-right: 15px;
    user-select: none;
    
    & .title {
        font-weight: 600;
        margin-top: 15px;
    }

    & .content {
        & .emptyState {
            text-align: center;
            margin-top: 75px;
        }

        & .itemContainer {
            width: fit-content;
            min-width: 155px;
            margin: 10px 15px 5px 0px;
            display: inline-block;
            font-size: 13px;

            &:hover {
                & .close {
                    opacity: 1;
                }
            }

            & .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1;
                padding: 6px 10px;
                background-color: #eaf3fe;
                border-radius: 6px;
                user-select: none;
                position: relative;

                &.colliding {
                    background-color: var(--gray100);

                    & .indicatorContainer {
                        opacity: 1;
                    }
                }

                & .indicatorContainer {
                    opacity: 0;

                    & .indicator {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: var(--gray500);
                        position: absolute;
                        top: -2px;
                        right: -2px;
                    }
                }
            }

            & .close {
                opacity: 0;
                width: 10px;
                fill: var(--gray600);
                cursor: pointer;
                margin-left: 8px;
            }
        }
    }
}
