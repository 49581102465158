.actions {
    display: flex;
    flex-direction: row-reverse;

    & .userConfiguration {
        margin-right: 10px;

        &:active .userConfigurationIcon {
            fill: white;
        }
    }
}

