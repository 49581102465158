@import "../../../global/theme.css";

.editShelfHeader {
    background-color: var(--blue300);
    font-size: 16px;
    font-weight: 600;
    height: 50px;
}

.warningMessage {
    width: 100%;
    bottom: 74px;
    position: absolute;
    padding-right: 25px;
}

.editShelfContent {
    background-color: var(--white);
    height: calc(100% - 125px);
    overflow-y: scroll;

    &.editShelfContentWithWarning {
        height: calc(100% - 160px);
    }

    & .content {
        padding: 5px 10px 0 20px;

        & .topShelf {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 15px;
            user-select: none;
        }
    }
}

.editShelfIcon {
    margin: 0 12px 2px 0;
    vertical-align: middle;
    width: 18px;
}