@import "../../global/theme.css";

.container {
    vertical-align: middle;
}

.label {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
    color: var(--gray700);
    font-weight: 300;
    vertical-align: middle;
    cursor: pointer;
}
