@import '../../../global/theme.css';

.creativeView {
    border-radius: inherit;
    background-color: var(--gray100);
    overflow: hidden;

    & .creativeType {
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 4px;
        background-color: var(--gray100);
        padding: 0 6px;
        font-weight: 600;

        &.image {
            height: 20px;
            width: auto;
            padding: 0;
        }
    }

    & .creativeAsset {
        border-radius: inherit;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    & .creativeFallback {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: inherit;
        text-align: center;
        padding: 50px 0;
        gap: 9px;
    }
}