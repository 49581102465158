
.formArea {
    display: flex;
    vertical-align: row;
    align-items: end;

    & .dropdownContainer {
        width: 200px;
    }
    & .submitButtons {
        height: 40px;
    }
}

