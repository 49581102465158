@import "../../../../global/theme.css";

.container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;

    & .narrowLeftColumn {
        width: 35%;
    }

    & .leftColumn {

        & p {
            font-size: 14px;
            color: var(--gray800);
        }
    }

    & .rightColumn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 15px;
        width: 65%;
        z-index: 1;

        & .spacerBottom {
            margin-bottom: 15px;
        }
    }
}

