@import "../../global/theme.css";

.sortingPanel {
    background: var(--white);
    margin: 6px 0 10px 0;
    display: flex;
    padding: 6px;
    cursor: pointer;
    font-size: 12px;

    & .priority {
        width: 114px;
        border-right: 1px solid var(--gray400);
    }

    & .sortingField {
        padding-left: 20px;
        display: flex;
        align-items: center;

        & .arrow {
            width: 9px;
            margin-left: 3px;
            fill: var(--gray400);

            &.arrowDesc {
                transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
            }
        }

        &.active {
            color: var(--blue300);
            & .arrow {
                fill: var(--blue300);
            }
        }
    }
}
