.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 180px;
    font-weight: 300;


    & .header {
        font-size: 30px;
    }
    & .subHeader {
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 400;
    }
    & .button {
        margin-top: 20px;
    }
}
