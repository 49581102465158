@import '../../global/theme.css';

:root {
    --disabledText:{
        color: var(--gray500) !important;
    };
    --disabledBackground:{
        background-color: var(--gray300) !important;
    };
    --disabledButton:{
        @apply --disabledText;
        @apply --disabledBackground;
    };
    --activeButtonTextAndIcons:{
        color: var(--white);
    };
    --activeButton:{
        background-color: var(--green400);
        border: var(--green400);
        @apply --activeButtonTextAndIcons;
    };
    --activeButtonHover:{
        background-color: var(--teal300) !important;
        border-color: var(--teal300) !important;
        @apply --activeButtonTextAndIcons;
    };
}

.clearButtonIcon {
    fill: var(--blue300);
    width: var(--icon-size-small);
    height: auto;
}

.editButtonText {
    font-size: 15px;
}

.exportButton {
    @apply --activeButton;
    &:hover {
        @apply --activeButtonHover;
    }
    &[disabled], &:hover[disabled], &:focus[disabled] {
        @apply --disabledButton;
    }
}

.exportButtonActive {
    @apply --activeButtonHover;
}

.exportButtonIcons {
    padding-right: 5px;
}

.exportButtonList {
    width: 200px;
    font-weight: 400;
    margin-top: 5px;
}

.exportButtonItem {
    display: flex;
}

.exportButtonIcon {
    @apply --activeButtonTextAndIcons;
    margin-right: 2px;
    padding-right: 5px;
    min-width: 15px;
}

.exportButtonDisabled {
    @apply --disabledText;
}
