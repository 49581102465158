@import "../../../global/theme.css";

.text {
    @apply --font-size-large;
    color: var(--gray800);
    margin-bottom: 20px;
    line-height: 26px;
}

.actionsContainer{
    display: flex;
    align-items: flex-end;
    position: relative;
}

.actionsRight{
    margin-left: auto;
    text-align: right;
}

.subHeader {
    margin-top: 5px;
}