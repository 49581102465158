@import "../../../global/theme.css";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--gray200);

    & .codingLang {
        color: var(--gray800);
        font-size: 14px;
    }

    & .codingLangPadding {
        padding: 7px 10px;
    }

    & .rightSide {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 7px 10px;

        .item {
            color: var(--blue300);
            margin-right: 15px;
            font-size: 12px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover * {
                color: var(--gray600);
            }

            & .icon {
                margin-right: 5px;
            }
        }
    }
}
