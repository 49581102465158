.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #CCCCCC;

    & .pageTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-left: 2px;
    }

    & .saveDraftTitle {
        color: var(--blue300);
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.contentLayout {
   display: flex;

   & .leftContainer {
        flex: 3;
        display: flex;
        height: 560px;
        padding-top: 18px;
        width: 700px;
        padding-left: 2px;
        &.contentScrollable {
            overflow-y: scroll;
        }
   }

   & .rightContainer {
        flex: 1;
        max-width: 220px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        min-height: 600px;
   }
}
