@import "../../../global/theme.css";

.emptyStateContainer {
    background-color: var(--white);
    padding: 0 var(--pageWrapperLeftPadding);
}

.emptyStateContainerWithMargin {
    margin-bottom: 10px;
}

.topPartContainer {
    display: flex;
    justify-content: space-between;

    & .subHeaderContainer {
        margin-bottom: 0;
    }
}

.header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--gray800);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & .boxImage {
        max-height: 70px;
        max-width: 130px;
        margin-right: 20px;
        border-radius: 10px;
    }

    & .subTitle {
        margin-top: 5px;
        font-weight: normal;
        font-size: 14px;
    }
}

.textWithMargin {
    margin-bottom: 20px;
}

.textWithPadding {
    padding-bottom: 20px !important;
}

:root {
    --actionsRightHeight: 59px;
}

.actionsContainer {
    display: flex;
    align-items: flex-end;
    padding: 0 var(--pageWrapperLeftPadding);
    background-color: var(--white);

    &.sticky {
        position: sticky;
        position: -webkit-sticky; /* for older browsers */
        padding-top: 20px;
        top: 0;
        z-index: calc(var(--stickyZIndex) + 2);
    }

    &.stickyChildren {
        top: 64px;
        box-shadow: 0 5px 5px var(--gray200);
        z-index: calc(var(--stickyZIndex) + 1);
    }
}

.actionsLeft {
    display: flex;
    align-items: center;
}

.actionsRight {
    margin-left: auto;
    text-align: right;
    display: flex;
    align-items: center;
}

.subHeader {
    margin-top: 5px;
}