@import "../../global/theme.css";

.container {
    color: var(--gray700);
    border: 1px solid var(--gray600);
    border-radius: 2px;
    background-color: var(--white);

    & .buttonContent {
        margin: auto;
    }

    &.disabled {
        background-color: var(--gray100);
         & .buttonContent {
             display: none;
         }

         & .spinner path{
             fill: var(--gray700);
         }
    }

     &:hover {
       border-color: var(--gray800);
    }
}