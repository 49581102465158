@import "../../../../../../global/theme.css";

.container {
    & .step {
        width: 100%;
    }

    & .singleMargin {
        margin-bottom: var(--single-text-margin);
    }

    & .doubleMargin {
        margin-bottom: var(--double-text-margin);
    }

    & .landscapeImage {
        max-width: 750px;
        height: auto;
        margin-top: 4px;
    }

    & .stepFourImage {
        max-width: 420px;
        height: auto;
    }

    & .codeBlock {
        width: 625px;
    }

    & .stepAlertText {
        color: var(--gray700);
    }

    & .stepAlertContainer {
        fill: var(--gray700);
    }
}