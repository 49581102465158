@import "../../global/theme.css";

.tdStyle {
    text-align: left;
    overflow: hidden;
    min-width: 100px;
    max-width: 180px;
}

.imgStyle {
    height: 30px;
    max-width: 30px;
    margin: 0 13px 0 0;
    border-radius: 3px;
}

.labelsStyle {
    padding: 10px 0 10px 0;
    max-width: 80%;
    min-height: 50px
}

.subTextStyle {
    font-size: 10px;
    color: black;
}

.subTextExtraStyle {
    font-size: 10px;
    color: #515864;
}

.tableContainer{
    max-height: 50px;
    padding: 0 10px 10px 10px;
    overflow-y: scroll;
}

.tbody{
    text-align: center;
    white-space: pre-line;
}

.search {
    position: sticky;
    display: inline-block;
    padding-bottom: 10px;

    & .searchInput {
        box-sizing: border-box;
        width: 200px;
    }
}

.spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: auto;
    margin: auto;
    align-items: center;
    width: 75px;
    height: 100px;
    fill: var(--blue300);
}

.sectionHeader {
    display: flex;
    margin-bottom: 10px;
    & .text {
        margin-left: 10px;
        margin-top: -3px;
    }
}
