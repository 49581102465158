.paymentCompleteContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    justify-content: center;
    align-items: center;

    .svgContainer {
        margin-left: 20px;
    }

    .paymentCompleteHeader {
        color: var(--gray800);
        font-size: 24px;
        font-weight: 600;
    }

    .paymentCompleteSubheader {
        color: var(--gray700);
        font-size: 18px;
        font-weight: 400;
    }
}





