.container{
    max-width: 100%;
    height: 100%;
    display: flex;

    & .content {
        width: 100%;
        display: flex;
        margin: 0;
        padding: 0;

        & .contentSectionContainer {
            padding: 0 20px;
        }
    }

    & .list {
        width: 257px;
    }
}


