@import "../../../global/theme.css";

.searchContainer {
    display: flex;
    width: 380px;

    & .search {
        width: calc(100% - 65px);

        & :global(.Select__control) {
            border-radius: 5px !important;
            cursor: text !important;
            border-right: unset;
            border-top-right-radius: unset !important;
            border-bottom-right-radius: unset !important;
        }
    }

    & .searchIconSector {
        background-color: white;
        border: 1px solid var(--gray400);
        border-left: none;
        border-radius: 5px;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 35px;

        & .searchIcon {
            width: 19px;
            height: 19px;
        }
    }
}
