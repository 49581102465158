@import "../../global/theme.css";
@import "./ReactSelectOverrides.css";

.container {
    position: relative;
    width: 407px;
    border-radius: 3px;
    border-color: var(--gray400);
    background-color: var(--white);
    cursor: text;
    min-height: 30px;
    height: 100%;
}

.tagContainer {
    display: inline-flex;
    align-items: center;
    margin: 0 4px 4px 0;
    padding: 0 5px;
    border: 1px solid var(--gray300);
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    cursor: default;
    border-radius: 2px;
    background: var(--gray200);
    & * {
        outline: none;
    }
}

.sortableHelper {
    z-index: 1400;
}

.tagLabel {
    display: inline;
}

.icon {
    padding-right: 3px;
    display: flex;
}

.subTitle {
    color: var(--gray700);
    padding-left: 2px;
    padding-right: 5px;
}
.tagX {
    margin-left: 7px;
    margin-top: 1px;
    display: flex;
    cursor: pointer;
    align-items: center;
    &:hover {
        & .clearIcon {
            fill: red !important;
        }
    }

    & path {
        fill: var(--gray700);
    }
}

.tagLock {
    margin-left: 7px;
    width: 11px;
    height: 11px;
    margin-bottom: 3px;
    color: var(--gray700);
    margin-right: 1px;
}

.clearIcon {
    width: 8px !important;
    height: 20px !important;
}

.errorMessage {
    color: var(--red300);
    position: absolute;
    right: 0;
    font-size: 11px;
}

.tagInputControl {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.headerContainer {
    border: 1px solid var(--gray400);
    border-right: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-weight: 600;
    color: var(--blue300);
    display: flex;

    &.isDisabled {
        color: var(--primaryButtonTextDisabled);
        background-color: var(--primaryDisabledLight);
        border-color: var(--gray300);

        & svg {
            stroke: var(--primaryButtonTextDisabled);
        }
    }

    & +:global(.Select__control) {
        border-left: none;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.headerIcon {
    padding: 8px 0 0 10px;
}

.headerText {
    padding: 10px 10px 0;
}

.verticalLine {
    width: 1px;
    background-color: var(--gray400);
    margin: 7px 0;
}
