@import "../../global/theme.css";
@import "./ReactSelectOverrides.css";

.container {
    width: 407px;
    border-radius: 3px;
    border-color: var(--gray400);
    background-color: var(--white);
    cursor: text;
    min-height: 30px;
    height: 100%;
}

.tagContainer {
    display: inline-flex;
    align-items: center;
    margin: 0 4px 4px 0;
    padding: 0 5px;
    border: 1px solid var(--gray300);
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    cursor: default;
    border-radius: 2px;
    background: var(--gray200);
    & * {
        outline: none;
    }
}

.tagLabel {
    display: inline;
}

.tagX {
    margin-left: 7px;
    margin-top: 1px;
    display: flex;
    cursor: pointer;
    align-items: center;
    &:hover {
        & .clearIcon {
            fill: red !important;
        }
    }

    & path {
        fill: var(--gray700);
    }
}

.tagLock {
    margin-left: 7px;
    width: 11px;
    height: 11px;
    margin-bottom: 3px;
    color: var(--gray700);
    margin-right: 1px;
}

.clearIcon {
    width: 8px !important;
    height: 20px !important;
}