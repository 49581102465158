@import "../../global/theme.css";

/* The container */
.container {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 300;
  align-items: center;

  &.small {
    & .checkmark {
      width: 14px;
      height: 14px;
    }
    &:not(.indeterminate) .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 9px;
      border-width: 0 2px 2px 0;
    }
  }
}

.container.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  & .checkmark {
    background-color: var(--gray200);
    }
}

.container.reverse {
  flex-direction: row-reverse;
  & .checkmark {
    margin-left: 10px;
    margin-right: 0;
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border: 1px solid var(--gray400);
  border-radius: 2px;
  position: relative;
  margin-left: 0;
  flex-shrink: 0;
  margin-right: 10px;

  &.error {
    border-color: var(--red300);
  }
}

.label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray600);

  &.errorLabel {
    color: var(--red300);
  }
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark, .container.indeterminate .checkmark {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container:not(.indeterminate) .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.container.indeterminate .checkmark:after {
  display: block;
  left: 4px;
  right: 4px;
  height: 3px;
  border-radius: 1px;
  background-color: white;
  top: 0;
  bottom: 0;
  margin: auto;
}