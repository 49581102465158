@import "../../global/theme.css";
@import "../../global/general.css";

.header {
    background-color: var(--gray700);
    color: var(--white);
    width: 100%;
    text-align: center;
    line-height: 28px;
    height: 28px;
    font-weight: 400;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    font-size: 11px;
    border-radius: 2px 2px 0 0;
}

.content{
    font-size: 12px;
    color: var(--gray800);

        & .contentText {
        color: var(--gray700);
        font-size: 10px;
        font-weight: 400;
    }
}