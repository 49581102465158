@import '../../../../global/theme.css';

.overrideContainer {
    background-color: transparent;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }

    .panelContent {
        background-color: transparent;
        padding: 0;
    }

    .panelHeaderExpanded {
        box-shadow: none;
        border-bottom: none !important;
        border-radius: 2px 2px 0 0;

        & .expanded .arrowArea {
            margin-left: auto;
            transform: rotate(90deg);
            display: flex;
            align-items: center;
            transition: all 0.3s ease-in-out;
        }
    }

    .panelHeader {
        height: 60px;
        border-radius: 2px;
    }

    .overrideArrow {
        color: var(--primaryColor) !important;
        width: 32px !important;
        height: 32px !important;
        transform: rotate(270deg);
        margin-top: 5px;
    }

    .overrideOldArrowArea {
        display: none;

        & .expandableOverrideExternal {
            margin-right: 10px;
        }
    }

    .collapsableContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}