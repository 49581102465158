@import "../../global/theme.css";

.container {
    padding: 0px 30px 105px 30px;
    line-height: 1.6;
    margin: 25px 0 6px;
    font-size: 14px;
    max-width: 100%;
    color: var(--gray700);

    & * {
        outline-width: 2px;
    }
}

.text {
    font-size: 14px;
    font-weight: 600;
    margin-top: -1px;
}

.rulesTitle {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin-bottom: 15px;
    color: var(--gray800);
}

.sectionHeader {
    display: flex;
    margin-bottom: 5px;
    & .text {
        margin-left: 10px;
        margin-top: -3px;
    }
}

.reportTypeDropdown {
    display: flex;
    width: 100%;
}

.dropdown {
    width: 100%;
    height: 36px;
}

.textInputLine {
    font-weight: 600;
    color: var(--black);
}

.inputHeader {
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 3px;
    &::before {
        content: "*\00a0";
        white-space: nowrap;
        color: var(--red300);
    }
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--gray400);
    margin-top: 19px;
    margin-bottom: 8px;
}

.shelfGroupInfo {
    font-size: 11px;
    margin-left: 4px;
}

.thresholdCondition {
    display: flex;
    justify-content: left;
    margin-top: 10px;
}

.filter {
    margin-top: 9px;
    display: flex;
    justify-content: left;

}

.filterSpacing {
    padding-top: 13px;
}

.metricFormat {
    font-size: 18px;
    width: 0px;
    padding-top: 2px;
    margin-left: 10px;
    margin-right: 4px;
}

.clearIcon {
    padding-top: 11px;
    fill: var(--gray600);
}

.plusIcon {
    fill: var(--blue300);
    padding-top: 11px;
}

.leftBorderIcons {
    margin-left: -10px;
}

.ovalIcons {
    margin-left: -7px;
}
.ovalFilled {
    & * {
        fill: var(--gray700) !important;
    }
}
.gridWrapper {
    display: grid;
    grid-template-columns: 25px 610px;
    align-items: start;
    border-left: 2px solid var(--gray700);
}

.metricGroup {
    margin-bottom: 23px;
}

.shelfGroup {
    margin-top: -5px;
    margin-bottom: 23px;
    font-size: 12px;
    margin-left: -10px;
}

.shelfGroupContent {
    padding: 0px 10px 0px 10px;
}

.shelfGroupLabelContainer {
    font-size: 13px;
    font-weight: 600;
    &::after {
        content: " (Optional)";
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
        color: var(--gray600);
    }
}
