@import '../global/theme.css';


.pageDivider {
    background-color: var(--gray400);
    height: 1px;
    margin: 20px 0;
}

.active {
    background-color: var(--blue300);
}

.spinner {
    position: absolute;
    top: calc(50% - 30px);
    width: 100%;
}
