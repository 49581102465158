@import "../../../../global/theme.css";

.description {
    font-size: 13px;
    color: var(--gray600);
    margin-bottom: 10px;
    line-height: 1.57;
}

.editor {
    width: 100% !important;
    height: calc(100% - 120px) !important; /* form height less section's title & description */
}

.errorMsg {
    color: red;
    font-size: 11px;
    direction: rtl;
}


.errorIndicator {
    border: 1px red solid;
}

:global .CodeMirror {
    height: 100% !important;
}

:global .CodeMirror-lint-tooltip {
    z-index: 1300 !important;  /* should be same as modal z-index */
    font-family: revert !important;
}
