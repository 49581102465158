@import '../../global/theme.css';

.container {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 15px;
    font-weight: 300;
    &:hover {
        border-bottom: 1px dashed var(--gray400);
        padding-top: 1px;
        & .buttonsArea {
            display: flex;
        }
    }
}

.domainNoHover {
    height: 25px;
    font-size: 15px;
    font-weight: 300;
}

.buttonsArea {
    margin-left: auto;
    display: none;
    align-items: center;
}

.buttonContainer {
    margin-right: 10px;
}

.button {
    fill: var(--gray600);
    width: 20px;
    height: 20px;
    cursor: pointer;
    & * {
        fill: var(--gray600);
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover * {
        fill: var(--gray700);
    }
    &.marked * {
        fill: var(--blue300);
    }
    &.marked:hover * {
        fill: var(--blue400);
    }
    &.archive:hover * {
        fill: var(--red300) !important;
    }
}

.defaultMark {
    margin-left: 5px;
    font-style: italic;
    color: var(--gray700);
    font-weight: 400;
}
