@import "../../global/theme.css";

.stickyShadow {
  position: sticky;
  position: -webkit-sticky;  /* for older browsers */
  z-index: calc(var(--stickyZIndex) - 1);

  height: 1px;
  width: 100%;
  background-color: var(--gray400);
}

.stickyShadowCover {
  height: 1px;
  width: 100%;
  background-color: var(--bodyBackgroundColor);
  z-index: calc(var(--stickyZIndex) - 1);

  /* for cover */
  position: relative;
  top: -1px;
}
