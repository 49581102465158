.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeIn {
    0% {opacity: 0; transform: translateY(50px)}
    80% {opacity: 0; transform: translateY(50px)}
    100% {opacity: 1; transform: translateY(0)}
}

.icon {
  width: 40px;
  animation: fadeIn 0.7s ease-in-out;
}

.textContainer {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & a {
      cursor: pointer;
  }
}