@import "../../global/theme.css";

.copyAreaWithQRCodeContainer {
    display: flex;

    & .copyAreaContainer {
        width: 476px;
        margin-right: 22px;
    }

    & .qrCodeContainer {
        width: 122px;
    }
}
