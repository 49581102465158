@import "../../global/theme.css";

.container {
  font-size: 16px;
  background-color: white;
  border-radius: 2px;
  padding: 10px 20px;
  margin: 16px 0;
  &.open {
    & .arrow {
      transform: rotate(90deg);
    }
  }
}

.titleContainer {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  color: var(--gray700);
}

.arrow {
  border-left: 6px solid var(--gray700);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-right: 8px;
}

.content {
  display: block;
  padding: 20px 0;
}