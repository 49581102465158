@import './TableCellImgLabel.css';

.container {
    display: flex;
    align-items: center;
    max-width: 150px;
    font-style: italic;
    color: var(--gray700);

    & .agencyIcon {
        margin-right: 5px;
    }
}
