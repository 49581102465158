@import "../../global/theme.css";

.upgradePlansContainer {
    --thank-you-color: var(--blue300);
    display: flex;
    flex-direction: column;
    padding: 36px 0;
    width: 100%;
    .upgradePlansHeaderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }
    .lineSeperator {
        width:220px;
        height: 1px;
        margin: 8px 0;
        background-color: var(--gray400);
    }
    .upgradePlanHeader {
        color: var(--gray800);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
    }

    .planCardsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 32px;
        max-width: 100%;
        flex-wrap: wrap;

    }

    .thankYouLabel {
        color: var(--thank-you-color);
        font-weight: 600;
        line-height: 150%;
        font-size: 14px;
    }
}
