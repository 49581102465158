@import "../styles.css";

.selectorContainer {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.preamble {
  margin: 0;
  flex-grow: 1;
}

.screenContainer {
  position: relative;
}

.screenWarning {
  color: var(--warning1Color);
  width: 16px;
  height: 14px;
  position: absolute;
  left: -28px;
  top: 4px;
}