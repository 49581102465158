@import "../../../global/theme.css";

:root {
    --tablePadding: 0 15px 0 0;
}

.titleContainer {
    font-family: 'sacramento';
    display: flex;
    justify-content: center;
    font-weight: 400;
    height: 80px;
    font-size: 60px;
    line-height: 98px;

    &.welcomeTitle {
        color: var(--blue800);
        margin-bottom: 25px;
    }

    &.doneTitle {
        color: var(--turquoise100);
    }
}

.stepperIconCustom {
    height: 25px !important;
    width: 25px !important;
    font-size: 14px;
}

.stepperConnectorCustom {
    margin: 10px 0 0;
    background-color: var(--gray700);
}

.stepActiveIconCustom:after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    bottom: -7px;
    right: -7px;
    border: 1px solid var(--gray700);
    border-radius: 50%;
    background-color: var(--white);
}

.stepIconConnectorCustom {
    background-color: unset !important;
}

.wizardFooter {
    bottom: unset;
    background-color: unset;
    position: relative;
    padding: 10px 0;
}

.firstStepContainer {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--onboardingPlanningHeight);

    & .topTextContainer {
        text-align: center;
    }

    & .platformSelectionTitle {
        margin: 50px auto 30px auto;
        color: var(--gray800);
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        display: block;
    }

    & .platformQuestion {
        margin-top: 28px;
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
    }

    & .platformSelectionContainer {
        & .platformCheckboxesContainer {
            margin-top: 25px;
            display: grid;
            gap: 20px;
            flex-wrap: wrap;
            grid-template-columns: [col1] 50% [col2] 50%;

            & .platformCheckboxContainer {
                cursor: pointer;
                user-select: none;
                width: 180px;
                height: 80px;
                border-radius: 8px;
                border: solid 1px var(--gray400);
                padding: 16px;
                display: flex;
                justify-content: space-between;

                & .platformCheckbox {
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 600;
                }

                & .checkboxLogoContainer {
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    border-radius: 4px;
                    background-color: var(--gray300);
                    margin: auto 0;
                }

                & .checkboxLogo {
                    width: 24px;
                    height: 24px;
                }

                &.active {
                    border-color: var(--blue300);
                    background-color: var(--blue100);

                    & .checkboxLogoContainer {
                        background-color: var(--blue300);
                    }

                    & .checkboxLogo {
                        color: var(--blue100);
                    }
                }

                &.disabled {
                    pointer-events: none;
                    background-color: var(--gray100);

                    & .checkboxLogo {
                        color: var(--gray500);
                    }
                }
            }
        }

        & .selectPlatformWarning {
            &.hide {
                opacity: 0;
            }

            color: var(--attention);
            margin-top: 9px;
        }
    }


    & .targetChildrenQuestion {
        margin-top: 24px;
        display: flex;
        gap: 10px;
        align-items: center;
    }
}

.loader {
    position: absolute;
    top: 30%;
    left: 45%;
}

.trueIcon {
    fill: var(--gray700);
}

.falseIcon {
    fill: var(--gray400);
}

.answerText {
    font-weight: 400;
    font-size: 14px;
}

.platformIcons {
    height: 40px;
    align-items: center;
    justify-content: flex-end;
    padding: var(--tablePadding);
}

.summaryPageFooter {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
}

.summaryNextButton {
    margin-left: 20px;
}

td.checkboxTd {
    padding: 0 0 0 25px;
}
