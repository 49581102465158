@import "../../global/theme.css";

.container{
  padding: 0px 13px;
  height: 36px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  @apply --font-button;
  border-radius: 2px;
  & * {
    outline: none;
  }
}

.buttonInner{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledText {
      color: var(--gray400);
}

.icon {
    margin-left: 10px;
}

.oldPrimary {
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryColor);
  color: var(--white);
  &[disabled] {
    color: var(--gray500);
    background-color: #c6c6c6;
    border: 1px solid var(--gray400);
  }

  &.compare {
     background-color: transparent;
     width: 40px;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0;
     border-top: 1px solid var(--gray400);
     border-right: 1px solid var(--gray400);
     border-bottom: 1px solid var(--gray400);
     border-left:0;
     margin-left: -5px;
     margin-right: 10px;
     vertical-align: bottom;

     & .Icon {
         margin-left: 3px;
     }

     &:hover {
       background-color: var(--blue100);
    }
  }
}
.oldSecondary {
    border: 1px solid var(--primaryColor);
    background-color: var(--white);
    color: var(--primaryColor);
    &[disabled] {
        color: var(--gray500);
        background-color: #c6c6c6;
        border: 1px solid var(--gray400);
    }
    &.remove_compare {
        border: none;
        background-color: transparent;
        color: var(--primaryColor);
    }
}

.primary {
    display: inline-block;
    border: none;
    border-radius: 2px;
    background-color: var(--blue300);
    color: var(--primaryLight);
    &:hover {
        background-color: var(--blue400);
    }
    &.active {
        background-color: var(--blue500);
    }
    &[disabled], &:hover[disabled], &:focus[disabled] {
      background-color: var(--primaryDisabledLight);
      color: var(--primaryDisabledDark);
    }
}

.secondary {
    display: inline-block;
    border-radius: 2px;
    background-color: var(--secondaryLight);
    color: var(--secondaryDark);
    border: 1px solid var(--secondaryDark);
    &:hover {
        background-color: var(--secondaryHover);
        border-color: var(--secondaryHover);
        color: var(--secondaryLight);
    }
    &.active {
        background-color: var(--secondaryActive);
        border-color: var(--secondaryActive);
        color: var(--secondaryLight);
    }
    &[disabled] {
        background-color: var(--secondaryLight);
        border: 1px solid var(--secondaryDisabled);
        color: var(--secondaryDisabled);
    }
}

.flat {
    display: inline-block;
    border: none;
    border-radius: 2px;
    background-color: unset;
    color: var(--secondaryHover);
    &:hover {
        background-color: var(--flatHover);
    }
    &.active {
        background-color: var(--secondaryDisabled);
    }
    &[disabled], &:hover[disabled], &:focus[disabled] {
      color: var(--flatDisabled);
    }
}