.editContainer {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: smooth;
}

.editContent {
    overflow: auto;
}

.editTop {
    height: 1000px;
    overflow: hidden;
    padding-bottom: 50px;
}

.spinner {
    position: absolute;
    top: calc(50% - 30px);
    width: 100%;
}