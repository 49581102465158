@import "../../global/theme.css";

.container {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    align-items: center;
    padding: 0 6px 0 10px;
    height: 30px;
    transition: transform 0.1s ease-in-out;
    outline: none;
    &.blockStyle {
        background-color: var(--gray300);
        border: solid 1px var(--gray400);
    }
    &.skanPlusCollapsed {
        color: var(--skanPlusGradientEnd) !important;
        background-image: linear-gradient(white, white), linear-gradient(var(--skanPlusGradientStart), var(--skanPlusGradientEnd));
        background-clip: content-box, border-box;
        padding: 2px;
        height: 33px;
    }
    &.skanPlusExpanded {
        color: var(--white) !important;
        background-image: linear-gradient(95deg, var(--skanPlusGradientStart) 1%, var(--skanPlusGradientEnd) 100%);
        height: 33px;
    }

    & .skanPlusIcon {
        margin-right: 10px;
    }
    &.collapsed {
        & .arrowArea {
            transform: rotate(-90deg);
        }
    }
    &[disabled] {
        opacity: 0.4;
        cursor: default;
    }

    & sup {
        margin-left: 2px;
    }
}

.arrowArea {
    display: flex;
    align-items: self-end;
    transform: rotate(0deg) translateY(1px);
    & .arrow {
        fill: var(--gray700) !important;
        width: 13px;
        margin: 0 5px;
    }
    &.skanPlus {
        margin-right: 5px;
        margin-left: auto;
    }
    &.blockStyle {
        margin-left: auto;
    }
    & .skanPlusCollapsed {
        color: var(--skanPlusGradientEnd) !important;
    }
    & .skanPlusExpanded {
        color: var(--white) !important;
    }
}

.content {
    padding: 20px 10px 0 10px;
}