.container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;

    & .selectionsContainer{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .tabs {
        padding: 0;
        margin: 28px 0;
        width: 100%;
    }
}

.spacerEmptyState{
        margin: 0 20px 20px;
        width: 100%;
    }