@import "../global/theme.css";

:root {
   /* If changing those values, change also the values in consts.js */
    --sidenavWidth: 230px;
    --sidenavSlimWidth: 60px;
    --sectionHoverColor: #264D77;
    --sectionSelectedColor: var(--blue500);
    --textSelectedColor: var(--blue100);
    --slimButtonSectorHeight: 30px;
    --topbarHeight: 50px;
    --footerHeight: 50px;
}


.navSeparator {
    width: 84%;
    height: 1px;
    margin: 10px 15px;
    background-color: var(--blue400);
}

.mainContainer {
    height: 100%;
    width: 100%;
}

.widthAnimation {
    transition: width 0.5s;
}

.bodyContainer {
    float: right;
    height: 100%;
    width: calc(100% - var(--sidenavWidth));
    position: relative;

    &.slimMode {
        width: calc(100% - var(--sidenavSlimWidth));
    }
}

.sidenavContainer {
    width: var(--sidenavWidth);
    height: calc(100% - var(--topbarHeight));
    float: left;
    position: fixed;
    background-color: var(--sidenavBackgroundColor);
    user-select: none;
    z-index: 999;
    top: var(--topbarHeight);

    &.slimMode {
        width: var(--sidenavSlimWidth);
    }


    & .slimButtonSector {
        height: var(--slimButtonSectorHeight);
        display: flex;
        justify-content: end;
        align-items: center;

        & .slimButton {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--blue300);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 10px;

            &.slimMode {
                margin-right: -10px;
            }

            & .slimButtonIcon {
                fill: var(--white);
                width: 11px;
                height: 11px;
            }

            & .chevronRight {
                margin-right: -2px;
                transform: rotate(-90deg);
            }

            & .chevronLeft {
                transform: rotate(90deg);
                margin-right: 2px;
            }
        }
    }

    & a {
        text-decoration: none !important;
    }

    & .selected {
        background-color: var(--sectionSelectedColor);
        color: var(--textSelectedColor) !important;
    }

    & .sidenavSections {
        height: calc(100% - var(--footerHeight) - var(--slimButtonSectorHeight));
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;

        &.slimMode {
            height: calc(100% - var(--slimButtonSectorHeight));
        }
    }

    & .footer {
        text-align: center;
        padding: 5px 0;
        height: var(--footerHeight);
        color: var(--gray500);
        white-space: nowrap;
        overflow: hidden;

        & .footerTitle {
           font-size: 14px;
            letter-spacing: .3px;
        }

        & .footerLink {
            font-size: 10px;
        }

        & a {
            color: var(--gray500) !important;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

.navSectionContainer {
    color: var(--white) !important;
    display: block;

    & ul {
        padding: 0;
    }

    & sup {
        margin-left: 5px;
    }

    &.linkSection {
        &:hover {
            background-color: var(--sectionHoverColor);
        }
    }

    & .navSubSection {
        color: var(--gray300);
        text-transform: none;
        font-size: 14px;
        font-weight: 400;

        &:hover {
            cursor: pointer;
            background-color: var(--sectionHoverColor);
        }

        & .subSectionLink {
            display: block;
            position: relative;
            padding: 8px 10px 9px 55px;
        }

        & a {
            color: inherit !important;
        }
    }

    & .progressBarContainer {
        width: 80%;
        margin: auto;

        & .progressBarSection {
            height: 7px;
            margin-bottom: 0px;
        }

        & .progressBarText {
            font-size: 11px;
            font-weight: 400;
            padding: 7px 0px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & .externalLinkSection {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .externalLinkIcon {
           display: none;
        }

        &:hover {
            & .externalLinkIcon {
               display: initial;
            }
        }
    }
}

.navSection {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    text-transform: capitalize;
    padding: 10px 16px;

    &:hover {
        cursor: pointer;
        background-color: var(--sectionHoverColor);
        transition: ease all 0.5s, font-size 1ms;
    }

    &.slimMode {
        padding: 14px 10px;
        display: flex;
        justify-content: center;
    }
}

.slimIconChevron {
    fill: var(--white);
    margin-left: -8px;
    width: 11px;
    height: 6px;
    transform: rotate(-90deg);
}

.navLogo {
    margin-top: -1px;
    margin-right: 10px;

    &.slimMode {
        margin-right: 0px;
    }


    & .selected {
        g {
            fill: var(--blue300) !important;
        }
    }

}

li.navSubSection {
    list-style: none !important;
}

.navbarTooltipSubsectionItem {
    &:hover {
         background: var(--sectionHoverColor) !important;
     }
}

.tooltip {
    background-color: #1E3F5EE8 !important;
}