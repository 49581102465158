@import "../../../../global/theme.css";

:root {
    --list-item-border: 1px solid var(--gray300);
    --section-header-background-color: var(--gray200);
    --list-item-height: 52px;
    --circle-number-diameter: 22px;
    --number-margin-right: 10px;
    --single-indent: calc(var(--circle-number-diameter) + var(--number-margin-right));

    --list-item-number: {
        font-size: 14px;
        text-align: center;
        margin-right: var(--number-margin-right);
        line-height: 20px;
        color: var(--gray700);
    };
}

.listItem {
    height: var(--list-item-height);
    background-color: var(--white);
    border-bottom: var(--list-item-border);

    & .number {
        @apply --list-item-number;
    }

    & .circleIcon {
        @apply --list-item-number;
        min-width: var(--circle-number-diameter);
        min-height: var(--circle-number-diameter);
        max-width: var(--circle-number-diameter);
        max-height: var(--circle-number-diameter);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.numberCircle {
            background: var(--white);
            border: 1px solid var(--gray700);
        }
    }

    & .indent0{
        padding-left: 0;
    }

    & .indent1 {
        padding-left: var(--single-indent);
    }

    & .indent2 {
        padding-left: calc(2 * var(--single-indent));
    }

    & .indent3 {
        padding-left: calc(3 * var(--single-indent));
    }

    & .indent4 {
        padding-left: calc(4 * var(--single-indent));
    }

    & .listItemText {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        font-weight: normal;
        white-space: nowrap;
        z-index: 101;

        & .listItemTextParagraph {
            white-space: break-spaces;
        }
    }

    &:hover {
        background-color: var(--blue100);
        cursor: pointer;
    }

    & .listItemSuffix {
        display: flex;
        z-index: 101;
        position: absolute;
        right: 16px;
    }

    & .badgeLayout {
                text-align: end;
                min-width: 90px;
                position: relative;

                & .badgeText {
                    text-align: center;
                    font-size: 11px;
                    font-weight: 400;
                    color: var(--secondaryDark);
                    position: absolute;
                    right: 10px;
                    bottom: 0;
                }
            }
}
.selectedListItem {
    background-color: var(--blue100);
}

.disabledListItem {
    cursor: not-allowed !important;
}

.expandIcon, .collapseIcon {
    width: 13px;
    height: 13px;

    & g {
        fill: var(--blue300)
    }
}

.expandIcon {
    transform: rotate(-90deg);
}
