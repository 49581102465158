@import '../../../global/theme.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: auto;
    gap: 32px;

    .title {
        font-size: 24px;
        font-weight: 600;
    }

    .stepsContainer {
        display: flex;
        gap: 16px;

        .stepBox {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;

            width: 130px;
            height: 130px;
            border-radius: 20px;
            border: var(--gray400) solid 1px;

            .stepNumber {
                font-size: 13px;
                font-weight: 600;
                color: white;
                width: 24px;
                height: 24px;
                background-color: var(--gray700);
                border-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                font-size: 14px;
            }
        }
    }
}