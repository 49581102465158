@import "../../global/theme.css";

.container {
  position: relative;
  &.marked:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--primaryButtonBackground);
  }

  &.markedWarning:after {
    background-color: var(--warning1Color);
   }
}

.headerArea {
  background-color: var(--white);
  display: flex;
  height: 52px;
  align-items: center;
  padding: 0 20px 0 6px;
}

.titleIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  & .minus, & .plus {
    width: 12px;
    height: 2px;
    background-color: var(--gray500);
  }

  & .plus:after {
    position: absolute;
    content: '';
    width: 12px;
    height: 2px;
    transform: rotate(90deg);
    background-color: inherit;
  }
}

.animatedArea {
  overflow: hidden;
  background-color: var(--white);
}

.titleRightArea{
  margin-left: auto;
}

.contentContainer {
  padding: 0 40px 18px;
}