@import "../global/theme.css";

:root {
    --section-item-border: 1px solid var(--gray400);
    --list-item-border: 1px solid var(--gray300);
    --menu-section-border: 1px solid var(--gray300);
    --content-background: var(--gray100);
    --content-unvisitied-background: var(--gray100);
    --section-header-background-color: var(--gray200);
    --section-item-background-color: var(--gray100);
    --circle-number-diameter: 22px;
    --circle-number-margin-right: 10px;
    --list-item-height: 60px;
}

& .onboardingTooltip {
    height: 75px;
    width: 318px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.onboardingGuideLayout {
    height: 88vh;
    min-height: 800px;
    min-width: 300px;

    & ul {
        text-align: left;
    }

    & ol {
        padding: 5px 0 0 20px;
        text-align: left;
    }

    & .onboardingGuidePageHeader{
        display: flex;
        flex-direction: column;

        & .onboardingGuideMainHeader {
            color: var(--purple300);
            font-size: 20px;
            font-weight: 600;
          }

        & .onboardingGuideSubHeaderContainer{
            display: flex;
            justify-content: space-between;
          }

        & .onboardingGuideSubHeader {
            font-size: 16px;
            font-weight: 600;
            color: var(--gray700);
            margin-top: 5px 0;
            display: flex;
            align-content: center;
          }

      & .progressBarContainer {
            display: flex;
            align-items: center;
        }

      & .progressBarText {
            width: max-content;
            white-space: nowrap;
            margin-right: 10px;
        }
    }

    & .onboardingGuideMenuList {
        margin-top: 10px;

        & .sectionContainer {
            background-color: var(--white);
            display: flex;
            border-top: 0;
            max-width: 100%;
            overflow-x: auto;
        }

        & .sectionList {
            width: 33%;
            min-width: 295px;
            border-top: var(--section-item-border);
            border-bottom: var(--section-item-border);
            border-left: var(--section-item-border);
        }

        & .sectionContent {
            border: var(--section-item-border);
            width: 100%;
            background-color: var(--white);
            position: relative;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            padding-bottom: 15px;
        }

        & .sectionListItem {
            background-color: var(--section-header-background-color);
            height: 70px;
            border: var(--section-item-border);
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            cursor: pointer;
            scroll-margin-top: 17px;

            &.completed {
                background-color: var(--content-background);
            }

            & .collapseExpand {
                width: 15px;

                & .collapseIcon {
                    overflow: visible;
                }

                & .expandIcon {
                    overflow: visible;
                }
            }

            & .sectionListItemText {
                margin-left: 10px;
                font-size: 18px;
                font-weight: 600;
                color: var(--gray800)
            }

        }

        & .listItemHeader {
            background-color: var(--bodyBackgroundColor) !important;
            height: 69px !important;
            cursor: auto !important;
            align-items: flex-end;

            & .listItemTextHeader {
                font-weight: 600 !important;
                font-size: 16px !important;
            }

            & .listItemHeaderInternalDiv {
                align-items: center;
                display: flex;
                height: 24px; /*this prevents the icon in the div from stretching*/
            }
        }

        & .selectedListItem {
            background-color: var(--blue100) !important;
        }

        & .listItem {
            height: var(--list-item-height);
            background-color: var(--white);
            border-bottom: var(--list-item-border);
            display: flex;
            padding-left: 16px;

            & .hoverIndicator {
                display: none;
                position: absolute;
                right: 15px;
                top: 13px;
                width: 15px;
                height: 15px;
                fill: var(--blue300);
                transform: rotate(135deg);
            }

            &.hide {
                opacity: 0;
                left: -30px;
                height: 5px;
            }

            &.show {
                opacity: 1;
                left: 0;
                transition: all 0.5s ease;
            }

            &:hover:not(.disabledItem) {
                background-color: var(--blue100);
                cursor: unset;

                & .hoverIndicator {
                    display: block;
                }
            }

            &.completed {
                background-color: var(--content-background);

                &:hover {
                    background-color: var(--blue100);
                }
            }
            & .sectionNumber {
                border-radius: 50%;
                width: var(--circle-number-diameter);
                height: var(--circle-number-diameter);
                color: var(--gray700);
                border: 1px solid var(--gray700);
                font-size: 14px;
                text-align: center;
                margin-right: var(--circle-number-margin-right);
              }

            & .listItemText {
                font-size: 14px;
                font-weight: normal;
                color: var(--gray700);
                white-space: nowrap;
            }

            & .listItemTextSelectedWithNumber {
                font-size: 14px;
                font-weight: normal;
                color: var(--gray700);
                white-space: nowrap;
                height: 100%;
                background-color: var(--blue100) !important;
                z-index: 100;
                position: absolute;
                padding-top: calc(var(--list-item-height) / 3);
                margin-left: calc(var(--circle-number-margin-right) + var(--circle-number-diameter));
            }

            & .listItemTextSelected {
                font-size: 14px;
                font-weight: normal;
                color: var(--gray700);
                white-space: nowrap;
                height: 100%;
                background-color: var(--blue100) !important;
                z-index: 100;
                position: absolute;
                padding-top: calc(var(--list-item-height) / 3);
            }

            & .listItemSuffix {
                display: flex;
                z-index: 101;
                position: absolute;
                right: 16px;
            }

            & .badgeLayout {
                text-align: end;
                min-width: 90px;
                position: relative;

                & .badgeText {
                    text-align: center;
                    font-size: 11px;
                    font-weight: 400;
                    color: var(--secondaryDark);
                    position: absolute;
                    right: 10px;
                    bottom: 0;
                }
            }
        }
    }
}

.errorIcon {
    width: 10px;
    margin-right: 20px;
}

.onboardingIcon {
    margin-right: 7px;
}

.tooltip {
    font-size: 13px !important;
    margin: 0 auto;
    background-color: var(--white) !important;
    color: var(--gray600) !important;
    border: 1px solid var(--gray500);
}

.opaqueBackground {
    opacity: 1!important;
}
