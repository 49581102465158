@import "../../../global/theme.css";

:global(.react-grid-item.react-grid-placeholder) {
    background-color: var(--blue100) !important;
    opacity: 0.9 !important;
}

:global(.react-draggable-dragging) {
    z-index: calc(var(--stickyZIndex) + 2) !important;
}

.gridWidget {
    display: flex;
    flex-direction: column;

    & :global(.react-resizable-handle)::after {
        width: 7px !important;
        height: 7px !important;
        border-right: 2px solid var(--gray700) !important;
        border-bottom: 2px solid var(--gray700) !important;
    }
}

.pivotChartWidget {
    padding: 10px;
}
