.testChannelsContainerOverride {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    width: 524px;
    padding: 0 14px;

    & .testChannelsHeaderOverride {
        border-bottom: none !important;
        height: 56px;
    }

    & .testChannelsContentContainerOverride {
        padding-top: 0;
    }

    & .testChannelsArrowAreaOverride {
        margin-left: 0;
        margin-right: 12px;
    }

    & .externalHeaderAreaOverride {
        margin-right: 0;
    }
}