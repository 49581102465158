@import "../../../global/theme.css";

.welcomePage {
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    height: var(--onboardingPlanningHeight);
}

.welcomeTextContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.welcomeText {
    font-size: 24px;
    font-weight: 600;
}

.text1 {
    margin: 27px 0 15px 0;
}

.welcomeSvg {
    margin: 40px 0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}

.underline {
    left: -7px;
    position: relative;
    color: var(--blue300);
}

.letsStartButton {
    padding: 0 50px !important;
}
