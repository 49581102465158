@import "../../../global/theme.css";

.parameterField {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    font-weight: 400;
    margin-top: 0;
}

.checkboxLabelClass {
    @apply --font-input-label;
    margin-right: 6px;
}