@import "../../../global/theme.css";
@import "../../../global/general.css";

.subHeaderText {
    min-width: 215px;
    max-width: 235px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: var(--gray700);
    user-select: none;

    & .helpTextContainer {
        display: flex;

        &.clickable {
            cursor: pointer;
        }
    }

    & .subHeaderHelpText {
        margin-left: 8px;
    }

    & .toggleMenuIconContainer {
        height: 18px;
    }

    & .helpIconContainer {
        height: 18px;
    }

    & .helpIcon {
        height: 19px;
        fill: var(--gray700);
    }

    & .toggleMenuIcon {
      cursor: pointer;
      width: 13px;
      margin-top: 4px;
      fill: var(--blue300);
        &.top {
            transform: rotate(-180deg);
        }
    }

    & .faqLinkText {
        font-size: 14px;
        font-weight: 600;
        margin-left: 5px;

        &:hover {
            text-decoration: none;
        }
    }
}

.helpPopover {
    z-index: 103;
}

.helpPopoverContentWrapper {
    display: flex;
    flex-direction: column;
    width: 225px;
    background-color: var(--white);
    border: 1px solid var(--gray400);
    border-radius: 4px;
    margin-top: 7px;

    & .helpPopoverRow {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 20px;
        align-items: center;

        &:hover {
            text-decoration: none;
        }

        & .helpPopoverLink {
            color: var(--gray700);
            font-size: 14px;
        }

        &:hover {
            background-color: var(--blue100);

            & svg path {
                fill: var(--blue300);
            }
        }
    }
}
