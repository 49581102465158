.stepsContainer {
    padding-top: 15px;
}

.dropdown {
    width: 250px;
}

.dropdownBody {
    width: 250px;
}

.dropdownLabel {
    padding-right: 5px;
}