@import "../../global/theme.css";

.container {
  padding: 0px 30px 84px 30px;
  line-height: 1.6;
  margin: 25px 0 6px;
  font-size: 14px;
  max-width: 100%;
  color: var(--gray600);

  & * {
    outline-width: 2px;
  }
}

.headerText {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 6px;
  color: var(--black);
}

.buttonAndText {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: var(--black);
}

.copyButton {
  margin-right: -15px;
  font-size: 12px;
  font-weight: 400;
}

.numOfDaysContainer {
  display: flex;
  align-items: center;
  margin: 20px 0 20px;
  &::before {
    content:"*\00a0";
    white-space: nowrap;
    color: var(--red300);
}
}

.numOfDaysInput {
  width: auto;
  text-align: left;
  margin: 0 7px;
  display: block;

}

.textInputLine {
  font-weight: 600;
  color: var(--black);
  width: 60%;
}

.generateLinkButton {
  margin-top: 27px;
}

.dateRangeSection {
  display: flex;
  margin-top: 20px;
}

.CalendarIcon {
  margin: 3px 7px 15px;
  fill: var(--blue300);
}

.dateRangeString {
  font-weight: 600;
  color: var(--black);
}

.dateChangeRadioButtons {
  width: 400px;
  margin-left: 106px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray400);
  margin: 30px 0;
}

.emptyState {
  padding-top: 120px;
}

.link {
  width: 100%;
  resize: none;
  height: 100px;
  margin-top: -8px;
  padding: 10px 10px 10px 10px;
  border-radius: 3px;
  border: 1px solid var(--gray400);
}

.sharedReportLine {
  height: 40px;
  padding-top: 13px;
  justify-content: space-between;
  display: flex;
}

.sharedReportLineDescription {
  font-size: 14px;
}

.sharedReportLineBorder {
  border-bottom: thin dashed rgba(0, 0, 0, .2);
}

.sharedReportLineButtons {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  width: 100px;
  cursor: pointer;
  & .button {
    fill: var(--gray600);
    & * {
      fill: var(--gray600) !important;
    }
    &:hover {
      fill: var(--gray700) !important;
      & * {
        fill: var(--gray700) !important;
      }
    }
    &:active {
      fill: var(--gray800) !important;
      & * {
        fill: var(--gray800) !important;
      }
    }
  }
}
