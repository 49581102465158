@import "../../global/theme.css";

.itemContainer {
    overflow: visible;
    background-color: var(--gray100);
    height: 78px;
    padding: 0px 21px;
    margin-bottom: 20px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        background-color: var(--blue100);
        & .alertButtons {
            opacity: 1;
            align-items: center;
            right: 65px;

        }
        & .itemDetails {
            white-space: nowrap;
            background: linear-gradient(to left, var(--blue100) 100px, var(--gray600));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        & .itemErrorDetailsOnly {
            white-space: nowrap;
            background: linear-gradient(to left, var(--gray600));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        & .EditIconOnly {
            margin-right: -32px;
        }
        & .noAlertDetails {
            margin-top: -11px;
        }
    }
}

.itemLeft {
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 32px;
    width: 32px;
    border-radius: 3px;
    margin-right: 20px;
    margin-top: 3px;
}

.itemHeaders {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-top: -5px;
}

.itemHeader{
    justify-content: left;
    margin-bottom: 2px;
}

.itemHeaderName{
    font-size: 16px;
    font-weight: 400;
}

.itemHeaderPipe{
    display: inline;
    font-size: 16px;
    color: var(--gray500);
}

.itemHeaderSubscribed{
    display: inline;
    font-weight: 300;
    color: var(--gray800);
}

.itemHeaderSubscribedIcon {
    position: relative;
    top: 2px;
    left: 4px;
}

.itemSubHeader{
    font-size: 12px;
    font-weight: 300;
    color: var(--gray600);
}

.itemRight {
    display: flex;
    justify-content: space-between;
    padding-top: 2px;
}

.itemDetails {
    padding-top: 4px;
    padding-right: 40px;
    font-size: 12px;
}

.itemErrorDetails {
    padding-top: 4px;
    font-size: 12px;
    margin-right: 3px;
}

.alertButtons {
    display: flex;
    justify-content: space-around;
    width: 100px;
    flex-flow: row-reverse;
    border-radius: 3px;
    opacity: 0;
    position: absolute;

    & .button {
        fill: var(--gray600);
        & * {
            fill: var(--gray600) !important;
        }
        &:hover {
            fill: var(--gray700) !important;
            & * {
                fill: var(--gray700) !important;
            }
        }
        &:active {
            fill: var(--gray800) !important;
            & * {
                fill: var(--gray800) !important;
            }
        }
    }
}

.highlighted {
    padding: 0;
    background-color: var(--highlightColor);
}