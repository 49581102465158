@import "../../global/theme.css";

.boxBody {
  display: flex;
  align-items: center;
  position: relative;
  background: white;

  font-size: 1em !important;
  padding: 16px 20px;
  height: 75px;

  &.pending {
    & .linesContainer, & .ribbon {
      opacity: .5;
    }
  }
  &.inactive {
    opacity: .5;
  }
}

.ribbon {
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.img {
  margin-right: 10px;
  height: 100%;
}

.leadingLine {
  display: flex;
  align-items: center;
}

.usernameText {
  font-size: 18px;
  font-weight: 500;
  margin-right: 9px;
}

Badge {
  margin-right: 9px;
}

.description {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: var(--gray700)
}

.secondaryLine {
  font-size: 14px;
  font-weight: 300;
  color: var(--gray700);
}

.highlighted {
  font-weight: bold;
  padding: 0;
  background-color: var(--highlightColor);
}

.buttonContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.seperator {
  margin-right: 10px;
  width: 1px;
  height: 20px;
  background-color: transparent;
}

.icon {
  margin-left: 20px;
  height: 14px;
  cursor: pointer;
  fill: var(--gray600) !important;

  &:hover {
    fill: #555555 !important;
  }
}

.invitationLink {
  transition: 0.1s all linear;
  opacity: 0;
  pointer-events: none;
  @nest .boxBody:hover & {
    opacity: 1;
    pointer-events: auto;
  }
}
