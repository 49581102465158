.container {
    text-align: center;

    & p {
        font-size: 14px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    & img {
        width: 306px;
        height: 257px;
        display: block;
        margin: 0 auto;
    }
}