.container {
  gap: 8px;
  width: 680px;
  overflow: visible;

  .containerClass {
    margin-bottom: 8px;
    textarea {
      resize: none;
      width: 100%;
      height: 60px;
    }
  }
  .label {
    font-size: 14px;
    color: var(--gray700) !important;
    margin-bottom: 8px;
  }
  .formItem {
    margin-bottom: 15px;

    .icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
    }

    .dropdownRow {
        padding: 2px 8px;
    }

    .wrapper {
      width: 100%;
    }
  }

  .dateContainer {
    z-index: var(--stickyZIndex);

    .dateTitleContainer {
      flex-direction: row;
      display: flex;
      gap: 11px;
      margin-bottom: 8px;
      align-items: center;
    }

    .checkboxContainer {
      margin-top: 8px;

      .checkmarkLabel {
        padding-right: 11px;
        font-size: 14px;
        color: var(--gray700) !important;
        font-weight: 400;
      }
    }
  }

  .dropdownContainer {
    width: 100%;
    margin: 3px 0;
  }
}

.subTitle {
  margin-bottom: 16px;
}