.revenuePostbacksOptions {
    width: 500px;
}

.dropdownWrapper:not(:last-child) {
      margin-bottom: 20px;
}

.topText {
      margin-top: 10px;
      margin-bottom: 15px;
}