.container{
    max-width: 100%;
    height: calc(100% - 255px);
    margin: 20px;
}

.content {
    width: 100%;
}

.list {
    width: 257px;
}