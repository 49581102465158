@import "../../global/theme.css";

.loadingBar {
  background-color: var(--primaryColor);
  height: 3px;
  position: absolute;
}

.labelCell {
  padding-right: 10px;
}

.modalContainer {
  padding: 0 30px;
}

.modalInputCell {
  width: 100%;
  padding-right: 10px;
}

.modalInput {
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--gray400);
  padding: 6px 12px;
  outline-width: 2px;
}

.rowSpacer{
  height: 20px;
}

.submitButton{
  margin-left: 237px;
}