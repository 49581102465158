@import "../../global/theme.css";


.layout {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .exampleSdkKeyContainer {
        height: 120px;
        border: var(--gray400) 1px solid;
        width: 100%;
        max-width: 750px;

        & .exampleSdkKeyTitle {
            background: var(--gray300);
            height: 36px;
            border-bottom: var(--gray400) 1px solid;

            & .titleText {
                margin: 8px;
                font-weight: 600;
              }

        }

        & .exampleSdkKeyContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            height: 80px;
            line-height: 25px;
            overflow: auto;

            & .exampleSdkKeyText {
                margin-right: 30px;
            }

          }

      }

    & .docIconsContainer {
        line-height: 50px;
      }

}