@import "../../../global/theme.css";

.settingsContainer {
    display: flex;


    & .fieldOptionsContainer {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-right: 20px;

        & .search {
            margin-left: 45px;
        }

        & .sectionsContainer {
            flex-grow: 1;
            border-right: solid 1px #ebebeb;
            padding-right: 30px;
            margin-top: 20px;

            & .checkboxLabel {
                font-size: 14px;
                font-weight: 500;
                color: var(--gray700);
            }
        }
    }

    & .selectedFieldsContainer {
        width: 60%;
        margin-top: 45px;
        margin-left: 8px;
    }
}
