@import "../../global/theme.css";

.container {
  padding: 0 30px 84px 30px;
}

.namesContainer {
  display: flex;
}

.nameItem {
  flex: 1;
}

.nameItem {
  margin-right: 10px;
}

.nameItem:last-child {
  margin: 0;
}

.formElementContainer {
  position: relative;
  &.invalid {
    & .formElementInput {
      border-color: var(--error1Color);
    }
  }
  & .formElementErrorMessage {
    color: var(--error1Color);
    font-weight: 200;
    position: absolute;
    right: 0;
    margin-top: 2px;
    font-size: 12px;
  }
}

.formElementLabel {
  font-weight: 300;
  font-size: 13px;
  color: var(--gray700);
  margin-bottom: 7px;
}
.formElementInput {
  width: 100%;
  height: 36px;
  font-size: 16px;
  font-weight: 300;
  padding: 0 10px;
  outline: none;
  border: 1px solid var(--gray400);
  border-radius: 2px;
}

.formElementInput::placeholder {
  font-weight: 300;
  color: var(--gray400);
  font-size: 16px;
}

.formRow {
  margin-top: 20px;
}

.formRow:first-child {
  margin-top: 30px;
}
