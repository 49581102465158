@import "../global/theme.css";

.emptyState {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;

    & > * {
        margin-bottom: 20px;
    }

    & .text {
        font-size: 16px;
    }

    & .emptyStateBoxesContainer {
        display: flex;
        align-items: center;
    }
}
