@import "../../global/theme.css";

.valueLabelInput {
    width: 100%;
    border: 1px solid var(--gray400);
    padding-left: 20px;
    height: 35px;
}

.formatStaticText {
    position: relative;
    top: -25px;
    left: 6px;
    width: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}