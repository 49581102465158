@import "../../global/theme.css";

.field {
    position: relative;

    & .fieldText {
        line-height: 26px;
        font-weight: 400;
        color: var(--gray600);
        font-size: 13px;
        display: flex;
        align-items: center;
        & > * {
            margin-left: 6px;
        }
        & > *:first-child {
            margin-left: 0;
        }
        & .mandatory {
              color: var(--red300);
              font-size: 14px;
              font-weight: 600;
              display: flex;
              align-items: center;
          }
        & .overflow {
              overflow: hidden;
              text-overflow: ellipsis;
          }


    }
    &.error {
        & .fieldText {
            color: var(--red300);
        }
    }
}

.fieldLabel {
    margin-bottom: 0;
    font-weight: inherit;
    display: block;
    &.error {
        color: var(--red300);
    }
}

.dropDownContainer {
    width: 100%;
}

.errorMessage {
    position: absolute;
    right: 0;
    font-size: 11px;
    font-weight: 400;
    color: var(--red300);
    line-height: 26px;
}
