@import "../../../global/theme.css";

 .codeLanguageButton {
    color: var(--black);
    border: none;
    height: 31px;
    width: 82px;
    padding: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: 1px;
    cursor: pointer;
}

.codeLanguageButtonSelectedDark {
    background-color: var(--blackBgReactCodeBLockTab);
    color: var(--white);
}

.codeLanguageButtonSelectedLight {
    background-color: var(--white);
    border-top: 1px solid var(--gray200);
    border-bottom: 1px solid var(--white);
}