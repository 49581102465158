@import '../../global/theme.css';

:root {
    --barColor: var(--blue200);
}

.loader.expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* When chart is loading, spinner has more z index than datepicker, so it takes it's clicks */
    z-index: 998;
    pointer-events: none;
}

.loader {
    position: relative;
    width: 75px;
    height: 100px;
    margin: auto;
    display: none;
    opacity: 0;
    visibility: hidden;
}

.loader.visible {
    display: block;
    opacity: 1;
    visibility: visible;
}

.loader__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: var(--barColor);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

.loader__bar:nth-child(1) {
    left: 0px;
    -webkit-transform: scale(1, 0.2);
    transform: scale(1, 0.2);
    -webkit-animation: barUp1 4s infinite;
    animation: barUp1 4s infinite;
}

.loader__bar:nth-child(2) {
    left: 15px;
    -webkit-transform: scale(1, 0.4);
    transform: scale(1, 0.4);
    -webkit-animation: barUp2 4s infinite;
    animation: barUp2 4s infinite;
}

.loader__bar:nth-child(3) {
    left: 30px;
    -webkit-transform: scale(1, 0.6);
    transform: scale(1, 0.6);
    -webkit-animation: barUp3 4s infinite;
    animation: barUp3 4s infinite;
}

.loader__bar:nth-child(4) {
    left: 45px;
    -webkit-transform: scale(1, 0.8);
    transform: scale(1, 0.8);
    -webkit-animation: barUp4 4s infinite;
    animation: barUp4 4s infinite;
}

.loader__bar:nth-child(5) {
    left: 60px;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-animation: barUp5 4s infinite;
    animation: barUp5 4s infinite;
}

.loader__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: var(--blue300);
    border-radius: 50%;
    -webkit-animation: ball 4s infinite;
    animation: ball 4s infinite;
}

@-webkit-keyframes ball {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    5% {
        -webkit-transform: translate(8px, -14px);
        transform: translate(8px, -14px);
    }
    10% {
        -webkit-transform: translate(15px, -10px);
        transform: translate(15px, -10px);
    }
    17% {
        -webkit-transform: translate(23px, -24px);
        transform: translate(23px, -24px);
    }
    20% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
    27% {
        -webkit-transform: translate(38px, -34px);
        transform: translate(38px, -34px);
    }
    30% {
        -webkit-transform: translate(45px, -30px);
        transform: translate(45px, -30px);
    }
    37% {
        -webkit-transform: translate(53px, -44px);
        transform: translate(53px, -44px);
    }
    40% {
        -webkit-transform: translate(60px, -40px);
        transform: translate(60px, -40px);
    }
    50% {
        -webkit-transform: translate(60px, 0);
        transform: translate(60px, 0);
    }
    57% {
        -webkit-transform: translate(53px, -14px);
        transform: translate(53px, -14px);
    }
    60% {
        -webkit-transform: translate(45px, -10px);
        transform: translate(45px, -10px);
    }
    67% {
        -webkit-transform: translate(37px, -24px);
        transform: translate(37px, -24px);
    }
    70% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
    77% {
        -webkit-transform: translate(22px, -34px);
        transform: translate(22px, -34px);
    }
    80% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px);
    }
    87% {
        -webkit-transform: translate(7px, -44px);
        transform: translate(7px, -44px);
    }
    90% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes ball {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    5% {
        -webkit-transform: translate(8px, -14px);
        transform: translate(8px, -14px);
    }
    10% {
        -webkit-transform: translate(15px, -10px);
        transform: translate(15px, -10px);
    }
    17% {
        -webkit-transform: translate(23px, -24px);
        transform: translate(23px, -24px);
    }
    20% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
    27% {
        -webkit-transform: translate(38px, -34px);
        transform: translate(38px, -34px);
    }
    30% {
        -webkit-transform: translate(45px, -30px);
        transform: translate(45px, -30px);
    }
    37% {
        -webkit-transform: translate(53px, -44px);
        transform: translate(53px, -44px);
    }
    40% {
        -webkit-transform: translate(60px, -40px);
        transform: translate(60px, -40px);
    }
    50% {
        -webkit-transform: translate(60px, 0);
        transform: translate(60px, 0);
    }
    57% {
        -webkit-transform: translate(53px, -14px);
        transform: translate(53px, -14px);
    }
    60% {
        -webkit-transform: translate(45px, -10px);
        transform: translate(45px, -10px);
    }
    67% {
        -webkit-transform: translate(37px, -24px);
        transform: translate(37px, -24px);
    }
    70% {
        -webkit-transform: translate(30px, -20px);
        transform: translate(30px, -20px);
    }
    77% {
        -webkit-transform: translate(22px, -34px);
        transform: translate(22px, -34px);
    }
    80% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px);
    }
    87% {
        -webkit-transform: translate(7px, -44px);
        transform: translate(7px, -44px);
    }
    90% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    40% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    90% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
}

@keyframes barUp1 {
    0% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    40% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    50% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    90% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
}

@-webkit-keyframes barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    40% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    50% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    90% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    100% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
}

@keyframes barUp2 {
    0% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    40% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    50% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    90% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    100% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
}

@-webkit-keyframes barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
    100% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
}

@keyframes barUp3 {
    0% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
    100% {
        -webkit-transform: scale(1, 0.6);
        transform: scale(1, 0.6);
    }
}

@-webkit-keyframes barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    40% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    50% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    90% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    100% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
}

@keyframes barUp4 {
    0% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    40% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
    50% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    90% {
        -webkit-transform: scale(1, 0.4);
        transform: scale(1, 0.4);
    }
    100% {
        -webkit-transform: scale(1, 0.8);
        transform: scale(1, 0.8);
    }
}

@-webkit-keyframes barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    40% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    90% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes barUp5 {
    0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    40% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    90% {
        -webkit-transform: scale(1, 0.2);
        transform: scale(1, 0.2);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

