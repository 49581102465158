@import "../../global/theme.css";

.container {
  display: flex;
  align-items: center;
  min-width: 0;

  & .bannerPriority {
    min-width: 60px;
    padding: 5px;
    margin: 0 30px;
    background-color: white;
    border: 1px solid var(--gray400);
    text-align: center;
    cursor: pointer;
  }

  & .bannerName {
    margin-right: 50px;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
  }

  & .rightArea {
    margin-left: auto;
    display: flex;
    align-items: center;
    pointer-events: none; /* so clicking on text still triggers the expander */

    & .actionMenuContainer {
      pointer-events: all;

      & .actionMenuButton {
        width: 36px;
        display: block;

        & ul {
          /* so menu isn't cut by overflow hidden */
          margin-right: 9px;
        }

        &:hover g {
          fill: var(--gray700);
        }
      }
    }
  }
}

.priorityPopover {
  width: 240px;
  margin-top: 5px;
  margin-left: 17px;
  padding: 15px;
  user-select: none;

  & .priorityTitle {
    color: var(--gray700);
  }

  & .priorityFieldContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70px;
    padding: 0 5px;
    margin: 5px auto;

    & .priorityField {
      text-align: center;
      border-color: var(--primaryColor);
      margin-left: -8px;
      cursor: pointer;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    & .chevron {
      width: 20px;
      padding: 3px;
      cursor: pointer;
      fill: var(--blue300);

      &.top {
        transform: rotate(-180deg);
      }

      &.disabled {
        pointer-events: none;
        cursor: default !important;
        filter: invert(100%) sepia(5%) saturate(15%) hue-rotate(322deg) brightness(103%) contrast(102%);
      }
    }
  }

  & .priorityButtons {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}


