@import "../global/theme.css";
.page {
    max-width: 800px;
    & .search {
        display: flex;
        margin-top: 10px;

        & .box {
          box-sizing: border-box;
          width: 600px;
        }
    }

    & .textField {
        width: 200px;
    }

     & .radioBtn {
       width: 180px;
     }

    & .redirect {
        font-size: 15px;
        margin-block: 10px;
        font-weight: 400;
    }

    & .linkBox {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        padding-top: 6px;
        height: 100px;

        & .link {
              resize: none;
              flex-grow: 1;
              border-radius: 3px;
              padding: 4px 8px;
              color: var(--gray700);
          }
    }

    & .createLinkBtn {
        display: block;
        margin: 20px 0;
    }

    & .sectionTitle {
        margin-top: 20px;
    }
}
