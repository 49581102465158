@import "../../../../global/theme.css";

.eventsContainer {
    margin-bottom: 20px;
}

.eventFieldContainer {
    display: flex;
    align-items: flex-end;
    cursor: default;
    width: 100%;
    margin-top: 15px;

    & > *:first-child {
        flex: 1;
    }

    & .modelSlotsClass {
        background-color: #92c8f6;
    }
}

.eventIconsContainer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    & .xIcon {
        cursor: pointer;
        margin-left: 10px;
        height: 11px;
        width: 11px;
        & path {
            fill: var(--gray600);
        }
        &:hover:not(.disabled) {
            & path {
                color: var(--red300);
                fill: var(--red300);
            }
        }
        &.disabled {
            opacity: 0.3;
            cursor: default;
        }
    }

    & .plusIconContainer {
        margin-left: 10px;
        display: flex;
        cursor: pointer;
        color: var(--gray600);
        fill: var(--gray600);

        &:hover:not(.disabled) {
            color: var(--blue400);
            fill: var(--blue400);
        }

        &:not(.disabled) {
            color: var(--blue300);
            fill: var(--blue300);
        }

        &.disabled {
            opacity: 0.3;
            cursor: default;
        }

        & .plusIcon {
            height: 15px;
            width: 15px;
        }
    }
}
