@import '../../global/theme.css';

.option {
    gap: 8px;
    display: flex;
    align-items: center;
    padding: 0 6px !important;

    &:hover {
        cursor: pointer;
        background-color: var(--blue100) !important;
    };

    &[aria-selected='true'] {
        background-color: var(--blue50) !important;
    }

    &[aria-selected='true']:hover {
        background-color: var(--blue150) !important;
    }
}

.option, .noOptions, .loadingOptions, .inputLabel {
    font-size: 14px !important;
}

.icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.inputLabel {
    max-width: 165px !important;
    line-height: 20px !important;
    margin: auto;
}

.chip {
    font-size: 12px;
    max-width: 100px;
}

.endAdornment {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
    height: 100%;
    display: flex;
    align-items: center;

    & .indicatorSeparator {
        align-self: stretch;
        background-color: hsl(0, 0%, 80%);
        margin: 8px 0;
        width: 1px;
    }
}

.action {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.disabled {
        cursor: default;
        pointer-events: none;
    }
}

.open {
    composes: action;
    margin: 0 2px;

    & .icon {
        width: 13px;
        fill: var(--blue300);

        &.rotated {
            transform: rotate(180deg);
        }

        &.disabled {
            fill: var(--gray500) !important;
        }
    }
}

.remove {
    composes: action;
    border-left: 1px solid var(--gray300);

    &.disabled {
        pointer-events: none;
        cursor: default;
    }

    & .icon {
        height: auto;
        width: var(--icon-size-small);
        fill: var(--white);
        background-color: var(--gray400);
        border-radius: 50%;

        &:hover {
            background-color: var(--gray500);
        }
    }
}
