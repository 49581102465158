@import "../../global/theme.css";

.PageHeader {
  font-size: 28px;
  margin-top: 35px;
  margin-bottom: 30px;
  font-weight: 300;
  color: var(--gray800);
  line-height: 33px;
}

.AppWrapper {
  margin-bottom: 10px;
}

.AppHeading {
  width: 100%;
  height: 60px;
  background-color: var(--white);
  cursor: pointer;
  margin-bottom: 2px;
}
.AppHeading .AppIcon {
  height: 60px;
  width: 60px;
  display: inline-block;
  text-align: center;
}
.AppHeading .AppCollapseIcon {
  width: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.AppHeading .AppCollapseIcon .ExpandCollapseIcon {
  width: 12px;
  height: 12px;
}
.AppHeading .AppHeadingText {
  display: inline-block;
  vertical-align: middle;
}
.AppHeading .AppHeadingText .AppName {
  color: var(--black);
  line-height: 22px;
  height: 22px;
  font-size: 18px;
  font-weight: 500;
}
.AppHeading .AppHeadingText .AppDetails {
  color: var(--gray700);
  line-height: 17px;
  height: 17px;
  font-size: 14px;
}

.AppBody {
  width: 100%;
  background-color: var(--white);
}
.AppBody .NetworkPanel {
  /*margin-left: 15px;*/
  padding-top: 10px;
}
.AppBody .NetworkPanel:first-child {
  padding-top: 25px;
}
.AppBody .NetworkPanel:last-child {
  padding-bottom: 25px;
}
.AppBody .NetworkPanel .NetworkHeading {
  width: 100%;
  overflow: hidden;
}
.AppBody .NetworkPanel .NetworkName {
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
  /*margin-left: 10px;
  margin-right: 10px;*/
  vertical-align: middle;
}
.AppBody .NetworkPanel .NetworkName .ExpandCollapseIcon {
  height: 12px;
  width: 12px;
  margin-left: 20px;
}
.AppBody .NetworkPanel .NetworkName.Expand {
  margin-left: 60px;
  margin-top: 3px;
  width: 40px;
  text-align: center;
  float: left;
  cursor: pointer;
}
.AppBody .NetworkPanel .NetworkName.TextWrapper {
  overflow: hidden;
  cursor: pointer;
}
.AppBody .NetworkPanel .NetworkName.TextWrapper:hover > .deleteButton {
  display: inline-block;
}
.AppBody .NetworkPanel .NetworkName.TextWrapper .deleteButton {
  display: none;
}
.AppBody .NetworkPanel .NetworkPanelText {
  color: var(--gray700);
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel {
  margin: 10px 0px 0px 0px;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignHeading {
  width: 100%;
  overflow: hidden;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName {
  color: var(--gray800);;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName .ExpandCollapseIcon {
  height: 12px;
  width: 12px;
  margin-left: 40px;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName.Expand {
  margin-left: 85px;
  margin-top: 3px;
  float: left;
  /*margin-right: 10px;
	display: inline-block;*/
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName .deleteButton {
  display: none;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName.TextWrapper {
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName.TextWrapper:hover > .deleteButton {
  display: inline-block;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName.TextWrapper:hover > .CampaignNameLabel {
  font-weight: 500;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .CampaignName .CampaignNameLabel {
  font-weight: 300;
  font-size: 15px;
}
.CampaignNameSingularLinkLabel {
  margin-left: 4px;
  font-size: 12px;
  color: var(--gray500);
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .DestinationPanel {
  margin: 10px 46px 0 125px;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .DestinationPanel .DestinationHeading {
  background-color: var(--blue150);
  color: #343434;
  padding: 5px 5px 5px 8px;
  font-size: 14px;
  font-weight: 500;
}
.AppBody .NetworkPanel .CampaignsContainer .CampaignPanel .DestinationPanel .DestinationTable {
  background-color: var(--gray100);
  padding: 10px 10px 0px 15px;
}

.TextBox {
  width: 100%;
  background-color: #F0F0F0;
  color: #989898;
  border: 1px solid var(--gray400);
  border-radius: 2px;
  height: 36px;
  font-size: 16px;
  line-height: 19px;
  padding-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.TextBox.TrackingLink {
  cursor: pointer;
}
.TextBox.TrackingLink:active {
  background-color: #ACD1F9;
}

.FieldContainer {
  background-color: var(--gray100);
  width: 100%;
  padding-top: 13px;
}
.FieldContainer:first-child {
  padding-top: 8px;
}
.FieldContainer.Link:last-child {
  padding-top: 15px;
}
.FieldContainer:last-child {
  padding-bottom: 23px;
}
.FieldContainer .FieldLabel {
  float: left;
  padding-top: 8px;
  padding-left: 10px;
  width: 220px;
}
.FieldContainer .FieldValue {
  display: flex;
  text-align: left;
  vertical-align: middle;
  margin-left: 5px;
}
.ToggleChecked:global(.react-toggle--checked) :global(.react-toggle-track) {
  background-color: #00A773;
}
.ToggleUnchecked:global(.react-toggle) :global(.react-toggle-track) {
  background-color: #515864;
}
.FieldContainer .FieldValue.TrackingLinkContainer {
  cursor: pointer;
}
.FieldContainer .FieldValue.TrackingLinkContainer:hover + .ClickToCopy {
  display: inline-block;
}
.FieldValue.ClickToCopy {
  color: #00A8FF;
  cursor: pointer;
  margin-left: 10px;
  width: 55px;
  padding-top: 8px;

}
.FieldValue.ClickToCopy:hover {
  display: inline-block;
  text-decoration: underline;
}

.DeferredToggleContainer {
  height: 30px;
  line-height: 30px;
  padding-top: 5px;
}
.DeferredToggleContainer .DeferredToggleWrapper {
  line-height: normal;
  display: flex;
  vertical-align: middle;
}
.DeferredToggleContainer .DeferredTextWrapper {
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #989898;
  font-weight: 300;
}

.AvailableLinks {
  color: var(--gray800);;
  font-weight: 300;
  display: inline-block;
  margin-right: 15px;
}

.CollapseAll {
  display: inline-block;
  cursor: pointer;
  color: #00A8FF;
  font-weight: 300;
}

.ExportCsv {
  margin-left: auto;
  margin-bottom: 15px;
	padding: 5px 16px;
	border-radius: 3px;
  background-color: var(--primaryColor);
  color: var(--white);
  border: 1px solid var(--primaryColor);
}

.LoadingSpinner {
  height: 200px;
  width: 100%;
  font-size: 30px;
  text-align: center;
  color: #00A8FF;
}

.FiltersWrapper {
	margin-top: 10px;
}

.FiltersWrapper .FilterSelector {
  display: inline-block;
  margin-right: 17px;
}
.FiltersWrapper .FilterSelector input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid var(--gray500);
  background-color: white;
  transition: 0.2s all linear;
  outline: none;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.FiltersWrapper .FilterSelector input[type=radio]:checked {
  background-color: var(--gray700);
  border: 2px solid white;
}

.FiltersList {
  margin-bottom: 10px;
}

.FilterBlock {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.FilterItem {
  margin-right: 5px;
}
.FilterItem .Select *:hover {
  cursor: pointer;
}
.FilterItem .Select.fill {
  flex: 1;
}
.FilterItem .Select.invalid .Select-control {
  border: 1px solid #FF542B;
}
.FilterItem .Select .Select-clear-zone,
.FilterItem .Select .Select-arrow-zone {
  vertical-align: top;
  top: 3px;
}
.FilterItem .Select .Select-menu-outer {
  border-radius: 4px;
  border-top-color: var(--gray400);
  margin-top: 4px;
}
.FilterItem .Select .Select-menu-outer .is-selected, .FilterItem .Select .Select-menu-outer .is-focused {
  background-color: #E3F5FE;
}

.FilterTarget {
  display: inline-block;
  margin-right: 10px;
}

.FilterType {
  display: inline-block;
  margin-right: 10px;
}

.FilterTags {
  display: inline-block;
  width: 70%;
  max-width: 1000px;
}
.FilterTags .Select--multi .Select-value {
  border: 1px solid #dae3e8;
  font: inherit;
  font-size: 14px;
  line-height: 20px;
  background: -webkit-linear-gradient(top, var(--gray100) 0%, var(--gray200) 47%, #dedede 100%);
  background: linear-gradient(to bottom, var(--gray100) 0%, var(--gray200) 47%, #dedede 100%);
  color: inherit;
}
.FilterTags .Select--multi .Select-value .Select-value-icon {
  float: right;
  border: 0;
}
.FilterTags .Select--multi .Select-value .Select-value-icon:hover {
  color: initial;
  background-color: initial;
}

/* Override component's default styles */
@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 100%;
  }
}
.RemoveFilter {
  cursor: pointer;
  width: 12px;
  margin-right: 10px;
  margin-left: 5px;
  flex-shrink: 0;
}

.AddFilter {
  width: 200px;
  color: #00A8FF;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
	display: inline-block;
	margin-top: 10px;
}
.AddFilter:hover {
  text-decoration: underline;
}
.AddFilter .AddFilterIcon {
  height: 24px;
  width: 24px;
  margin: 0px 4px 4px 0px;
  display: inline-block;
  vertical-align: middle;
}

.AddFilter .AddFilterText {
  display: inline-block;
}

.Divider {
  border-top: 1px solid white;
  width: 100%;
}

.SearchTextBox {
  padding: 15px;
  color: var(--gray700);
  border: 1px solid var(--gray400);
  border-radius: 2px;
  height: 100px;
  width: 100%;
  max-width: 1500px;
  resize: none;
  margin-bottom: 10px;
}

.SearchButton {
  display: block;
  background-color: #8A8A8A;
  border: 1px solid #767676;
  border-radius: 3px;
  color: white;
  margin-top: 5px;
  width: 76px;
  height: 36px;
}
.SearchButton:hover {
  color: var(--white);
  background-color: #8a8a8a;
  border-color: #767676;
}
.SearchButton:disabled {
  opacity: 0.5;
  color: #A7A7A7;
  background-color: #CDCDCD;
  border-color: #CDCDCD;
}

.RadioButtonLabel {
  color: var(--gray800);;
  font-weight: 300;
  margin-right: 20px;
  cursor: pointer;
}

.HeaderControls {
  display: flex;
}


