@import "../../global/theme.css";
@import "../../global/general.css";

label.header {
    @apply --font-size-small;
    background-color: var(--gray200);
    color: var(--gray700);
    border-top: 1px solid var(--gray400);
    border-right: 1px solid var(--gray400);
    border-left: 1px solid var(--gray400);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-style: italic;
    padding: 4px 10px;
    width: 100%;
    height: 100%;
    cursor: default;
    margin: 0;
}

.popover {
    z-index: 15;
    margin-top: -10px;
    left: -10px !important;
    overflow-y: visible !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px 4px !important;

    & > div {
        overflow-y: visible !important;
  }
}

.tooltip {
    @apply --font-tooltip;
    background-color: var(--white);
    box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.15) !important;
    left: 150px !important;
    border-radius: 3px;
    padding: 10px;
    z-index: 20000;
    width: 350px;

    &:after {
        content: "";
        position: absolute;
        top: 99%;
        left: 10%;
        margin-left: -10px;
        border-width: 7px;
        border-style: solid;
        border-color: var(--white) transparent transparent transparent;
    }
}