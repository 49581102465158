@import "../../global/theme.css";

.noDataText {
    font-weight: 600;
    margin: -2px 0 0 10px;
}

.noDataContainerInner {
    display: flex;
    flex-direction: row;
}

.noDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fixedButtonContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.fixedButton {
    color: #455154 !important;
    background-color: var(--white) !important;
    border-radius: 2px !important;
    border: solid 1px #455154 !important;
}

.fixedButtonClicked {
    color: var(--gray300) !important;
}

.reportErrorDescription {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    padding: 14px 8px 14px 20px;
    border: solid 1px var(--red300);
    background-color: var(--gray100);
    line-height: 24px;
}

.reportErrorDescriptionDisabled {
    background-color: var(--gray200);
    opacity: 0.3;
}

.instructionsBackground {
    width: 98%;
    height: 100%;
    background-color: var(--gray200);
    display: inline-flex;
    margin: 10px 0;
}

.instructionsInner {
    padding: 0 0 0 5px;
    display: flex;
}

.instructionsText {
    padding: 13px 0 11px 5px;
}

.notificationInfo {
    padding-top: 10px;
    fill: var(--blue300);
}

.separator {
    width: 98%;
    height: 1px;
    margin: 10px 0;
    background-color: var(--gray400);
}
