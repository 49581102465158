@import "../../global/theme.css";

.topArea {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    background-color: white;
    border-bottom: 1px solid var(--gray400);

    & .actionsContainer {
        position: relative;
        display: flex;

        & .export {
            margin: 0 10px;

            & .downloadIcon {
                width: var(--icon-size-small);
                height: var(--icon-size-small);
            }
        }

        & .newPartnerInput {
            position: absolute;
            width: 300px;
            right: 130px;
            bottom: 0;
        }
    }
}
