@keyframes opacity {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.container {
	display: inline;
}

.container span {
	animation: opacity 1s infinite;

	font-size: 26px;
	width: 6px;
	display: inline-block;
}

.container span:nth-child(2) {
	animation-delay: 100ms;
}

.container span:nth-child(3) {
	animation-delay: 300ms;
}