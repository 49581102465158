@import "../../global/theme.css";

.dropdownContainer {
    z-index: 11;
    width: 100%;
}

.content {
    display: flex;
    align-items: center;
}

.contentIcon {
    display: flex;
    align-items: center;
    width: 16px;
    margin-right: 10px;
}

.headerIcon {
    display: flex;
    align-items: center;
    width: 13px;
}

.hidden {
    display: none;
}

.headerText {
    color: var(--blue300);
    padding-left: 8px;
    padding-right: 6px;
    font-weight: 600;
}

.verticalLine {
    height: 18px;
    width: 1px;
    margin-right: 7px;
    background-color: var(--gray400);
}

.subTitle {
    color: var(--gray400);
    padding-left: 6px;
    padding-right: 14px;
}

.newBadge {
    position: relative;
    top: -1px;
    color: var(--pink100);
    font-size: 80%;
    margin-left: 5px;
}

.explanation {
    color: var(--gray500);
    float: right;
    margin: 2px 0px 0px 6px;
    font-size: 11px;
}

.link {
    display: inline-flex;
    width: 100%;
    pointer-events: all;
    color: var(--gray700);
    text-decoration: none;
}

.linkIcon {
    fill: var(--gray500);
}

.itemsContainer {
    list-style: none;
    padding: 0;
    border: 1px solid var(--gray400);
    background-color: var(--white);
    margin: 0;
    max-height: 220px;
    overflow-y: auto;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;

    &.top {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.bottom {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    & li {
        @apply --font-dropdown-item;
        padding: 6px 10px;
        border-bottom: 1px solid;
        border-color: var(--gray200);

        &:hover {
            background-color: var(--hoverColor);
        }

        &.divider {
            background-color: var(--gray600) !important;
            color: var(--white) !important;
            font-style: italic;
            padding: 4px 10px;
            @apply --font-size-small;
            cursor: default;
            user-select: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.selectedContainer {
    padding: 7px 10px;
    border-radius: 2px;
    border: 1px solid;
    border-color: var(--gray400);
    background: var(--white);
    cursor: pointer;
    color: var(--gray700);
    font-size: 16px;
    position: relative;
    width: 100%;
    text-align: left;
    @apply --font-paragraph;

    & .generalItem {
        margin-right: 16px;

        & .default {
            font-style: italic;
            opacity: 0.6;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        & .link {
            pointer-events: none;
        }
    }

    &.show {
        &.error {
            border-color: var(--red300);
        }
    }

    &.error {
        border-color: var(--red300);
    }

    &.emptyList {
        cursor: default;
    }

    & .linkIcon {
        display: none;
    }
}

.hideTopBorder {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.container {
    width: 205px;
    position: relative;

    & * {
        outline: none;
    }

    outline: none;

    &.disabled {
        & .selectedContainer {
            cursor: not-allowed;
            background: var(--primaryDisabledLight);
            border: 1px solid var(--gray300);

            & .generalItem {
                cursor: not-allowed;
                color: var(--gray400);
            }

            & .arrow {
                color: var(--gray400) !important;
            }

            & .headerText {
                color: var(--gray400);
            }

            & .headerIcon {
                stroke: var(--gray400);
            }
        }
    }
}

.arrow {
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 0;
    margin: auto;
    color: var(--primaryColor) !important;
    margin-right: 4px;
}

.generalItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & * {
        pointer-events: none;
    }

    & .link {
        pointer-events: all;
    }
}

.disabledItem {
    color: var(--gray300);
    cursor: default;
}

.tooltip {
    display: none;
}

:global(.Select-multi-value-wrapper) {
    display: flex !important;
    height: 100% !important;
}

.goalsDropdownPopper {
    transform: translate3d(0, 76px, 0px) !important;
    min-width: 560px !important;
}

.errorIcon {
    position: absolute;
    top: 5px;
    right: 25px;
    cursor: pointer;
}
