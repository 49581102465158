.faqTitle {
    font-weight: 700;
    font-size: 14px;
    margin: 10px 0;
}

.faqAccordion {
    margin: 5px 0 20px 0;
}

.contentSection {
    line-height: 1.5;

    & li {
        margin-bottom: 10px;
    }
}