@import '../../../global/theme.css';

.content {
    display: flex;
    height: 100%;
    background-color: var(--gray200);
    justify-content: center;

    & .contentContainer {
        margin: 12px 25px 17px;
        background-color: white;
        height: 80vh;
        width: 100%;
    }
}