@import "../../global/theme.css";

.option {
    color: var(--gray700);
    cursor: pointer;
    font-weight: 400;
    margin-right: 3px;
    margin-left: 1px;
    &:active, &:focus {
        border: none;
        outline: none;
    }
}

.container {
    display: flex;
}

.iconContainer {
    margin-left: 8px;
    color: var(--gray700);
    fill: var(--gray700);
    &:focus,&:active {
        outline: none;
        border: none;
    }
}