@import '../../global/theme.css';

:root {
    --actionButtonSize: 20px;
    --tableBorder: 1px solid var(--gray300);
    --numericRangeWidth: 60px;
    --infinityIconWidth: 22px;
    --infinityIconMargin: calc((var(--numericRangeWidth) - var(--infinityIconWidth)) / 2);
}

.table {
    background-color: var(--white);
    color: var(--gray800);
    width: 100%;
    font-size: 13px;
    font-weight: 400;

    &.showInnerBorder {
         & tr {
             border-top: var(--tableBorder);
         }
         & th, & td {
             border-right: var(--tableBorder);
         }
    }

    & .checkboxCell {
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & tbody tr {
        transition: 200ms linear;

        &:global(.hideRow) {
            opacity: 0;
            pointer-events: none;
        }

        &:global(.hideRowDisplay) {
            display: none;
        }

        &.zebraRows:nth-child(even) {
            background-color: var(--gray100);

            &+:global(.childTableRow) {
                transition: none;
                background-color: var(--gray100);

                & td:first-child {
                    padding-left: 50px !important;
                }
            }

            & :global(.tableCellImgBadge) {
                border-color: var(--gray100);
            }
        }

        &.rowActionsHover {
            & td:nth-last-child(2) {
                border-right: none;
            }
        }

        &.highlightRow {
            background-color: var(--blue100);
        }
    }

    &.tableWithRowHover {
        & tr:hover, & .zebraRows:hover {
            background-color: var(--blue100);

            & :global(.tableCellImgBadge) {
                border-color: var(--blue100);
            }

            &.rowActionsHover {
                & .actions {
                    opacity: 1;
                }
            }
        }
    }

    & .disabledRow {
        background-color: var(--gray200) !important;
    }

    & .tableTr {
        height: 40px;
        font-size: 13px;
        color: var(--gray700);

        & .tableRowContainer {
            display: flex;
            align-items: center;
        }
    }

    & th, & td {
        &:global(.hideTd) {
            opacity: 0;
        }

        padding: 0 10px;
        &.actions {
            opacity: 0;
            position: relative;
            padding: 0;
            transition: 200ms linear;

            & div {
                height: 100%;
                width: 300px;
                position: absolute;
                right: 0;
                top: 0;
                box-shadow: inset -210px 0 20px 0 color(var(--blue100) a(0.95));
                & .action {
                    position: relative;
                    fill: var(--gray600);
                    width: var(--actionButtonSize);
                    height: var(--actionButtonSize);
                    cursor: pointer;
                    float: right;
                    top: calc((100% - var(--actionButtonSize)) / 2);
                    margin-right: 20px;

                    &.disabled {
                        cursor: default;
                        fill: var(--gray500);
                    }

                    & .badge {
                        font-size: 7px;
                        font-weight: 600;
                        color: var(--pink100);
                        position: absolute;
                        top: -5px;
                        left: 23px;
                    }
                }
                &.extendedMenu {
                    transform: rotate(90deg);
                }
            }
        }

        &:last-child {
            border-right: none;
        }
        &.actionsTh:nth-last-child(2) {
            border-right: 0;
        }
    }

    & th {
        font-size: 13px;
        color: var(--gray700);
        background-color: var(--white);
        font-weight: 400;
        cursor: default;
        padding: 6px 10px;
        border-bottom: var(--tableBorder);

        & .tableHeaderContainer {
            display: flex;

            & .sortOff {
                opacity: 0;
            }

            &:hover {
                & .sortOff {
                    opacity: 0.9;
                }
            }

            & .tableHeaderText {
                flex-grow: 1;
            }

            & .tableHeaderArrowContainer {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                min-width: 20px;
                justify-content: flex-end;

                & .arrowIcon {
                    fill: var(--gray700);

                    &:hover {
                        fill: var(--blue300)
                    }
                }
            }

            & .tableHeaderTooltipIcon {
                cursor: pointer;
                height: 12px;
                align-self: center;
                margin-top: 3px;
                margin-left: 5px;
                fill: var(--gray600);
            }

             & .tableHeaderIcon {
                height: 20px;
                margin-right: 10px;

                & path {
                  fill: var(--gray700);
                }
            }
        }

        &.filterCell {
            padding: 10px 15px;

            & input {
                height: 30px;

                &::placeholder {
                    font-size: 14px;
                    font-style: italic;
                }
            }
        }
    }
}

.iconContainer {
    width: 24px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
}

.iconText {
    padding-left: 10px;
    &.boldText {
        font-weight: bold;
    }
    display: flex;
    flex-direction: row;
}

.iconSubText {
    font-weight: normal;
    padding-left: 7px;
}

.booleanIcon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;

    &.green {
        color: var(--green300);
    }

    &.red {
        color: var(--red400);
    }

    &.blue {
        color: var(--blue300);
    }
}

.linkCell {
    color: var(--blue300);
    outline: none;

    &:hover {
        cursor: pointer;
    }

    & * {
        outline: none;
    }
}

.popoverCell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 100%;

    & .etlIconContainer {
        width: 24px;
        margin-right: 7px;
        display: flex;
        justify-content: center;
    }

    & .popoverComponent {
        display: flex;
        align-items: center;
    }

    & .popoverChevron {
        width: 26px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & svg{
            width: 10px;
            fill: var(--gray600);
            transform: rotate(-90deg);
         }

        &:hover {
            background-color: var(--gray600);
            & svg {
                fill: var(--white);
            }
        }
    }

    & .popoverChevronOpen {
        background-color: var(--gray600);

        & svg{
            fill: var(--white);
            transform: rotate(0deg);
         }
    }

}

.dropdownCell {
    & > div {
        position: relative;
        outline: none;
        height: 40px;
        width: 100%;
    }

    & * {
        outline: none;
    }

    & button {
        border-radius: 3px;
        border: none;
        background: inherit;
        color: var(--blue300);
        font-size: 16px;
        cursor: pointer;
        position: relative;
        width: 100%;
        text-align: center;
        height: 40px;

        &:hover {
            color: var(--blue400);
        }

        &.active {
            color: var(--blue500);
        }
    }

    & ul {
        opacity: 0;
        list-style: none;
        padding: 0;
        transition: opacity 0.3s ease-in-out;
        border: 1px solid;
        border-color: var(--gray400);
        width: 250px;
        font-weight: 300;
        position: absolute;
        right: -11px;
        background-color: var(--white);
        z-index: 1000;
        pointer-events: none;
        top: 41px;

        & li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px;
            border-bottom: 1px solid;
            border-color: var(--gray200);
            line-height: 20px;

            &:hover {
                background-color: var(--hoverColor);
            }

            &.disabled {
                color: var(--gray400);
                cursor: default;

                &:hover {
                    background-color: inherit;
                }
            }

            & .menuItemTooltip {
                width: 100%;
                display: inline-block !important; /* Allows tooltip on the entire li */
            }
        }

        &.show {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;
        }
    }
}

.tooltip {
    font-size: 13px !important;
    margin: 0 auto;
    background-color: var(--white) !important;
    color: var(--gray600) !important;
    border: 1px solid var(--gray500);
}

.opaqueBackground {
    opacity: 1 !important;
}

.itemTag {
    color: var(--pink100);
    text-transform: capitalize;
    font-weight: 400;
    font-size: 10px;
    margin-left: 2px;
}

.numericRangeCell {
    text-align: center;
    height: 40px;
    font-weight: 300;

    & .noRange {
        font-weight: 300;
        font-size: 30px;
        text-align: center;
    }

    & .numericRangeValueLabel {
        display: inline-block;
        width: var(--numericRangeWidth);

        &.emptyRange {
            width: 100%;
        }
    }

    & .numericRangeValueLabelWide {
        display: inline-block;
        width: 120px;
    }

    & .infinityIconWrapper {
        margin: 0 var(--infinityIconMargin);

        & svg {
            margin: 0;
        }
    }

    & svg {
        margin-right: 10px;
    }

    &IconWrapper {
        display: inline-block;
        margin-left: 15px;
        margin-right: 5px;
    }

    & .editableNumericRangeInput {
        position: relative;

        &.error {
            & input {
                border: 1px solid red;
            }
        }

        & input {
            width: var(--numericRangeWidth);
            border: 1px solid var(--gray400);
            padding-left: 15px;
            height: auto;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        & .labelFormatting {
            position: absolute;
            left: 4px;
            top: 2px;
            width: 10px
        }
    }
}

.rowIndexCell {
    text-align: center;
    font-weight: 300;
    position: relative;
    user-select: none;
}

.editButtons {
    display: flex;
    position: absolute;
    right: 12px;
    width: 40px;
    justify-content: space-between;

    & .disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }

    & .plusIconContainer {
        width: 24px;
        display: flex;
        cursor: pointer;
        color: var(--gray600);
        fill: var(--gray600);

        &:hover:not(.disabled) {
            color: var(--gray800);
            fill: var(--gray800);
        }

        &:active:not(.disabled) {
            color: var(--blue300);
            fill: var(--blue300);
        }
    }

    & .xIcon {
        cursor: pointer;
        width: 9px;

        & path {
            fill: var(--gray600);
        }

        &:hover:not(.disabled) {
            & path {
                color: var(--red300);
                fill: var(--red300);
            }
        }
    }
}
