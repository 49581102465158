@import '../../global/theme.css';

.landingPageBody {
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
}

.loadingPage {
    width: 100%;
    height: 40%;
    flex-direction: column;
    background-image: linear-gradient(to bottom, var(--marketing-gradient) 0%, var(--gray100) 100%);
    top: 0;
    left: 0;
    position: absolute;

    & .loadingPageInner {
        height: 100%;
        text-align: center;
        margin-top: 80px;
    }

    & .loadingPageTitle {
        font-size: 24px;
        color: var(--gray800);
        font-weight: 600;
    }

    & .loadingPageSubTitle {
        font-size: 14px;
        color: var(--gray800);
        margin: 18px auto 0 auto;
        font-weight: 600;
    }

    & .warningMsg {
        margin-top: 30px;
    }

    & .singularIconLoadingPage {
        width: 224px;
        fill: var(--blue300);
        margin-top: 27px;
    }
}
