@import "../global/theme.css";

:root {
  --collapsedItemMarginX: 4px;
  --collapsedItemMarginY: 6px;
  --collapsedContainerPaddingRight: 40px;
  --collapsedContainerPaddingOther: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  & .mainArea {
      flex-grow: 1;
      overflow: hidden;
      padding-bottom: 10px;
  }
}

.namePatternLabel {
  padding-top: 10px;
  padding-bottom: 10px;
}

:global(.ht_clone_top) {
  z-index: 0 !important;
}

.governanceFieldHeader {
  background-color: var(--blue100);
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;

  & .xButton {
    font-size: 18px;
    width: 12px;
    cursor: pointer;
    fill: var(--gray500)
  }

  & .upDownButton {
    margin-right: 10px;
    font-size: 18px;
    width: 12px;
    cursor: pointer;
    fill: var(--gray500)
  }
}

.governanceFieldContent {
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--gray100);
}

.schemaContentArea {
  padding: 20px;
}

.collapsedContentContainer {
  padding: calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginY))
           calc(var(--collapsedContainerPaddingRight) - var(--collapsedItemMarginX))
           calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginY))
           calc(var(--collapsedContainerPaddingOther) - var(--collapsedItemMarginX));
  display: flex;
  background-color: var(--gray100);
  flex-flow: wrap row;
}

.collapsedValueItem {
  padding: 8px;
  margin: var(--collapsedItemMarginY) var(--collapsedItemMarginX) var(--collapsedItemMarginY) var(--collapsedItemMarginX);
  background-color: var(--blue150);
  font-size: 12px;
  border-radius: 2px;
}

.manageCodes {
  display: flex;
  flex-direction: column;

  & .headerRow {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  & .schemaSelection {
    max-width: 830px;
    flex-grow: 1;
    padding-right: 50px;

    display: flex;
    align-items: center;

    & .schemaSelectionDropdown {
      flex-grow: 1;
    }
  }

  & .buttonsContainer {
      display: flex;
      align-items: end;
      justify-content: space-between;
      flex-direction: row;
      gap: 10px;
  }

  & .leftToggle {
    margin-left: 15px;
  }

}

.schema {
  margin-bottom: 20px;

  & .headerRow {
    display: flex;
    flex-direction: row;
    align-items: center; // controls where items sit on cross-axis (center = middle)
    justify-content: space-around;
  }

  & .schemaName {
    flex: 1;
  }

  & .schemaActions {
    & > * {
        margin-left: 10px;
    }

    & .deleteSchemaIcon {
      color: var(--gray400);
      cursor: pointer;

      & path {
        fill: var(--gray400);
      }

      &:hover {
        & path {
          fill: var(--red300);
        }
      }
    }
  }

  & .footerBlock {
    background-color: var(--gray100);
    padding: 30px;
    display: flex;
    align-items: center;

    & .addValueButtonContainer {
      flex: 1;
    }
  }
}

.dropzone {
  border-style: dashed;
  border-width: 1px;
  padding: 30px;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;

  & .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid var(--gray200);
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;

    & .thumbInner {
      display: flex;
      min-width: 0;
      overflow: hidden;

      & img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }
}

.newGovernanceActionArea {
  display: flex;
  gap: 10px;
}

.expandedTitleContainer {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.schemaTypePrefix {
  font-style: italic;
  padding-right: 10px;
}

.emptyState {
  margin: 100px 0;
}