@import "../../global/theme.css";
@import "../../global/general.css";

.dynamicTooltipHolder {
    display: flex;
    align-items: center;

    & .dynamicCheckMark {
        margin-right: 5px;
        visibility: hidden;
        text-align: left;
        height: var(--icon-size-small);
    }

    & .dynamicTooltip__tracker {
        fill: var(--orange300);
        color: var(--orange300);
    }

    & .dynamicTooltip__unified {
        fill: var(--green300);
        color: var(--green300);
    }

    & .dynamicTooltip__skan {
        fill: var(--purple300);
        color: var(--purple300)
    }

    & .dynamicTooltip__network {
        fill: var(--teal300);
        color: var(--teal300)
    }

    & .tooltipTitleSelected {
        font-weight: 600;
    }

    & .visible {
        visibility: visible;
    }
}

.tooltipContent {
    white-space: pre-line;
    margin-left: auto;
    display: inline-block;
    text-align: left;
    font-size: 14px;
    padding: 0 50px 5px 0;
    font-weight: 500;
}