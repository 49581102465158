@import "../styles.css";

.counter {
  display: flex;
  align-items: center;
}

.vSeperator {
  height: 16px;
  width: 1px;
  background-color: #a7a7a7;
  margin: 0 9px;
}

.preamble {
  margin: 0;
  flex-grow: 1;
}

.valuesList {
  overflow: scroll;

  & .value {
    transition: border 0.1s ease-in-out;
    height: 50px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    outline: none;

    border: solid var(--white);
    border-width: 0 0 0 3px;
    margin-bottom: 3px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.selectedValue {
      border-color: var(--blue300);
    }
  }

}

.valueName {
  flex-grow: 1;
  font-weight: 300;
  font-size: 16px;
  color: var(--gray700);
  margin-left: 10px;
}

.checkbox {
  align-self: right;
  margin-bottom: 0;
  margin-right: 13px !important;
}

.separator {
  height: 0;
  opacity: 0.7;
  border: solid 0.5px var(--gray400);
  margin: 10px 0;
}