@import '../../../global/theme.css';

.dimensionSearchInput {
    font-size: 12px;

    & .dimensionSearchInputIcon {
        fill: var(--gray400);
        width: 16px;
        height: 16px;
    }

    & .clearIcon {
        width: 16px;
        height: 16px;
        pointer-events: auto;
        cursor: pointer;
        fill: var(--gray700);
    }

    & .label {
        font-size: 14px !important;
        margin: auto;
    }
}