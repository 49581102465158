@import '../global/theme.css';

.title {
    font-size: 18px;
    color: var(--gray800);
}

.dialogPaper {
    overflow-y: hidden !important;
    background-color: var(--white) !important;
    width: 550px !important;
    height: 165px !important;
    padding: 35px !important
}

.closeIcon {
    cursor: pointer;
    top: 20px;
    right: 20px;
    height: 15px;
    position: absolute;
    fill: var(--gray700);
}

.container {
    display: flex;

    & .header {
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    & .content {
        height: 365px;
        display: flex;
        justify-content: space-between;
        width: 500px;
    }

    & .body {
        display: flex;
        flex-direction: column;
        height: 80px;
        justify-content: space-between;
        width: 350px;
    }

    & .errorIcon {}
}

@media (--mobile) {
    .container {
        & .body {
            width: 100%;
        }

        & .title {
            font-size: 14px;
        }

        & .errorIcon {
            display: none;
        }
    }
}