@import "../../../../global/theme.css";

.sectionContainer {
    padding-bottom: 10px;

    & .expendedContent {
        margin-bottom: 25px;
    }

    & .image {
        max-width: 800px;
        height: auto;
    }

    & .row {
        margin-bottom: 20px;

        & p {
            margin-left: 31px;
        }

        & .fauxStep {
            display: flex;
            padding: 8px 13px;
            flex-direction: column;
        }

        & .grayBack {
            background-color: var(--gray100);
            border-radius: 8px;
        }

        & .titleRow {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

        }

        & .titleBullet {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            color: var(--white);
            text-align: center;
            font-weight: bold;
            margin-right: 10px;
        }

        & .titleBulletBlueBack {
            background-color: var(--blue300);
        }

        & .title {
            font-weight: bold;
            font-size: 14px;
            color: var(--gray800);
            width: calc(100% - 21px);
            margin: 0;
        }

        & .stepLink {
            font-weight: 600;
            color: var(--blue300);
        }
    }
}
