@import '../../global/theme.css';

.goBackPanel {
    height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & .goBackPanelContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & .arrowIcon {
        transform: rotate(90deg);
        height: 20px;
        width: 10px;
        fill: var(--blue200);
        margin-right: 8px;
    }

    & .label {
        font-size: 13px;
        font-weight: 600;
        color: var(--gray700)
    }

    & .XIcon {
        width: 10px;
        position: absolute;
        right: 15px;
        fill: var(--gray600);
    }

    & .homeIcon {
        margin-right: 8px;

        & g {
            width: 10px;
            fill: var(--gray700);
        }
    }
}

