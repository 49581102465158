@import "../../../global/theme.css";

.section {
    position: relative;
    border-radius: 5px;
    border: solid 1px var(--gray400);
    max-height: 280px;
    overflow: auto;
    user-select: none;

    &:hover, &.expanded {
        border: solid 1px var(--blue300);
    }

    & .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        background-color: white;
        padding: 5px 12px;
        position: sticky;
        top: 0;
        font-size: 13px;
        cursor: pointer;
        z-index: 1;

        & .titleText {
            width: 100%;
            border: none;
            padding: 4px 0;
        }

        &.noResultsTitle {
            cursor: default;
            opacity: 0.5;
        }

        &::placeholder {
            font-size: 5px !important;
        }

        & .arrow {
            width: 12px;
            color: var(--blue300) !important;
            opacity: 1;

            &.desc {
                transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
            }
        }
    }

    & .secondaryTitle {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin: 12px;

        & .divider {
            margin-left: 6px;
            flex-grow: 1;
            height: 1px;
            background-color: var(--gray200);
        }
    }

    & .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 4px 8px;
        padding: 6px 10px;
        border-radius: 6px;

        &.selectedItem {
            background-color: var(--gray200);
            opacity: 1 !important;
        }

        &.disabled {
            opacity: 0.5;
        }

        &.disabled:hover {
            background-color: var(--gray200);
            opacity: 1;
        }

        &:hover {
            background-color: #eaf3fe;
            cursor: pointer;

            & .itemActions {
                opacity: 1;
            }
        }

        & .itemActions {
            opacity: 0;
            fill: var(--blue300);
            cursor: pointer;

            & .actionIcon {
                height: 15px;
            }
        }
    }

    & .addItem {
        position: sticky;
        bottom: 0;
        font-weight: 600;
        color: var(--blue300);
        background-color: white;
        padding: 8px 10px;
        cursor: pointer;
    }
}

::placeholder {
    font-size: 14px !important;
    font-style: normal !important;
}