@import "../global/theme.css";

.spinnerWrapper {
    display: flex;
    height: 100%;
}

.headerContainer {
    position: sticky;
    top: 0;
    z-index: var(--stickyZIndex);
    display: flex;
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: start;
    background-color: var(--bodyBackgroundColor);

    & .datePicker {
        margin-right: 55px;
    }
}
