@import "../../global/theme.css";

.fieldGroupRow {
  margin-bottom: 20px;
}

.fieldGroupRow:first-child {
  margin-top: 20px;
}

.fieldGroupRow:last-child {
  margin-bottom: 0;
}

:global(.Select-arrow-zone), :global(.Select-clear-zone) {
  display: none;
}

:global(.Select-control) {
  border-color: var(--gray400);
}

.separator{
  height: 1px;
  width: 100%;
  background-color: var(--white);
  margin: 20px 0;
}