@import '../../../global/theme.css';

.container {
  padding: 0;
  background-color: var(--bodyBackgroundColor);
  position: relative;

  &.containerWithPadding {
    padding: var(--pageWrapperTopPadding) var(--pageWrapperLeftPadding);
  }

  /* This prevents the actions dropdown from cutting at the bottom of the page.
     Such pages include Audience Segments & Conversion Models.
  */
  &.containerWithDropDownScrolling {
    min-height: 100% !important;
  }
}

.containerWrapper {
  display: inline;  /* VERY IMPORTANT: for actionsContainer stickiness */
  overflow-x: hidden;
}
