@import "../../global/theme.css";

.dimensionsFilterContainer {
  position: absolute;
  top: 12px;
  right: 180px;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
  color: var(--gray700);
}

.dimensionsFilterLabel {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 300;
}

.dimensionsFilterDropdown {
  width: 170px;
}
