@import "../../global/theme.css";

:root {
  --strength-gap: 2px;
}

.strengthMeter{
  position: relative;
  height: 3px;
  margin: 7px 0;
  border-radius: 2px;

  &:before, &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: var(--gray300);
    border-style: solid;
    border-width: 0 var(--strength-gap) 0;
    position: absolute;
    width: calc(25% + var(--strength-gap));
    z-index: 10;
  }
  &:before {
    left: calc(25% - var(--strength-gap) / 2);
  }
  &:after {
    right: calc(25% - var(--strength-gap) / 2);
  }
}

.strengthMeterFill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.strengthMeterFill[data-strength='1'] {
  width: 25%;
  background: var(--red300);
}
.strengthMeterFill[data-strength='2'] {
  width: 50%;
  background: var(--orange300);
}
.strengthMeterFill[data-strength='3'] {
  width: 75%;
  background: var(--green300);
}
.strengthMeterFill[data-strength='4'] {
  width: 100%;
  background: #3088f4;
}

.strengthMeterText {
  float: right;
  font-size: 11px;
}


.strengthMeterText[data-strength='0'] {
  color: var(--gray600);
}
.strengthMeterText[data-strength='1'] {
  color: var(--red300);
}
.strengthMeterText[data-strength='2'] {
  color: var(--orange300);
}
.strengthMeterText[data-strength='3'] {
  color: var(--green300);
}
.strengthMeterText[data-strength='4'] {
  color: #3088f4;
}

.dialogBody {
  padding: 23px 30px;
}

.dialogFooter {
  background: var(--gray300);
  padding: 12px 30px;
  display: flex;
  justify-content: flex-end;
}

.dialog {
  background-color: var(--bodyBackgroundColor) !important;
}