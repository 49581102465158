@import '../../../global/theme.css';

.dialog {
    width: 500px;
    max-height: 600px !important;

    .content {
        padding: 24px 30px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .descriptionContainer {
            display: flex;
            font-size: 14px;
            gap: 7px;

            .infoIcon {
                fill: var(--blue300);
                transform: scale(1.3);
            }
        }

        .valuesContainer {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .customDimensionName {
                font-weight: bold;
                font-size: 14px;
            }
        }

        .footerButtonContainer {
            display: flex;
            justify-content: flex-end;
        }
    }
}