@import "../../../../global/theme.css";

.contentSeparator {
    height: 1px;
    margin-bottom: 18px;
    margin-top: 18px;
    background-color: var(--gray400);
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
}

.navigationButtonsContainer {
    display: flex;
}

.navigationButton {
    width: 105px;
    max-height: 29px;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-right: 8px;
}

.navigationButton:active, .navigationButton:hover {
    & .backIcon {
        fill: white;
    }
}

.contentWithoutNavigation {
    padding: 60px 90px 15px;
    justify-content: center;
}

.contentWithNavigation {
    padding: 28px 41px 28px 41px;
    flex-direction: column;
}

.chevronIcon {
    fill: white;
    width: 11px;
    height: 12px;
    &.backIcon {
        transform: rotate(90deg);
        margin-right: 6px;
        fill: var(--primaryColor);
    }
    &.nextIcon {
        transform: rotate(-90deg);
        margin-left: 6px;
    }
}
