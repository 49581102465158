@import '../../global/theme.css';

.bannersList {
    overflow-y: auto;

    & .bannerItem {
        margin-bottom: 20px;

        & .headerContainer {
            overflow: visible; /* for actions menu */
            background-color: var(--blue100);
            border: none !important;
            height: 60px;
        }

        & .contentArea {
            display: flex;
            padding: 15px 30px;
            background-color: white;

            & .bannerFields {
                padding: 5px 30px;
                font-size: 12px;
                line-height: 1.57;
            }
        }

        & .fieldItem {
            display: flex;
            margin: 2px 0;

            & .value {
                font-weight: 600;
                margin-left: 10px;
            }
        }
    }
}
