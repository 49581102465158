@import '../../global/theme.css';

.container {
    display: flex;
    height: 40px;
    padding: 0 20px;
    align-items: center;
    font-size: 14px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    &.isLast {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    &:hover {
        background-color: var(--blue100);
        & .overlayButtons {
            opacity: 1;
            align-items: center;

        }
    }
}

.campaignName {
    flex-basis: 200px;
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 250px;
}
.agency {
    width: 150px;
}

.icons {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    & .icon {
        margin-right: 10px;
    }
    & .icon:last-child {
        margin-right: 0;
    }
    & .agencyIcon {
        margin-right: 10px;
    }
}

.icon {
    width: 20px;
    height: 20px;
    fill: var(--gray700) !important;
    opacity: 0.2;
    text-align: center;
    & * {
        fill: var(--gray700) !important;
        mask: none !important;
        filter: none !important;
    }
    &.noIcon:after {
        content:'';
        width: 8px;
        height: 2px;
        background-color: var(--gray700);
        display: inline-block;
    }
    &.noIcon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.dates {
    margin-left: auto;
    display: flex;
    color: var(--gray600);
    font-size: 12px;
    align-items: center;
    & > * {
        white-space: nowrap;
    }
}

.separator {
    height: 20px;
    width: 1px;
    background-color: var(--gray400);
    margin: 0 20px;
}

.overlayButtons {
    opacity: 0;
    display: flex;
    position: absolute;
    right: 0;
    cursor: default;
    padding: 0 20px;
    box-shadow: inset -160px 0 35px -10px var(--blue100);

    & .overlayButton {
        width: 20px;
        height: auto;
        cursor: pointer;
        fill: var(--gray500);
        margin-left: 15px;
        display: block;
        & * {
            fill: var(--gray500) !important;
        }

        &:hover {
            fill: var(--gray600) !important;
            & * {
                fill: var(--gray600) !important;
            }
        }
    }

    & .duplicate {
        margin-left: 15px;
        margin-top: 6px;
    }
}