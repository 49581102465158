.maxConversionIndicatorWrapper {
    display: flex;
    user-select: none;

    & .maxConversionIndicator {
        flex: 1;

        & .maxConversionIndicatorLabel {
            font-weight: 400;
            display: inline-block;
            margin-right: 5px;

            & + * {
                font-weight: 600;
            }
        }
    }
}
