@import '../../global/theme.css';

table.accountsTable {
    margin: 0;
    margin-top: 0 !important;
    border-collapse: separate !important;
    & td {
        background-color: transparent !important;
        border-top: none !important;
    }
    & td, & th {
        line-height: inherit !important;
        vertical-align: middle !important;
        border-bottom: 1px solid var(--gray400) !important;
        font-size: 13px !important;
        color: var(--gray700) !important;
        opacity: 1 !important;
        padding: 5px 0 !important;
    }
}
.accountsContainer{
    height: 100%;
    max-height: 100%;
    overflow: auto;
}
th.tableHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-top: 1px solid var(--gray400) !important;
    border-bottom: 1px solid var(--gray400) !important;
    background-color: var(--bodyBackgroundColor) !important;
    z-index: 1;
    &:first-child {
        padding-left: 2px !important;
    }
}