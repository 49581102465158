@import "../../../global/theme.css";

.modelDescriptionBox {
    margin: 20px 0 10px 0;

    & .modelDescriptionHeader {
        margin-bottom: 10px;

        & .typeDescriptionHeader {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            color: var(--gray700);
            text-transform: capitalize;
        }
    }

    & .typeDescription {
        display: flex;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: var(--gray700);
        margin-bottom: 20px;

        & > a {
            margin-left: 5px;
        }
    }
}
