@import '../../../global/theme.css';

.container {
    position: relative;
    height: 95px;
    display: flex;
    border-bottom: 1px solid var(--gray400);
    cursor: pointer;
    outline: none;
    justify-content: space-between;

    & .leftSection {
        display: flex;
        flex: 3;
        overflow: hidden;
    }

    & .rightSection {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &:hover, &.selected {
        background-color: var(--blue100);

        & * {
            .colorWithParentItem {
                background-color: var(--blue150);
            }
        }
    }

    &:not(.ok):not(.none) {
        & .icon {
            filter: grayscale(1);
        }

        & .rightSection {
            filter: grayscale(1);
        }
    }
}

.partnerAppSummaryContent {
    font-size: 11px;
    margin: 2px 0;
}

.iconArea {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 10px;
    min-width: 75px;
}

.icon {
    max-width: 65px;
    max-height: 65px;
    border-radius: 10px;
}

.contentArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    gap: 5px;
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.footer {
    font-size: 10px;
    opacity: 0.6;
}

.highlighted {
    font-weight: bold;
    padding: 0;
    color: inherit;
    background-color: yellow;
}

.favouriteArea {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    & .emptyFavourite {

        &:hover {
            fill: var(--blue300);
        }
    }

    & .favourite {
        fill: var(--blue300);

        &:hover {
            fill: var(--blue400);
        }
    }
}
