@import "../../global/theme.css";

.midContainer {
    background-color: var(--white);
    padding: 0 24px 10px 24px;
    margin-top: 8px;
    position: relative;
    height: 435px;
}

.noDataContainer {
    position: absolute;
    top: 155px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    flex-direction: column;
    color: var(--textColorLight);
}
