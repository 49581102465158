@import "../../global/theme.css";

.eivar {
  transition: border 0.1s ease-in-out;
  height: 50px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  outline: none;

  border: solid var(--white);
  border-width: 0 0 0 3px;
  margin-bottom: 3px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &.selected {
    border-color: var(--blue300);
  }
}

.logo {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.name {
  flex-grow: 1;
  font-weight: 300;
  font-size: 16px;
  color: var(--gray700);

}

.checkbox {
  align-self: right;
  margin-bottom: 0;
  margin-right: 13px !important;
}

.separator {
  height: 0;
  opacity: 0.7;
  border: solid 0.5px var(--gray400);
  margin: 10px 0;
}