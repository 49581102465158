@import "../../global/theme.css";

.container {
  display: flex;
  align-items: center;
  & .box {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    background-color: var(--gray400);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    font-size: 11px;
    font-weight: 600;
    padding-top: 2px;
    cursor: default;

    &:last-child {
      margin-right: 0;
    }
  }
}