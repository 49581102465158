.appSitesSummary {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column;
    overflow: hidden;

    & .addAppSiteButton {
        width: fit-content;
        align-self: end;
    }
}