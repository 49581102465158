@import '../global/theme.css';

:global(.stickyPageWrapper) {
    padding: 0 !important;

    & .headerFiltersWrapper {
        box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.15);
        z-index: 13;
    }

    & .chartsWrapper {
        margin: 0 40px 10px 40px;
    }

    & .spinner {
        margin: 0 40px;
    }
}


.headerFiltersWrapper {
    width: 100%;
    background-color: var(--white);
    padding: 10px 15px 25px 15px;
    margin-bottom: 20px;

    position: sticky;
    z-index: 5;
    top: 0;

    & .headerFilters {
        display: flex;
        justify-content: space-between;

        & .headerFilter {
            width: 100%;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    & :global(.Select__control) {
        align-items: start;
    }
}
