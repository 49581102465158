@import "../../global/theme.css";

.container {
    display: flex;
    position: relative;
    padding-top: 15px;

    &.shelfTabs {
        background-color: white;
        padding: 30px 30px 0;
    }

    & .divider {
        width: 100%;
        border-bottom: 2px solid var(--gray400);
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: 0;
    }

    & .tab, & .tab:hover {
        all: unset;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        color: var(--gray600);
        fill: var(--gray600);;
        margin: 0 15px;
        padding: 0 5px 10px;
        position: relative;
        border-bottom: 6px solid transparent;
        transition: all 500ms ease;
        z-index: 1;

        &:first-child {
            margin-left: 0;
        }

        & .tabLabelContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            & .tabIcon {
                vertical-align: middle;
                fill: inherit;
            }

            & .tabLabel {
                padding: 0 10px;
            }
        }
    }

    & .activeTab, .activeTab:hover {
        color: var(--blue300);
        border-bottom-color: var(--blue300);
        fill: var(--blue300);
    }

    & .toolTip {
        height: 30px;
        padding-left: 12px;
    }
}

.disabled {
    opacity: 0;
}
