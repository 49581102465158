@import "../../../global/theme.css";

.container {
    padding: 25px 0 25px 60px;

    & .mixedModelContent {
        display: flex;

        & .mixedSubModelContent {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-right: 30px;

            & .mixedSubModelTitle {
                margin-bottom: 15px;
                font-weight: 600;
            }
        }
    }
}


