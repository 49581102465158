@import '../global/theme.css';

.container {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 14px;
    color: var(--gray700);
}

.subHeader {
}

.filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
}

.sourcesAvailability {
    margin-top: 15px;
}

.dimensionHint {
    font-weight: 300;
    padding-bottom: 25px;

    & .dimensionName {
        font-weight: 600;
    }

    & .infoIcon {
        width: 13px;
        height: 13px;
        fill: var(--gray600);
        margin-right: 5px;
        margin-bottom: 3px;
        vertical-align: middle;
    }
}
.table {
    & .tableHeaderContainer {
        display: flex;
        justify-content: center;

        & .tableHeader {
            font-weight: 600;
            color: var(--gray700);
        }
    }

    & .sourceImg {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;
        vertical-align: middle;
    }

    & .tableTdInfoText {
        font-size: 10px;
        font-weight: 300;
        color: var(--gray600);
        margin-left: 5px;
    }
}

.minus {
    width: 14px;
    height: 3px;
    border-radius: 1.5px;
    background-color: var(--gray400);
}

.tooltipWrapper {
    margin-left: auto;

    &.pointer {
        cursor: pointer;
    }
}

.noData {
   fill: var(--gray400);
}

.indicationTdIcon {
    display: flex;
    margin: 5px auto 0 auto;

    &.active {
        fill: var(--primaryColor);
        height: var(--icon-size-small);
    }
}

td.indicationTd {
    width: 170px;
}

.availabilityParameterDropdown {
    width: 150px !important;
}

.dimensionsAvailabilityContainer {
    margin-top: 15px;
}

.emptyState {
    width: 0;
    height: 0;
    background-color: var(--gray100);
    border-radius: 50%;
    opacity: 0;

    & .emptyStateIcon {
        background-color: var(--gray100);
        fill: var(--gray400);
        width: 108px;
        height: 70px;
    }

    & .smallEmptyStateIcon {
        width: 60px;
        height: 60px;
    }

    &.show {
        opacity: 1;
        width: 200px;
        height: 200px;
        padding: 66px 46px 65px;
        margin: 150px auto;
    }

    &.showEmptyState {
        width: 130px;
        height: 130px;
        padding: 35px 35px 65px;
        margin: 70px auto 25px;
    }
}

.emptyStateText {
    text-align: center;
}