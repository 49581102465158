@import "../../../../global/theme.css";

.postbackPopup {
    position: absolute !important;
    width: 870px !important;;
    height: fit-content;
    top: 0;
    bottom: 0;
    right: 75px;
    margin: auto !important;

    & .container {
        & .title {
            font-size: 14px;
            font-weight: bold;
            color: var(--gray700);
        }

        & .postbackField {
            display: flex;
            margin: 20px 0;

            & .postbackUrl {
                display: flex !important;
                font-size: 13px;
                color: grey;
            }

            & .text {
                width: 240px;
            }

            & .radioButton {
                display: flex;
            }

            & .input {
                  width: 550px;
                & .postback {
                      height: 200px;
                }
            }

            & .note {
                width: 550px;
            }
        }

        & .actionButtons {
            display: flex;
            justify-content: flex-end;
            & .acceptButton {
                margin-left: 5px;
            }
        }
    }
}
