@import "../../global/theme.css";

.container {
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    width: fit-content !important;

    & :global {
        & .daterangepicker {
            width: max-content !important;

            & .calendar {
                max-width: none;
            }
        }
    }
}

.container.open {
    & :global(.daterangepicker.dropdown-menu) {
        display: flex !important;
    }
}

.container.hideRanges {
    & :global {
        & .daterangepicker {
             & .ranges {
                display: none;
             }
        }
    }
}

.container.singleDatePicker {
    & :global {
        & .daterangepicker {
            & .calender.right {
                display: none;
            }
            & .start-date {
                border-radius: 4px;
            }
        }
    }
}

.buttonContainer {
    border-radius: var(--inputBorderRadius);
    background-color: var(--white);
    @apply --font-paragraph;
    border: 1px solid var(--gray400);
    outline: none;
    height: 36px;
    padding: 0 10px 0 3px;
    display: flex;
    align-items: center;
    &.disabled {
        background-color: var(--gray50) !important;
        cursor: not-allowed !important;
    }
}

.iconContainer {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 3px;
    &.disabled {
        background-color: var(--gray50) !important;
    }
}

.icon {
    fill: var(--primaryColor);
    &.disabled {
        fill: var(--primaryButtonTextDisabledDark) !important;
    }
}

.label {
    font-weight: 600;
    color: var(--primaryColor)
}

.dateRange {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 7px;
    color: var(--primaryColor);
    &.disabled {
        color: var(--primaryButtonTextDisabledDark) !important;
    }
}

.title {
    position: absolute;
    top: -20px;
    color: #026497;
    @apply --font-paragraph;
    font-size: 11px;
}
