.checkoutEmbedPage {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.nonStripeContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    align-self: center;
    max-width: 888px;
    width: 100%;
    min-height: 100px;
}

.backButton {
    padding-left: 0 !important;
    border: none !important;
    width: 190px;

    &:hover {
        background-color: #FFF !important;
        color: var(--gray700) !important;

        .backIcon {
            fill: black !important;

        }
    }

    .backIcon {
        transform: rotate(90deg);
    }
}
