@import "../../global/theme.css";

.pacingCardContainer {
    --pacing-bar-header-color: var(--warning1Color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 420px;
    max-width: 586px;
    flex: 1 1 0;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--gray400);
    background-color: var(--white);

    &.attention {
        border: 4px solid #0000;
        background: linear-gradient(var(--white), var(--white)) padding-box, linear-gradient(
        180deg,
        var(--red400),
        var(--red100)
        ) border-box;
        padding-top: 22px;
    }

    .pacingCardTop {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        min-height: 260px;

        .pacingTitle {
            color: var(--gray800);
            font-size: 16px;
            font-weight: 600;
            line-height: 32px;
        }
        .lineSeperator {
            width: 100%;
            height: 1px;
            background-color: var(--gray400);
        }

        .pacingBarHeaderContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .pacingBarHeader {
                color: var(--pacing-bar-header-color);
                font-size: 32px;
                font-weight: 600;
            }

            .pacingBarInfo {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: var(--gray700);
            }
        }

    }

    .warningMessage {
        width: 100%;
    }
}
