.container {
    max-width: 786px;

    & .errorContainer {
        margin-top: 22px;
    }

    & .contentContainer {
        display: flex;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
        gap: 8px;
        flex-direction: column;
        padding: 16px;
        margin-top: 24px;

        & .inputContainer {
            border-radius: 4px;
            min-height: 44px;
            width: 100%;

            & .input {
                border-radius: 4px;
            }
        }
    }
}