@import "../global/theme.css";

:root {
    --searchInputHeight: 36px;
    --searchInputBottomPadding: 20px;
    --headerTopPadding: 10px;
    --headerBottomPadding: 5px;
    --slackContainerHeight: 30px;
}

.noDataContainer {
    position: absolute;
    top: 250px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    flex-direction: column;
    color: var(--textColorLight);
}

.addNewUanSection {
    display: flex;
    justify-content: flex-end;
}

.header {
    padding: var(--headerTopPadding) 0 var(--headerBottomPadding) 0;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: var(--bodyBackgroundColor);

    & .search {
        position: relative;
        display: inline-block;

        & .searchInput {
            box-sizing: border-box;
            width: 320px;
            height: var(--searchInputHeight);
        }
    }
}

.subHeader {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 14px;
}

.thead {
    display: block;
    position: sticky;
    top: calc(
            var(--searchInputHeight) +
            var(--searchInputBottomPadding) +
            var(--headerTopPadding) +
            var(--headerBottomPadding));
    z-index: 1;
}

.actionsTh {
    & th:nth-last-child(2) {
        border-right: 0;
    }
}

.theadWithSlack {
    top: calc(var(--searchInputHeight) +
    var(--searchInputBottomPadding) +
    var(--headerTopPadding) +
    var(--headerBottomPadding) +
    var(--slackContainerHeight));
}

.tbody {
    display: block;
}

.fieldsAvailability {
    cursor: pointer;
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 195px;
    height: 36px;
    padding: 10px 15px 0 0;
    border-right: 1px solid var(--gray400);
    right: 200px;
    z-index: 4;

    & .fieldsAvailabilityLabel {
        font-size: 13px;
        font-weight: 600;
        color: var(--blue300);
    }

    & .fieldsAvailabilityIcon {
        fill: var(--blue300);
    }
}

.filterDataConnectors {
    display: flex;
    align-items: center;

    & .filterDataConnectorsLabel {
        font-size: 13px;
        font-weight: 600;
        color: var(--blue300);
        padding-left: 5px;
    }

    & .filterDataConnectorsIcon {
        fill: var(--blue300);
    }

    & .filterDataConnectorsTextField {
        margin-left: 20px;
        align-items: center;
    }
}

.dataConnectorsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--searchInputBottomPadding);
}

.dataConnectorsSlackSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dataConnectorsTopSection {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.slackContainer {
    float: right;
    height: var(--slackContainerHeight);
    width: auto;
    align-items: center;
    display: inline-flex;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    & .line {
        height: 24px;
        width: 1px;
        margin: 0 10px 0 7px;
        background-color: var(--gray500);
    }

    & .slackText {
        width: 100%;
        font-size: 13px;
        color: var(--gray700);
        display: flex;
    }

    & .slackImg {
        width: 18px;
        height: 18px;
        margin: 2px 8px 0 0;
        object-fit: contain;
    }
}

.fieldsAvailabilityShelfIcon {
    fill: var(--white);
    margin: 0 16px 2px 0;
    vertical-align: middle;
}

.dataConnectorAlertsShelfIcon {
    fill: var(--white);
    margin: 0 16px 2px 0;
    vertical-align: middle;
}

.addUanButton {
    border-radius: 0 2px 2px 0;
    z-index: 4;
}
