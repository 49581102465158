@import '../../../global/theme.css';

:root {
    --dimensionsGap: 12px
}

.dimensionsContainer {
    display: flex;
    flex-direction: column;
    gap: var(--dimensionsGap);

    & .dimensionsRow {
        display: flex;
        gap: var(--dimensionsGap);
        overflow: hidden;

        & .osIcon {
            fill: var(--gray500);
        }
    }
}

.tag {
    display: flex;
    gap: 4px;
    padding: 2px 4px;
    background-color: var(--gray100);
    border: 1px solid var(--gray300);
    border-radius: 4px;
    overflow: hidden;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    height: 30px;

    img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }
}