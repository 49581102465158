.filterContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: end;
}

.dropdownLabel {
    margin-right: 14px;
}

.filterItem {
    margin-bottom: 10px;
    align-items: center;
    display: flex;
}

.dropdown {
    width: 505px;
}
