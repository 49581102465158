@import '../../global/theme.css';

.container {
    display: flex;
    position: relative;

    & .labels {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .subTextContainer {
            display: flex;
            align-items: center;
            font-size: 11px;

            & > *:not(:last-child)::after {
                content: "|";
                margin: -1px 3px 0;
                font-size: 10px;
            }

            & .avatar {
                margin-right: 4px;
                &::after {
                    content: none;
                 }
            }
        }
    }

    & .highlighted {
        padding: 0;
        background-color: var(--highlightColor);
    }

    & .image {
        margin: auto 0;
    }
}

.ellipsis {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.noSubTextExtra {
    margin-top: -5px;
}

.tooltip {
    font-size: 13px !important;
    margin: 0 auto;
    background-color: var(--white) !important;
    color: var(--gray600) !important;
    border: 1px solid var(--gray500);
}

.opaqueBackground {
    opacity: 1!important;
}

:root {
    --markSize: 5px;
    --imgBadgeSize: 16px;
}

.markCell {
    width: var(--markSize);
    height: var(--markSize);
    border-bottom: solid var(--markSize) transparent;
    border-left: solid var(--markSize) var(--gray400);
    border-right: solid var(--markSize) transparent;
    position: absolute;
    border-top: solid var(--markSize) var(--gray400);
    left: var(--pageWrapperLeftPadding);
    cursor: pointer;
}


.imgBadge {
    left:27px;
    top: 22px;
    position: absolute;
    height: var(--imgBadgeSize);
    width: var(--imgBadgeSize);
    background-color: var(--white);
    border-radius: 50%;
    border: 1px solid var(--white);
}

.sideImg {
    right: 10px;
    top: 18px;
    position: absolute;
}

.reversedContainer {
    flex-direction: row-reverse;
    justify-content: flex-end;
    & .image {
        align-self: self-end;
      }
    & .labels {
        flex-grow: 1;
        max-width: 92%;
      }
}
