@import "../../global/theme.css";

.noDataContainer{
    position: relative;
    margin-top: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  flex-direction: column;
  color: var(--textColorLight);
}

.emptyStateBoxesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 950px;
}

.textContainer {
    text-align: center;
}

.emptyStateHeader {
    color: var(--gray800);
    font-size: 18px;
    margin-bottom: 6px;
    display: block;
}
.emptyStateSubHeader {
    color: var(--gray700);
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
}
.topConnectorsText {
    color: var(--gray800);
    font-size: 16px;
    margin: 30px 0 20px;
}