@import "../../../global/theme.css";

.logoPlaceholder {
    border: 1px solid var(--gray300);
}

.scaleBanner {
    position: absolute !important;
    width: 143% !important;
    transform: scale(0.7);
    transform-origin: top left;

    &.bottom {
        transform-origin: bottom left !important;
    }
}
