.container {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    align-items: flex-start;
    border-radius: 8px;
    height: fit-content;
    background: var(--white, #FFF);
    box-shadow: 0 4px 8px 0 rgba(191, 191, 191, 0.25);

    &.anchor {
        position: fixed;
    }

    &.topEnd {
        right: 40px;
        top: 30px;
    }

    &.topStart {
        left: 40px;
        top: 30px;
    }

    &.bottomStart {
        left: 40px;
        bottom: 30px;
    }

    &.bottomEnd {
        right: 40px;
        bottom: 30px;
    }

    & .header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-self: stretch;

        & .headerContent {
            margin-bottom: 8px;
        }
    }

    & .content {
        display: flex;
        width: 90%;
        align-items: flex-start;
        gap: 24px;
        flex-shrink: 0;

        & .leftIcon {
            margin-left: 8px;
        }

        & .bodyContainer {
            display: flex;
            flex-direction: column;
            gap: 16px;

            & button {
                width: fit-content;
            }

            & .body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;

                & .label {
                    color: var(--gray800);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                }

                & .subLabel {
                    color: var(--gray700);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }

    }

    & .closeIcon {
        cursor: pointer;
        height: 9px;
        width: 9px;
        fill: var(--gray700);
        flex-shrink: 0;
    }

}
