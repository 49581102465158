@import "../../../global/theme.css";

.siteField {
    width: 500px;
    display: flex;

    & .sitesAutocomplete {
        margin-left: 10px;
    }
}

.siteNotFound {
    line-height: 40px;
}

.siteOptionIcon {
    width: 18px;
    height: 18px;
    border-radius: 4px;
}

.siteOptionDisabled {
    pointer-events: none;

    &, &:hover {
        color: var(--gray400) !important;
    }

    & .siteOptionIcon {
        opacity: 0.4;
    }
}
