@import "../../global/theme.css";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  @apply --font-size-medium-large;
}

.cancelButton {
  background-color: var(--gray500);
  border-color: var(--gray600);
  margin-right: 20px;
}

.deleteButton {
  background-color: var(--red300);
  border-color: var(--red300);
  margin-right: 20px;
}