@import "../../global/theme.css";

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

:root {
    --loaderColor: #F6F6F6;
    --loaderColorMid: #F0F0F0;

    --animatedBackground: {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: var(--loaderColor);
        background: linear-gradient(to right, var(--loaderColor) 8%, var(--loaderColorMid) 18%, var(--loaderColor) 33%);
        background-size: 800px 104px;
        position: relative;
    }
}

.wrapper {
    width: 100%;

    & .wrapperCell {
        display: flex;
    }

    & .text {
        width: 100%;

        & .textLine {
            height: 10px;
            width: 100%;
            margin: 4px 0;
            @apply --animatedBackground;
        }
    }
}
