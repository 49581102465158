@import "../../global/theme.css";

.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & .mainArea {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        & .leftArea {
            flex-shrink: 0;
            width: 260px;
            background-color: var(--white);
            border-right: 1px solid var(--gray400);
            overflow: auto;
        }

        & .rightArea {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding: 26px 0;
            overflow: auto;

            & .faqLinks {
                position: absolute;
                right: 30px;
            }
        }
    }
}