@import "../../../global/theme.css";
:root {
    --widgetBorderRaduis: 10px;
}

.gridWidgetHeader {
    flex-shrink: 0;
    background-color: var(--white);
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: var(--gray800);
    padding-left: 15px;
    padding-right: 5px;
    overflow-x: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: var(--widgetBorderRaduis) var(--widgetBorderRaduis) 0 0;

    & .gridHeaderDataType {
        font-size: 14px;
        display: flex;
        margin-right: 7px;

        & .gridHeaderDataTypeIcon {
            margin-right: 6px;
            display: flex;
            align-items: center;
        }

        & .gridHeaderDataTypeText {
            color: var(--gray600);
            margin-right: 7px;
        }
    }

    & .gridHeaderContents {
        flex: 1;
    }

    & .gridIconsSector {
        display: flex;
        align-items: center;
        padding-left: 6px;
    }

    & .gridIconContainer {
        display: flex !important;
        align-items: center;
    }

    & .deleteWidgetIcon {
        width: 17px;
        margin-right: 10px;
        color: var(--gray400);
        cursor: pointer;
        margin-top: 1px;

        & path {
            fill: var(--gray400);
        }

        &:hover {
            & path {
                fill: var(--red300);
            }
        }
    }
    & .cloneWidgetIcon, & .editWidgetIcon, & .exportWidgetIcon {
        margin-right: 10px;
    }

    & .editWidgetIcon, & .exportWidgetIcon {
        color: var(--gray400);
        cursor: pointer;

        & path {
            fill: var(--gray400);
        }

        &:hover {
            & path {
                fill: var(--gray500);
            }
        }
    }

    & .editWidgetIcon {
        width: 14px;
        margin-top: 1px;
    }

    & .exportWidgetIcon {
        width: 16px;
    }
}

.gridWidgetContent {
    background-color: var(--white);
    flex: 1;
    border-radius: 0 0 var(--widgetBorderRaduis) var(--widgetBorderRaduis);

    & .gridWidgetCenterContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
    }

    & .gridWidgetCenterNoData {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        text-align: center;
    }

    & :global(.gridContainer) {
        border-radius: 0 0 var(--widgetBorderRaduis) var(--widgetBorderRaduis);
    }

    & :global(.ag-status-bar) {
          display: none;
    }

    & :global(.ag-side-bar) {
       border-bottom-right-radius: var(--widgetBorderRaduis);
    }

    & :global(.ag-side-buttons) {
       border-bottom-right-radius: var(--widgetBorderRaduis);
    }

    & :global(.highcharts-container) {
       border-radius: 0 0 var(--widgetBorderRaduis) var(--widgetBorderRaduis);
    }
}
