.tagsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2px 16px 10px 16px !important;
    line-height: 0;
    height: 100%;

    &.loading {
        display: table-cell;
        padding: 0 16px !important;
    }
}