@import "../../../../global/theme.css";

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 21px var(--pageWrapperLeftPadding) 0 var(--pageWrapperLeftPadding);
    background-color: var(--white);
    position: sticky;
    top: 0;
    z-index: 999;
}

.onlyTitleContainer {
    padding: 21px var(--pageWrapperLeftPadding) 15px var(--pageWrapperLeftPadding);
}

.stickyChildrenContainer {
    background-color: var(--white);
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0 var(--pageWrapperLeftPadding) 15px var(--pageWrapperLeftPadding);
}

.actionContainer {
    padding: 17px 0 0 0;
}

.emptyStateContainer {
    padding-top: 15px;
}
