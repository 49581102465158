@import "../../global/theme.css";

.topXContainer {
  position: absolute;
  top: 12px;
  right: 9px;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
  color: var(--gray700);
}

.topXLabel {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 300;
}

.topXDropdown {
  width: 60px;
}
