@import "../../global/theme.css";

.planCard {
    --font-size: 14px;
    --large-font-size: 24px;
    --medium-font-size: 18px;
    --card-color: var(--blue300);
    --secondary-color: var(--blue100);
    position: relative;
    min-width: 268px;
    max-width: 381px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    padding: 36px;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: 16px;
    border: 1px solid var(--gray400);

    &.smallScreen {
        --font-size: 12px;
        --large-font-size: 20px;
        --medium-font-size: 14px;
    }

    &.starter {
        --secondary-color: var(--teal100);
    }
    &.enterprise {
        --secondary-color: var(--marketing-gradient);
    }

    &.cardSelected {
        border: 4px solid #0000;
        background: linear-gradient(var(--white), var(--white)) padding-box, linear-gradient(
        180deg,
        var(--card-color),
        var(--secondary-color)
      ) border-box;
        padding-top: 34px;
    }

    .selectedBadge {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        padding: 2px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        font-size: 13px;
        font-weight: 600;
        line-height: 25px;
        border: 1px solid var(--card-color);
        color: var(--card-color);
        background: var(--secondary-color);
        &.starter {
            color: var(--teal300);
        }

        &.growth {
            color: var(--blue300);
        }
    }

    .cardNameContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100px;

        .cardName {
            color: var(--card-color);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        }

        .cardDesc {
            font-size: var(--font-size);
        }
    }

    .ctaContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 130px;
        margin-top: 24px;

        .priceContainer {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: 6px;
            height: 36px;

            .cardPrice {
                font-size: var(--large-font-size);
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                color: var(--gray800);
            }

            .cardPriceText {
                font-size: var(--medium-font-size);
                font-style: normal;
                font-weight: 600;
                line-height: 175%;
                color: var(--gray800);
            }
        }



        .ctaButton {
            background-color: var(--card-color);
            justify-content: center;
            font-size: 13px;
            font-weight: 600;
            line-height: 25px;
            &:hover {
                background-color: var(--card-color) !important;
                color: var(--white) !important;
            }

            &.ctaButtonDisabled {
                cursor: not-allowed !important;
                background-color: var(--card-color) !important;
                color: var(--white) !important;
            }
        }
    }

    .lineSeperator {
        width: 100%;
        height: 1px;
        background-color: var(--gray400);
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .bodyContainer {
        font-size: var(--font-size);
        ul {
            line-height: 160%;
        }
    }

    .footerContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .pricingFooter {
            font-size: 14px;
            color: var(--blue300);
            flex-direction: row-reverse;
            gap: 8px;
            justify-content: flex-end;

            &:hover {
                color: var(--blue300) !important;
                background-color: var(--white) !important;

            }

        }
        .footerIcon {
            width: 24px;
            &:hover {
                color: var(--blue300) !important;
                background-color: var(--white) !important;
            }
}
    }
}