@import '../../global/theme.css';


.container {
    @apply --font-input-label;
    position: relative;
}

.errorMessage {
    position: absolute;
    right: 0;
    top: 100%;
    font-size: 11px;
    color: var(--red300);
}

.labelText {
    line-height: 26px;
    display: flex;
    align-items: center;
    position: relative;
    & > * {
        margin-left: 6px;
    }
    & > *:first-child {
        margin-left: 0;
    }
    & .mandatory {
        color: var(--red300);
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
}

.badge {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px !important;

    & .badgeInner {
        width: 12px;
        height: 14px;
        font-size: 10px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: white;
    }
}