@import "../../global/theme.css";

.editableTitleHolder {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  border-bottom: 1px transparent dashed;
  overflow: visible;
  max-width: 550px;

  &.error:not(.inputSelected) {
    border-bottom-color: var(--red300);
    border-bottom-style: solid;
  }
  
  &:hover:not(.inputSelected) {
    border-bottom-color: var(--gray400);
    &.error {
      border-bottom-color: var(--red300);
    }
    & .pencil {
      opacity: 1;
    }
  }
  &.inputSelected {
    border-bottom-style: solid;
    border-bottom-color: var(--gray600);
    & .pencil {
      opacity: 1;
      & path {
        fill: var(--gray600);
      }
    }
    &:hover {
      border-bottom-style: solid;
    }
  }
}


.editableInput {
  outline: none;
  background-color: transparent;
  border: none;
  width: 100%;
  padding-left: 0;
  font-size: 18px;
  
  &::placeholder {
    font-weight: 200;
    font-style: italic;
  }
}


.pencil {
  width: 13px !important;
  height: 16px;
  opacity: 0;
  transform: translate(18px, 8px);

  & path {
    fill: var(--gray400);
  }
}

.errorMessage {
  position: absolute;
  right: 0;
  bottom: -16px;
  font-size: 11px;
  font-weight: 300;
  color: var(--red300);
}
