@import "../../../global/theme.css";

.optimizedModelsShelfGroup {
    margin-top: 30px;
}

.optimizedModelsShelfGroupLabel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2px;
}

.shelfContentContainer {
    padding: 20px 0px 0 0px;
}

.optimizedModelsPanel {
    user-select: none;
    padding: 0px 20px 0 20px;
    border: 1px solid var(--skanPlusGradientStart);
    border-top: initial;
    border-bottom: initial;

    .optimizedModelsBlockExpanded {
        color: var(--white);
        background-color: var(--success1Color);
        margin-left: auto;
    }

    .optimizedModelsBlockCollapsed {
        color: var(--success1Color);
        border: solid 2px var(--success1Color);
        background-color: var(--white);
        margin-left: auto;
    }

    .dateChangeRadioButtons {
        width: 400px;
        margin-left: 106px;
    }

    & .groupExplanation {
        margin-top: -5px;
        margin-bottom: 15px;
    }
}

.optimizedModelsSave {
    margin-top: 40px;
    height: 35px;
}

.optimizeModelsSaveButton {
    float: right;
}
