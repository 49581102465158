@import "../../components/Main.css";

.customSubHeaderText {
  max-width: 600px;
}

.page {
    padding: 30px 30px 84px 30px;
    color: var(--gray700);
    margin-bottom: 30px;

    & .subHeaderSeparator {
        width: 100%;
        height: 1px;
        background-color: var(--gray700);
        opacity: 0.2;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    & .explanationLink {
        margin-bottom: 22px;
      }
    & .textLink {
        margin: 20px 0 6px;
        font-size: 14px;
        font-weight: 600;
      }
    & .explanationText {
        font-size: 14px;
        line-height: 24px;
        & img {
            max-width: 100%;
          }
      }
    & .applovinOauthExplanation {
        margin: 20px 0 20px;
    }
    & .authButton {
        display: flex;
        justify-content: center;
        margin: 30px 0;
      }
    & .authedContainer {
        width: 100%;
        height: 60px;
        padding: 11px 11px 11px 20px;
        margin-bottom: 25px;
        background-color: var(--white);
        border: solid 1px var(--gray400);
        display: flex;
        justify-content: space-between;
        & .authedUser {
            display: flex;
            align-items: center;
          }
        & .checkedIcon  {
            margin-right: 10px;
          }
      }
    & .separator {
        width: 100%;
        height: 1px;
        margin: 26px 0 20px;
        background-color: var(--gray400);
      }
}