@import "../../global/theme.css";

.circularPlusButton {
    cursor: pointer;

    & circle {
        fill: var(--primaryButtonBackground);
    }

    &:hover:not(:active) circle {
        fill: var(--blue400);
    }

    &:active circle {
        fill: var(--blue500);
    }
}

.disabled {
    opacity: 0.3;
    fill: var(--gray600);
    stroke: var(--gray600);
    pointer-events: none;
}
