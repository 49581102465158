@import "../../../global/theme.css";

.clearShelfContainer {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 75px;

    .clearIcon {
        fill: var(--blue300);
        width: var(--icon-size-small);
        height: auto;
    }
}