@import "../../../global/theme.css";

.advancedRevenueToggleWrapper {
    & .advancedRevenueToggle {
        width: 195px !important;
        margin-top: -4px;
        margin-left: 10px;

        & label {
            font-size: 12px !important;
            font-weight: 400 !important;
            color: var(--gray700) !important;
        }
    }
}

/* This allows scrolling in the table */
.revenueTableWrapperOuter {
    position: relative;
    top: 10px;

    & .revenueTableWrapperInner {
        overflow-y: auto;
        max-height: 400px;
        user-select: none;

        & td.totalRevenueWide {
            width: 300px;
        }

        & td.tableHeader {
            position: relative;
            z-index: inherit;
        }

        & td.tableCellCenter {
            text-align: center;
        }

        & .tableHeader {
            color: var(--gray700);

            /* For overflow scrolling */
            position: sticky;
            top: 0;
            z-index: 2;
            height: 45px;
        }
    }
}

