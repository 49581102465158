@import "../../global/general.css";

.container {
  display: flex;
  height: 78px;
  align-items: center;
  margin-left: 60px;
  margin-right: 20px;
}

.buttonsArea {
  margin-left: auto;
}

.cancelButton {
  background-color: var(--gray500);
  border-color: var(--gray600);
  margin-right: 14px;
}

.lastModified {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px;
}

.created {
  opacity: 0.4;
  font-size: 14px;
  font-weight: 300;
}