@import "../../global/theme.css";

.container {
  height: 62px;
  background-color: var(--sidenavBackgroundColor);
  padding: 0 20px;
  justify-content: space-between;
  display: flex;
}

.headerText {
  padding-top: 18px;
  padding-right: 22px;
  font-size: 16px;
  color: var(--white);
  display: flex;
  cursor: pointer;
}
