@import "../../../global/theme.css";
@import "../../../components/Main.css";

.skanTimersTransitionBox {
    margin: 30px 0 11px 0;

    & .skanTimersTransitionTitleBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      }

    & .skanTimersTextTitle {
        font-size: 13px;
        font-weight: 600;
        padding-right: 5px;
        padding-bottom: 0;
      }

    & .questionMark {
        height: 15px;
      }

    & .skanTimersItemTitle {
        font-size: 14px;
        font-weight: 600;
      }

    & .skanTimersItemDescription {
        font-size: 13px;
        font-weight: 400;
        color: var(--gray700);
      }
}