@import "../../../../global/theme.css";

.interactionContainer {
    --iconSize: 15px;

    & .appField {
        margin-bottom: 20px;
    }

    & .osTitle {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: var(--gray600);
        font-weight: 600;
        margin-bottom: 5px;

        & .osIcon {
            width: var(--iconSize);
            fill: var(--gray600) !important;
            margin-right: 10px;
            & * {
                fill: var(--gray600) !important;
                mask: none !important;
                filter: none !important;
            }
        }
    }

    & .linkSettings {
        border-left: 1px solid var(--gray400);
        padding-top: 5px;
        padding-left: 12px;
        margin-left: calc(var(--iconSize) / 2);
        margin-bottom: 30px;

        & .formField {
            margin-bottom: 10px;
        }
    }
}
