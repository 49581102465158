@import '../../global/theme.css';

.trialStatusPanel {
    height: 36px;
    display: flex;
    align-items: center;
    cursor: default;

    & .upgradeButton {
        text-decoration: underline;
        color: var(--white) !important;
        height: 24px !important;
        font-weight: 700;
    }

    & .thankYouLabel {
        margin-left: 5px;
    }

    & .label {
        font-size: 13px;
        font-weight: 600;
        color: var(--white);
    }

    & .XIcon {
        width: 10px;
        position: absolute;
        right: 15px;
        fill: var(--white);
        cursor: pointer;
    }

    & .checkmark {
        fill: var(--turquoise200);
    }
}

