@import "../../../../../dashboard_react/src/global/theme.css";

.skeleton {
    width: 600px;
    height: 40px !important;
    margin-top: 8px !important;
    background-color: var(--gray100) !important;
    align-self: flex-start;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    & .sortSection {
        display: flex;
        align-items: center;
        gap: 4px;

        & .sortDirectionContainer {
            border-radius: 4px;
            border: 1px solid var(--gray400);
            cursor: pointer;
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;

            & .sortDirectionIcon {
                &.desc {
                    transform: rotate(180deg);
                }
            }
        }
    }
}