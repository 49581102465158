@import '../../../global/theme.css';

.dimensionPopover {
    margin-top: 8px;

    .dimensionPopoverContent {
        width: 280px;
        height: 385px;
        padding: 8px;
        overflow-y: hidden;
        background-color: var(--white);
    }

    .dimensionList {
        overflow-y: auto;
        height: calc(100% - 35px);
        padding: 0;

        .dimensionListSubheader {
            font-weight: 600;
            font-size: 12px;
            color: var(--gray600);
            background-color: var(--white);
            border-bottom: 1px solid var(--gray200);
            padding: 7px 5px;
        }

        .dimensionListItem {
            padding: 1px !important;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;

            &:hover {
                background-color: var(--blue100);
            }

            &.selected {
                pointer-events: none;
                cursor: default;
                background-color: var(--blue50);
            }

            .dimensionListItemText {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-left: 4px;

                .dimensionName {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--gray700);
                }

                .dimensionIcon {
                    --icon-color: var(--gray700); /* Default icon color */
                    stroke: var(--icon-color);
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}