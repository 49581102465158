@import "../../global/theme.css";
@import "../../global/general.css";

.container{
    display: flex;

    &.topSectorContainer {
        align-items: center;
        padding: 35px 35px 35px 20px;
    }

    &.fieldsGroupContainer {
        align-items: center;
        padding: 20px
    }

    & .textType[type="text"]{
        height: 36px;
        padding: 6px 10px;
        font-size: 16px;
        color: var(--gray700);
        border: 1px solid var(--gray400);
    }

    & .textType[type="text"]:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}
.label {
    box-sizing: border-box;
    padding: 4px 0 0 0;
    font-weight: 500;
    flex: 0 0 250px;
}

.tooltip {
    width: 13px;
    height: 13px;
    margin: 1px 0 0 8px;
    fill: var(--gray600);
    cursor: pointer;
}

.unifiedTooltip {
    width: 15px;
    height: 15px;
    margin: 1px 0 -3px 8px;
    fill: var(--gray700);
    cursor: pointer;
}

.skanPlusBorderDivider {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: linear-gradient(to right, #17a39f, #03c1ff);
    max-width: 1202px;
    height: 1px;
    margin: 22px 0 30px;

    & .skanPlusContent {
        background-color: var(--bodyBackgroundColor);
        padding: 0 20px;
        width: 240px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        & .skanPlusContentSpan {
            margin-bottom: 3px;
        }

        & svg {
            margin-bottom: 6px;
        }
    }
}

.borderDivider {
    text-align: center;
    width: 100%;
    color: var(--gray700);
    cursor: default;
    max-width: 1202px;
    border-bottom: 1px solid var(--gray400);
    height: 10px;
    margin-top: 22px;
    margin-bottom: 30px;

    & .borderDividerSpan {
        background-color: var(--bodyBackgroundColor);
        padding: 0 10px;
    }
}

.containerLabelTop {
    & .label {
        color: var(--generalLabelLight);
        font-weight: 300;
        font-size: 13px;
        margin-bottom: 7px;
    }
    & .groupContainer {
        max-width: 850px;
    }
    & .textType[type="text"]{
        height: 36px;
        padding: 6px 10px;
        font-size: 16px;
        color: var(--gray700);
        border: 1px solid var(--gray400);
        border-radius: 2px;
    }
}

.topSectorHolder {
    display: flex;
    align-items: center;
    padding: 0;
}

.topSectorText {
    margin-left: 10px;
    font-weight: 600;
    color: var(--gray800);
    font-size: 14px;
}

.fieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.singleGroup {
    &::first-child {
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
    }
    &::last-child {
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
    }
}

.tagContainer{
    max-width: 970px;
    flex-grow: 1;
}

.groupContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 975px;
    padding-left: 3px;
}

.groupContainer.filter {
    max-width: 800px;
}

.warningMessages{
    margin-top: 10px;
}

.unifiedReportOutdatedMessage {
    white-space: pre-line;
    margin: 10px 20px 10px;
}

.groupSubText {
    display: block;
    font-size: 12px;
    padding-top: 5px;
    padding-right: 10px;
}