@import "../global/theme.css";

:root {
    --list-item-border: 1px solid var(--bodyBackgroundColor);
    --content-background: var(--white);
    --content-unvisitied-background: var(--gray100);
}

.homePageLayout {
    display: flex;
    max-width: 82%;
    height: 88vh;
    min-height: 800px;

    & .homePageMenu {
        background-color: var(--content-unvisitied-background);
        min-width: 300px;
        width: 25%;
    }

    & .homePageMenuList {
        padding: 0;
        overflow-y: scroll;

        & .categoryListItem {
            border-bottom: var(--list-item-border);
        }

        & .categoryListItem {
            background-color: var(--content-unvisitied-background);
            cursor: pointer;
            height: 75px;

            &.completed {
                background-color: var(--content-background);
            }

            & .collapseExpand {
                width: 15px;

                & .collapseIcon {
                    overflow: visible;
                }

                & .expandIcon {
                    overflow: visible;
                }
            }

            & .categoryListItemText {
                margin-left: 10px;
                font-size: 16px;
                font-weight: 600;
                color: var(--gray800)
            }

        }

        & .listItem {
            height: 45px;
            border-bottom: var(--list-item-border);
            background-color: var(--content-unvisitied-background);

            & .hoverIndicator {
                display: none;
                position: absolute;
                right: 15px;
                top: 13px;
                width: 15px;
                height: 15px;
                fill: var(--blue300);
                transform: rotate(135deg);
            }

            &:hover {
                background-color: var(--blue100);

                & .hoverIndicator {
                    display: block;
                }
            }

            &.completed {
                background-color: var(--content-background);

                &:hover {
                    background-color: var(--blue100);
                }
            }

            & .lockIconWrapper {
                position: absolute;
            }

            & .lockIcon {
                height: 13px;
                fill: var(--gray700)
            }

            & .listItemText {
                margin-left: 25px;
                font-size: 13px;
                font-weight: 600;
                color: var(--gray700);
            }

            &.selected {
                background-color: var(--blue100);
                box-shadow: inset 2px 0px 0px 0px var(--blue300);
            }

            & .badgeText {
                position: absolute;
                right: 40px;
                font-size: 11px;
                font-weight: 400;
                color: var(--secondaryDark)
            }
        }
    }

    & .homePageContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        background-color: var(--content-background);
        min-width: 740px;
        width: 75%;
        height: 100%;

        & .header {
            display: flex;
            padding: 20px 0;
            justify-content: space-between;
            border-bottom: 1px solid var(--gray200);
        }

        & .body {
            height: 420px;
            padding: 12px;
            line-height: 1.7;
            overflow-y: scroll;
            background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
            background-attachment: local, local, scroll, scroll;

            @media (min-width: 1900px) {
                font-size: 16px;
            }
        }

        & .footer {
            display: flex;
            justify-content: space-between;
        }


        & .mediaContainer {
            width: 100%;
            padding-top: 56.25%;
            height: 0;
            position: relative;

            & .media {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.errorIcon {
    width: 10px;
    margin-right: 20px;
}

.tooltip {
    font-size: 13px !important;
    margin: 0 auto;
    background-color: var(--white) !important;
    color: var(--gray600) !important;
    border: 1px solid var(--gray500);
}

.opaqueBackground {
    opacity: 1!important;
}

