@import "../../global/theme.css";

.button {
  width: 185px;
  height: 40px;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  &:hover {
    box-shadow: 0 0 2px 3px rgba(66, 133, 244, .3);
  }
  &:active {
    background-color: var(--gray200);
    box-shadow: 0 0 2px 3px var(--white);
  }
}

.googleIcon {
  width: 18px;
  height: 18px;
  margin: 11px 21px 11px 11px;
}
.btnText {
  color: rgba(0,0,0,0.54);
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: roboto, sans-serif!important;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
}
