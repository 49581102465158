@import "../../global/theme.css";

.codeTable {
    background-color: var(--gray50);
    flex-grow: 1;
    overflow: auto;

    & th {
        background-color: var(--gray300) !important;

        color: var(--gray700) !important;
        font-family: open-sans, sans-serif !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        height: 40px !important;
        vertical-align: middle !important;

        border-top: 0 !important;
        border-bottom: 0 !important;
        border-left: 0 !important;
        border-right: 1px solid var(--bodyBackgroundColor) !important;
    }

    & td {
        color: var(--gray800) !important;
        font-family: open-sans, sans-serif !important;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 40px !important;
        vertical-align: middle !important;

        padding-left: 10px !important;
        padding-right: 10px !important;

        border-top: 0 !important;
        border-left: 0 !important;
        border-bottom: 1px solid var(--bodyBackgroundColor) !important;
        border-right: 1px solid var(--bodyBackgroundColor) !important;
    }

    & td.disabled {
      background-color: var(--gray300) !important;
    }

    & tr:hover > td:not(.disabled) {
      background-color: var(--blue100) !important;
    }

    /* reset autocomplete dropdown */
    & :global(.autocompleteEditor) textarea,
    & :global(.autocompleteEditor) td,
    & :global(.autocompleteEditor) th {
        line-height: 22px !important;
        vertical-align: top !important;
    }

    & :global(.htAutocompleteArrow) {
        color: var(--gray600) !important;
    }
}

.changesSection {
    margin-bottom: 30px;
    margin-top: 30px;

    & .leftButton {
        margin-right: 15px;
    }
}

.schemaSelection {
    max-width: 830px;
    padding-right: 50px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.newCodeFormContainer {
    padding: 20px;
}

.formField {
    margin-bottom: 15px !important;
}

.formFieldContainer {
    margin: 10px 0;
}
