@import "../../global/theme.css";

.container {
  width: 100%;
}

.rowContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;

  &:hover {
    & .showOnHover {
      opacity: 1;
    }
  }
}
.rowContainer:last-child {
  margin-bottom: 0;
}

.valuesContainer{
  margin-left: 10px;
  flex: 9;
}
.xIcon{
  cursor: pointer;
  margin-left: 10px;
  margin-top: 13px;
  height: 11px;
  width: 11px;
  & path {
    fill: var(--gray600);
  }
  &:hover:not(.disabled) {
    & path {
      color: var(--red300);
      fill: var(--red300);
    }
  }
  &.disabled {
     opacity: 0.3;
     cursor: default;
   }
}

.prefixText {
  margin: 6px 40px 0 6px;
}

.plusIconContainer {
  margin-left: 10px;
  display: flex;
  filter: grayscale(1);
  margin-top: 5px;
  cursor: pointer;
  color: var(--gray600);
  fill: var(--gray600);
  &:hover:not(.disabled) {
    color: var(--gray800);
    fill: var(--gray800);
  }
  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.plusIcon {
    margin-top:  5px;
    height:  15px;
    width: 15px;
}

.valueTooltip {
  text-align: left;
  & .fieldDisplayName {
    font-weight: 600;
    margin-bottom: 5px;
  }
}