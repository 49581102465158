@import '../../../global/theme.css';

.creativeTags {
     --tableBorder: solid 1px var(--gray400);

    width: 50%;
    padding: 26px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .tagsTitle {
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--gray800);

        & .searchInput {
            width: 330px;
        }
    }

    & .tagsTableContainer {
        margin-bottom: 80px;
        overflow-y: auto;
        width: 100%;
        overflow-x: hidden;

        & .tagsTable {
            border: var(--tableBorder);
            table-layout: fixed;

            & .tagsTableHeader {
                position: sticky; /* make the table heads sticky */
                top: -1px; /* table head will be placed from the top of the table and sticks to it */
                z-index: 1;

                & .tagsTableHeaderCell {
                    color: var(--gray800);
                    font-size: 14px;
                    padding: 16px 10px;
                    font-weight: 600;
                }
            }

            & .tagsTableRowCell {
                color: var(--gray700);
                line-height: 0;
            }

            & .dimensionNameTableCell {
                font-size: 13px;
                padding: 8px 16px;
                font-weight: 600;
                line-height: 14px;
                vertical-align: top;
            }
        }

        & .tag {
            font-size: 13px;
            line-height: 18px;
        }

        & .warningMessage {
            padding: 8px 16px !important;
        }

        & .cellLoader {
            border-radius: 5px;
        }

        & .messageContainer {
            border: var(--tableBorder);
            color: var(--gray800);
            font-size: 14px;
            padding: 16px 10px;
        }
    }
}

.searchIcon {
    width: 24px !important;
    height: 24px !important;
}