@import '../global/theme.css';


.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:first-child) {
        margin-top: 20px;
    }
}

.searchBox {
    flex-grow: 1;
    max-width: 640px;
    border-radius: 2px;
    border: 1px solid var(--gray400);
    padding: 0 10px;
    outline: none;
}

.button {
    margin-left: 20px;
}

.segmentCounter {
    margin: 30px 0 10px;
    font-weight: 500;
}

.templateButton {
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}

.tableIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}