@import "../global/theme.css";

:global(.react-grid-item.react-grid-placeholder) {
    background-color: var(--blue100) !important;
    opacity: 0.9 !important;
}

:global(.react-draggable-dragging) {
    z-index: calc(var(--stickyZIndex) + 2) !important;
}

:global(.react-grid-layout) {
    margin-bottom: 30px; /* To make resizes easier */
}

.dashboardHeader {
    position: sticky;
    top: 0;
    z-index: calc(var(--stickyZIndex) + 1);
    padding: 10px 20px;
    background: white;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

    & .rightSection {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        & > * {
            margin-left: 10px;
        }
    }

    & .dashboardSelection {
        width: 480px;
        padding: 0 10px 0 20px;
    }

    & .actionButtons {
        display: flex;
        align-items: center;
        & svg {
            vertical-align: middle;
            cursor: pointer;
          }
    }

    & .copyDashboardIcon {
        margin-left: 13px;
    }

    & .deleteDashboardIcon {
        height: 18px;
        margin-left: 10px;
        & path {
            fill: var(--gray400);
        }
        &:hover {
            & path {
                fill: var(--red300);
            }
        }
    }

    & .togglePrivatePublic {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        & .toggleButton {
            margin-left: 15px;
        }

        & .eyeButton {
            display: inline-block;
            width: 19px;
            margin-right: 9px;
            cursor: pointer;

        }
        & .eyeButtonDisabled{
            opacity: 0.2;
            cursor: default;
        }

        & .toggleText {
            font-size: 14px;
            display: inline-block;
            color: var(--gray700);
        }
    }
}

.dashboardGridHeader {
    position: sticky;
    top: 55px;
    z-index: var(--stickyZIndex);
    background-color: var(--bodyBackgroundColor);
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

    & .datePickerWrapper {
        display: flex;
        align-items: center;
        height: 36px;
        margin-top: 5px;

        & .dashboardDatePicker {
            max-width: 830px;
            flex-grow: 1;
        }
    }
}

.gridWidget {
    display: flex;
    flex-direction: column;

    & :global(.react-resizable-handle)::after {
        width: 7px !important;
        height: 7px !important;
        border-right: 2px solid var(--gray700) !important;
        border-bottom: 2px solid var(--gray700) !important;
    }
}

.pivotChartWidget {
    padding: 10px;
}

.pageSeparator {
    border-bottom: 1px solid var(--secondaryDark);
    width: 100%;
    display: flex;
    margin-top: 35px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.2;
}

.secondTimePeriodContainer {
    position: relative;
    display: inline-block;
    font-weight: 300;
    margin-bottom: -1px;

    & > div {
        display: inline-block;
    }

    & .compareSupportWarning {
        bottom: -19px;
        left: 0;
        font-size: 10px;
        font-style: italic;
        color: var(--gray800);

        & svg {
            margin-left: 5px;
            margin-right: 3px;
            height: 12px;

            & path {
                fill: var(--blue300);
            }
        }
    }
}

.emptyState {
    margin-bottom: 25px;
}

.refreshDataTextContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: -5px;
}

.refreshDataContainer {
    background-color: var(--blue100);
    height: 64px;
    margin-bottom: 20px;
    padding: 21px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.refreshDataButtonContent {
    color: var(--blue300);
    font-weight: 600;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }

    & svg {
        color: var(--blue300) !important;
        fill: var(--blue300) !important;
        margin-right: 8px;
        margin-bottom: -5px;
        height: 20px;
    }
}

.dismissRefreshIcon {
    color: var(--blue300) !important;
    height: 20px !important;
    width: 20px !important;
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.creativesIcon {
    width: var(--icon-size-small);
    fill: var(--gray600);
    margin-top: -2px;
}