@import "../../../global/theme.css";

.container {
  background-color: var(--white);
  padding: 24px;
  margin-top: 8px;
  position: relative;
}

.metricsbar {
  width: 100%;
  overflow: auto;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 35px 0;
  border-bottom: 1px solid var(--gray300);
}

.noMetrics {
  border-bottom: none;
}

.metricButton{
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--gray500);
  margin-right: 28px;
  white-space: nowrap;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}

.metricButton.selected {
  color: var(--textColor);
  border-bottom-color: var(--primaryColor) !important;
  font-weight: 400;
}

.metricButton.disabled {
  cursor: default;
}

.metricButton:hover .metricButtonLabel {
  color: var(--textColor);
}
