@import "../global/theme.css";


.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 115px;
    width: 100%;

    & .description {
        width: 450px;
        height: 70px;
        text-align: center;
    }

    & .graph {
        margin-top: 20px;
        padding: 35px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    }
}
