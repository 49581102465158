@import "../../global/theme.css";

.container {
    position: relative;
    margin-bottom: 15px;

    & .fieldLabel {
        @apply --font-input-label;
        display: flex;
        align-items: center;

        &.mandatory {
            &::before {
                content:"* ";
                color: var(--red300);
                font-size: 14px;
                font-weight: 600;
                margin-right: 6px;
            }
        }

        &.error {
            color: var(--red300);
        }

        & .tooltipIcon {
            margin-left: 6px;
        }
    }

    & .fileField {
        display: flex;
        align-items: end;

        & .fileInput {
            display: none;
        }

        & .uploadButton {
            display: flex;
            align-items: center;
            color: var(--primaryColor);
            font-weight: 600;
            margin-right: 10px;
            margin-bottom: 0;
            cursor: pointer;

            & .uploadImage {
                margin-right: 5px;
                fill: var(--primaryColor);
            }

            &.disabled {
                cursor: default;
                color: var(--gray400);

                & .uploadImage {
                    fill: var(--gray400);
                }
            }
        }

        & .clearButton {
            color: var(--flatButtonText);
            text-decoration: underline;
            font-size: 13px;
            cursor: pointer;
        }
    }

    & .errorMessage {
        position: absolute;
        right: 0;
        font-size: 11px;
        font-weight: 400;
        color: var(--red300);
    }
}
