.eventsContainer {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 8px;
    cursor: no-drop;

    & .tabsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;

        & .tab {
            padding: 4px 8px;
            width: 16%;
            display: flex;
            justify-content: center;
            border: 1px solid #ddd;
            border-radius: 4px;
            color: var(--gray600);
            background-color: var(--gray100);
            white-space: nowrap;
            &.disabled {
                color: var(--gray500);
            }
        }

        & .expandButton {
            padding: 4px 8px;
            color: #666;
            cursor: pointer !important;
            font-weight: 600;
        }
    }
}