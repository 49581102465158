@import "../global/theme.css";

.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.appSummaryContent {
    font-size: 11px;
    margin: 2px 0;
}

.topArea {
    border-bottom: 1px solid var(--gray400);
}

.mainArea {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.leftArea {
    flex-shrink: 0;
    width: 400px;
    background-color: var(--white);
    border-right: 1px solid var(--gray400);
    overflow: auto;
}

.rightArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 40px 26px 40px;
    overflow: auto;
}

.rightAreaTop {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 26px;

    & .actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-top: 15px;

        & .toggleLinks {
            margin-right: 20px;
            width: 200px;
        }
    }
}

.expandableOverrideContainer {
    background-color: transparent;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.expandableOverrideContent {
    background-color: transparent;
    padding: 0;
}

.expandableOverrideHeader {
    height: 60px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}

.expandableOverrideHeaderExpanded {
    box-shadow: none;
    border: 1px solid #e0e0e0;
    border-bottom: 0.5px solid #e0e0e0 !important;
    border-radius: 6px 6px 0px 0px;
}

.expandableOverrideArrow {
    display: none;
}

.expandableOverrideExternal {
    margin-right: 10px;
}
