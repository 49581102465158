@import "../../components/Main.css";

.tableContainer {
    width: 640px;
    margin: 0 auto 90px;
}

.accountsTableContainer {
    display: flex;
    position: static;
    flex-direction: column;
    align-items: center;
    & .loadingText {
        color: var(--gray700);
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
      }
    & .loaderContainer {
        margin-top: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

}

.noAccountsContainer{
    margin-top: 100px;
}

.table {
    border: 1px solid var(--gray400);
}

.tableHeader {
    padding: 2px 10px;
    position: sticky;
    top: 69px;
    z-index: 2;
    box-shadow: 0 1px 0 0 var(--gray300);
}
.tableFiltersHeader {
    position: sticky;
    top: 101px;
    z-index: 1;
    box-shadow: 0 3px 5px -4px var(--gray400);

}

.toggleRowContainer {
    display: flex;
    width: 640px;
    margin: 0 auto;
    padding: 25px 0 14px;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: var(--bodyBackgroundColor);
    & .accountsHeader {
        font-size: 15px;
        font-weight: 600;
      }
}