@import "../../../global/theme.css";

:root {
    --snackbarCloseIconSize: 9px;
}
.snackbarContainer {
  width: 337px;
  padding: 10px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  border-color: var(--gray400);
  background-color: var(--gray200);

  & .snackbarInnerContainer {
    padding: 10px;
    width: calc(100% - var(--snackbarCloseIconSize));
  }
}

.snackbarTitle {
    display: flex;
    align-items: center;
}

.snackbarText {
  margin-left: 26px;
}

.closeIcon {
  cursor: pointer;
  height: var(--snackbarCloseIconSize);
  width: var(--snackbarCloseIconSize);
  fill: var(--gray700);
}

.snackbarContainerSuccess {
  border-color: var(--green200);
  background-color: var(--green100);

    & .snackbarIcon {
      fill: var(--green300);
      padding-right: 5px;
    }

    & .snackbarTitle {
      color: var(--green300);
      font-size: 16px;
      font-weight: 600;
    }
}

.snackbarContainerError {
  border-color: var(--red200);
  background-color: var(--red100);

    & .snackbarIcon {
      fill: var(--red300);
      padding-right: 5px;
    }

    & .snackbarTitle {
      color: var(--red300);
      font-size: 16px;
      font-weight: 600;
    }
}