@import "../global/theme.css";

.shelfContainer {
    padding: 5px 20px;

    & .tooltip {
        width: 13px;
        height: 13px;
        fill: var(--gray600);
        cursor: pointer;
        top: 2px;
        position: relative;
        left: -2px;
    }

    & .sectorBreak {
        width: 100%;
        height: 28px;
        float: left;
    }

    & .topSectorContainer {
        width: 100%;
        float: left;
    }

    & .topSectorDataContainer {
        width: 50%;
        float: left;

        & .fieldLabel {
            display: block;
        }
    }

    & .topSectorPreviewContainer {
        float: right;
        padding-top: 20px;
        padding-right: 15px;
        user-select: none;

        & .previewLabel {
            display: block;
            text-align: center;
            font-size: 13px;
        }
    }

    & .fieldContainer {
        margin-top: 10px;
        margin-bottom: 10px;

        & .dragMessage {
            color: var(--gray400);
            font-style: italic;
            user-select: none;
        }

        & .fieldLabel {
            padding: 10px 0 5px 0;
            font-size: 13px;
            margin-right: 10px;
        }

        & .fieldLabelRequired::before {
            content: "*\A0";
            white-space: nowrap;
            color: var(--red300);
        }

        & .fieldLabelComment {
            font-style: italic;
            color: var(--gray400);
        }
    }
}