@import "../../components/Main.css";
@import '../../global/theme.css';

.page {
    color: var(--gray700);
    height: 100%;
}

.content {
    margin: 27px;
    height: 100%;
}

/*data availability*/

.shelfTitle{
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 10px;
}

.description{
    margin-bottom: 30px;
}

.dataSection{
    padding-top: 20px;
    background-color: var(--white);
    border: solid 1px var(--gray300);
}

.tableContainer{
    max-height: 50px;
    padding: 0 10px 10px 10px;
    overflow-y: scroll;
}

.thead {
    position: sticky;
    font-weight: bold;
    border-top: solid 1px var(--gray300);
    height: 37px;
}

.tbody{
    text-align: center;
}

.monthDiv {
    display: flex;
    margin: 0 auto 0 auto;
    max-width: fit-content;
    padding-bottom: 20px;
}

.DALoader {
    top: 15%
}

/*data refresh*/

.rightSide {
    float: right;
}

.boldFont {
    font-weight: bold;
}

.borderDivider {
  text-align: center;
  width: 100%;
  color: var(--gray700);
  cursor: default;
  max-width: 1202px;
  border-bottom: 1px solid var(--gray400);
  height: 10px;
  margin-top: 22px;
  margin-bottom: 30px;
}

.container {
  display: flex;
  flex-direction: column;
}

.radioLabel {
    @apply --font-input;
    display: block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 2px;
    margin-top: 2px;
    color: var(--gray600);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radioSpan {
    margin-left: 10px;
}

.error{
    color: var(--red300)
}

.checkmark {
    top: 2px;
    left: 3px;
}

.tableContainer{
    max-height: 50px;
    padding: 0 10px 10px 10px;
    overflow-y: scroll;
}

.thead {
    position: sticky;
}

.tbody{
    text-align: center;
}

.warningMessage{
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    z-index: 1;
}

.warningMessage2{
    position: absolute;
    top: 105px;
    right: 0;
    left: 0;
    z-index: 1;
}

.tableRow {
    height: 50px;
}
