@import "../../global/theme.css";

.sitesTable {
    height: 100%;
    overflow: auto;
    margin-top: 20px;

    & .tableHeader {
        position: sticky; /* make the table heads sticky */
        top: -1px; /* table head will be placed from the top of the table and sticks to it */
        z-index: 1;
    }

    & .tableCell {
        height: 65px;
    }

    & .osAndDateCell {
        min-width: 80px;

        & .osIcon {
            fill: var(--gray500);
            width: var(--icon-size-small);
            height: auto;
        }
    }

    & .appNameCell {
        max-width: 300px;
        overflow-wrap: break-word;
    }

    & .appImageCell {
        width: 40px;
    }

    & .appImage {
        height: 30px;
        margin: 0 5px;
    }

    & .title {
        font-weight: bold;
    }

    & .disabledTitle {
        color: lightgrey;
    }

    & .title, .disabledTitle, .subText {
        cursor: default;
    }

    & .tableCellContainer {
        text-align: center;
        min-width: 80px;
        cursor: default;
    }

    & .postbacksCellContainer {
        display: flex;
        text-align: center;
    }
}