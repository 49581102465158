.container {
    width: 100%;
    background-color: var(--white);
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(60px, max-content);
    grid-column-gap: 20px;
    padding: 10px 0 0;
}

.filterContainer {
    margin-bottom: 20px;
}