@import "../../global/theme.css";

.container {
    border: 1px solid var(--gray400);
    background-color: var(--white);
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    min-width: 90px;
    height: 35px;
    cursor: pointer;
    user-select: none;

    &.clicked {
        background-color: var(--blue100);
        border-color: var(--blue300) !important;
    }

    &:not(.disabled):hover {
        background-color: var(--blue150);
        border-color: var(--blue300) !important;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: var(--gray500);
        border: 1px solid var(--gray400);
        background: var(--bodyBackgroundColor);

        & .icon {
            fill: var(--gray500) !important;
        }
    }

    .textContainer {
        font-size: 13px;
        padding: 20px 15px;
        display: flex;
        align-items: center;
        height: 100%;

        & .icon {
            margin-right: 9px;
        }
    }

    .expandContainer {
        border-left: 1px solid;
        border-color: inherit;
        fill: var(--blue100);
        display: flex;
        padding: 0 6px;
        align-items: center;
        height: 100%;

        & .icon {
            fill: var(--blue300);
            width: 13px;
            margin: 0 6px;
            &.top {
                transform: rotate(-180deg);
            }
        }
    }
}