@import "../global/theme.css";

:root {
  --fieldWidth: 750px;
  --fieldWidthMax: 80%;
}

.fieldGroupRow {
  margin-bottom: 20px;
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
  height: 64px;
}

.fieldGroupRow:first-child {
  margin-top: 20px;
}

:global(.Select-arrow-zone), :global(.Select-clear-zone) {
  display: none;
}

:global(.Select-control) {
  border-color: var(--gray400);
}

.generateLinkContainer{
  display: block;
  height: 36px;
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
}

.generateLinkWrapper {
  display: inline;
  float: left;
}

.generateLinkSpinner {
  height: 35px;
  & svg {
    height: 80%;
  }
}

.separator{
  height: 1px;
  margin: 20px 0;
}

.linkWrapper {
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
}

.linkBox{
  display: flex;
  flex-direction: row;
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
  margin-top: 10px;
  padding-top: 6px;
  height: 100px;
}

.link {
  resize: none;
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid var(--gray400);
  padding: 4px 8px;
  color: var(--gray700);
}

.copyButtonWrapper {
  margin-bottom: 7px;
  width: var(--fieldWidth);
  max-width: var(--fieldWidthMax);
}

.copyButton{
  cursor: pointer;
  color: var(--primaryColor);
  &:hover {
    text-decoration: underline;
    color: var(--blue300);
  }
  float: right;
  font-size: 14px;
}

.openInstructionsWrapper {
  margin: 20px 0;
}

.openInstructions {
  font-size: 14px;
  cursor: pointer;
  color: var(--primaryColor);
  &:hover {
    text-decoration: underline;
    color: var(--blue300);
  }
  margin: 20px 0;
}

.button{
  background-color: var(--blue400) !important;;
  border: none !important;
}

.generateLinkButton {
  background-color: var(--blue300) !important;
  width: 134px;
  border-radius: 2px;
  border: 1px solid var(--blue300);
}


.fullLinkWrapper {
  color: var(--textColorLight);
  margin-bottom: 20px;
}

.attributesTableWrapper {
  margin: 20px 0;
}

.toggleLinks {
  margin-right: 20px;
  width: 300px;
}
