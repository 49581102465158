@import "../global/theme.css";

.ribbon {
  width: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.header {
  font-size: 18px;
  font-weight: 200;
}

.uploadBody {
  font-weight: 200;
}

.uploadBodySection {
    padding-bottom: 10px;
  }
