.progressBarContainer {
        --bar-height: 20px;
        --container-color: var(--gray400);
        --fill-color: var(--blue300);
        --fill-width: 50%;
        --bar-width: 100%;
        --legend-transform-percent: 50%;
        --legend-top: -8px;

        height: var(--bar-height);
        width: var(--bar-width);
        background-color: var(--container-color);
        position: relative;
        border-radius: 30px;
        &.withLegend {
                margin-top: calc(-1* var(--legend-top));
        }

        .filler {
                height: 100%;
                width: var(--fill-width);
                border-radius: inherit;
                background-color: var(--fill-color);

                .legend {
                        position: absolute;
                        display: flex;
                        justify-content: flex-end;
                        width: var(--fill-width);
                        color: var(--fill-color);
                        top: var(--legend-top);

                        .legendText {
                                transform: translateX(var(--legend-transform-percent));
                        }

                }
        }

        .rangeBar {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
        }

}
