@import "../../global/theme.css";
@import "../../components/Main.css";

.container {
    height: 100%;
    overflow: auto;
}

.page {
    padding: 30px;
    color: var(--gray700);
}

.topSeparator {
    width: 100%;
    height: 1px;
    background-color: var(--gray400);
    margin: 30px 0 25px 0;
}

.shelfHeaderTitle {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: var(--green400);
    margin-bottom: 5px;
}

.shelfInfoBox {
    margin-bottom: 20px;

    & .slotsArea {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.noSliderSector {
            align-items: end;
        }

        & .measurementSlider {
            width: calc(100% - 250px);
            margin-right: 20px;

            &.noSliderSector {
                text-align: right;
            }

            & .sliderMarkLabel {
                top: 25px;
                color: var(--gray600);
                font-size: 11px;
                font-weight: 400;
            }
        }
    }
}

.shelfInfoBoxText {
    font-size: 13px;
    line-height: 1.43;
    color: var(--gray700);
}

.shelfInfoBoxTitle {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: var(--gray700);
    margin-bottom: 5px;
}

.shelfWarningMessage {
    font-size: 13px !important;
    color: var(--black);
    padding: 10px;
}

.shelfInputBox {
    margin-bottom: 10px;
}

.shelfInput {
    width: 100%;

    & .labelFormatting {
        position: relative;
        left: 7px;
        top: -27px;
        width: 10px
    }
}

.skan4TransitionArea {
    & .skan4TransitionTitle {
        display: flex;
        flex-direction: row;
    }
}


.coarseMappingTableWrapper {
    position: relative;
    margin-top: 30px;

    & td.tableCellCenter {
        text-align: center;
    }

    & .conversionValueCell {
        width: 15%;
    }

    & .eventsCellWide {
        width: 60%;
        white-space: pre-line;
        line-height: 2;
        padding: 5px 0px;
    }

    & .eventsCell {
        width: 35%;
        white-space: pre-line;
        line-height: 2;
        padding: 5px 0px;
    }

    & .totalRevenue {
        width: 30%;
    }
}

td.tableHeader {
    position: relative;
    z-index: inherit;
}

.tableHeader {
    color: var(--gray700);

    /* For overflow scrolling */
    position: sticky;
    top: 0;
    z-index: 2;
    height: 45px;
}

.modelError {
    color: var(--red300);
    font-size: 12px;
    margin-top: 10px;
}