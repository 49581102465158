@import "../global/theme.css";

.mtaContainer {
    margin-left: 45px;
    width: 1200px;

    & .formContainer {
        display: flex;
        border: 1px solid var(--gray400);
        background-color: var(--white);
    }

    & .pageHeader {
        padding: 25px 0 25px 0;
        font-size: 24px;
        font-weight: 600;
    }

    & .formArea {
        display: flex;
        flex-direction: column;
        padding: 20px 0 100px 40px;

        & .reportTitle {
            font-size: 18px;
            font-weight: 600;
        }

        & .learnMore {
            margin-top: -20px;
            margin-bottom: 10px;
        }
        & .datepicker {
            display: flex;
            justify-content:flex-start;
        }

        & .datepickerContainer {
            width: 230px;
        }

        & .fieldErr {
            color: red;
        }

        & .fieldsLabels {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 600;
        }

        & .fieldsErrors {
            position: static;
        }

        & .lookbackWindowLabel, & .lookbackWindowValue {
            font-size: 14px;
            padding: 6px 0 6px 0;
        }

        & .lookbackWindowLabel {
            margin-left: 30px;
            margin-right: 2px;
        }

        & .lookbackWindowValue {
            font-weight: bold;
        }

        & .submitButtons {
            width: 480px;
            margin-top: 20px;
            text-align: right;
        }
    }
}