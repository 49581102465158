@import "../../../global/theme.css";

.timePeriodContainer {
    display: flex;
    align-items: center;
    margin: 25px 0 15px 0;

    &.warningMessage {
        margin-bottom: 10px;
    }

    & .buttonContainer {
        border-radius: 5px;
        border: solid 1px var(--gray400);
    }

    & .compare {
        margin-left: 10px;
        color: var(--blue300);
        font-weight: 600;
        cursor: pointer;
    }

    & .compareLabel {
        margin-right: 10px;
    }
}

.compareWarningMessage {
    margin-bottom: 10px;
}