@import '../global/theme.css';

.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.topArea {
    border-bottom: 1px solid var(--gray400);
}

.mainArea {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.leftArea {
    flex-shrink: 0;
    width: 400px;
    background-color: var(--white);
    border-right: 1px solid var(--gray400);
    overflow: auto;
}

.rightArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0 26px 0;
    overflow: auto;
}

.rightAreaTop {
    margin-top: 26px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 40px 24px;

    & .mainText {
        flex: 1;
        margin-right: 30px;
        margin-bottom: 10px;
    }

    & .addModelButton {
        margin-bottom: 24px;
    }
}

.toggleOldLinks {
    margin-left: auto;
    display: flex;
    margin-right: 20px;
}

.skanPlusIcon {
    width: 35px;
    margin-right: 15px;
}

.expandableOverrideContainer {
    background-color: transparent;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.expandableOverrideContent {
    background-color: transparent;
    padding: 0;
}

.expandableOverrideHeader {
    border-bottom: none !important;
    height: 60px;
}

.expandableOverrideHeaderExpanded {
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.2);
}

.expandableOverrideArrow {
    display: none;
}

.expandableOverrideExternal {
    margin-right: 10px;
}

.rightAreaMain {
    height: 100%;
    overflow-y: auto;
    min-width: 650px; /* To prevent nasty overflow cutting bugs */
    padding: 0 40px;
}
