@import "../../../global/theme.css";

.bannerFormContainer {
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin: 0 30px;

    & .bannerPreviewContainer {
        margin: 35px;
        position: relative;
        height: fit-content;

        & .bannerPreview {
            --previewFrameSize: 52px;

            position: absolute;
            width: 100%;
            height: calc(100% - 2 * var(--previewFrameSize));
            top: var(--previewFrameSize);
        }
    }

    & .bannerForm {
        padding-right: 20px;
        padding-top: 30px;
    }
}
