@import "../../global/theme.css";

.midContainer {
  background-color: var(--white);
  padding: 0 24px 10px 24px;
  margin-top: 8px;
  position: relative;
}

.tooltipContainer {
  font-size: 12px;
  background-color: var(--white);
  box-shadow: -2px 2px 12px 0 rgba(68,75,87,0.25);
  color: var(--gray700);
  position: relative;
  z-index: 10;
}

/*
.tooltipContainer:before {
  content: '';
  position: absolute;
  right: -5px;
  top: 50px;
  height: 10px;
  width: 10px;
  background-color: var(--white);
  transform: rotate(45deg);
}
*/

.tooltipHeader {
  padding: 0 8px;
  color: var(--white);
  background-color: #6B6F78;
  height: 28px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.tooltipItemColor{
  width: 8px;
  height: 8px;
  margin-right: 7px;
}

.tableContainer {
  padding: 8px 10px;
}

.tooltipItem{
  height: 24px;
  font-size: 12px;
  font-weight: 300;
  color: var(--gray700);
}

.tooltipItemName{
  min-width: 100px;
  margin-right: 30px;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  & span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.tooltipEnding {
      min-width: fit-content;
    }
  }
}

.legendItem {
  display: flex;
  align-items: center;
}

.tooltipValue {
  margin-right: 16px;
  font-weight: 500;
}

.tooltipValueHeader{
  font-weight: bold;
}

.tooltipItem td:last-child > .tooltipValue {
  margin-right: 0;
}

.legendColor {
  height: 4px;
  width: 8px;
  margin-right: 10px;
}

:global(.highcharts-legend-item-hidden) .legendColor {
  opacity: 0.3;
}

.legendText{
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spacer {
  width: 16px;
}

.topbarContainer{
  display: flex;
  align-items: center;
  color: var(--textColor);
  margin-bottom: 20px;
}

.metricButton{
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--gray500);
  margin-right: 28px;
  white-space: nowrap;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}

.metricButtonIcon {
  height: 16px;
  width: 16px;
  opacity: 0;
  margin-bottom: 4px;
}

.metricButton:hover .metricButtonLabel {
  color: var(--textColor);
}

.metricButton:hover .metricButtonIcon {
  opacity: 1;
  fill: var(--gray300);
}

.metricButtonIcon:hover svg {
  fill: var(--gray300);
}

.metricButton:last-child {
  border-right: none;
  margin-right: 0;
}

.metricButton.selected {
  color: var(--textColor);
  border-bottom-color: var(--primaryColor) !important;
  font-weight: 400;
}

.metricButton.disabled {
  cursor: default;
}

.metricButton.disabled .metricButtonLabel{
  opacity: 0.3;
}

.metricButtonBorder{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.metricsBarPlaceholder {
  height: 161px;
}
.metricsbar{
  width: 100%;
  overflow: auto;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 35px 0;
  border-bottom: 1px solid var(--gray300);
}

.noMetrics {
  border-bottom: none;
}

.topbarItems{
  white-space: nowrap;
  vertical-align: middle;
  height: 30px;
  margin-left: auto;
}

.topbarTitle {
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  color: var(--gray700);
}

.numberOfLinesContainer{
  font-size: 14px;
  font-weight: 400;
  color: var(--gray700);
  display: inline-flex;
  align-items: center;
  height: 100%;
  vertical-align: middle;
}

.numberOfLinesLabel{
  margin-right: 10px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 300;
}


.numberOfLinesDropdown{
  width: 60px !important;
  left: 0 !important;
}

.numberOfLinesInput{
  width: 35px;
  border-radius: 3px;
  border: 1px solid var(--gray400);
  -moz-appearance:textfield;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

.numberOfLinesInput::-webkit-inner-spin-button,
.numberOfLinesInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.spacerVertical{
  display: inline-block;
  height: 24px;
  width: 1px;
  margin: 0 10px;
  background-color: var(--gray300);
  vertical-align: middle;
}

.noDataContainer{
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  flex-direction: column;
  color: var(--textColorLight);
}

.noDataImage {
  width: 250px;
  height: auto;
  margin-bottom: 10px;
  margin-left: 10px;
}

.noDataLine {
  width: 250px;
  height: 1px;
  background-color: var(--gray300);
  position: relative;
  top: -10px;
  left: 5px;
}

.highcartsContainer{
  position: relative;
}

.xLabelHolder, .xLabelItem {
  display: flex;
  text-align: center;
}

.xLabelHolder.partial {
  opacity: 0.5;
}