@import "../../global/theme.css";
@import "../../components/Main.css";

.topArea {
    display: flex;
    align-items: flex-start;
    padding-right: 10px;

    & .measurementContainer {
        display: flex;
        flex-direction: column;
        flex: 1;

        & .measurementSlider {
            margin-right: 50px;

            & .disabledSlider {
                pointer-events: none;

                & :global(.MuiTooltip-popper) {
                    display: none;
                }
            }

            & .oneDaySlider {
                pointer-events: none !important;
                width: 0 !important;
                top: -32px !important;
                margin-bottom: -32px !important;
            }

            & .twoDaySlider {
                width: 16.67% !important;
                top: -32px !important;
                margin-bottom: -32px !important;
            }

            & .fourDaySlider {
                width: 50% !important;
                top: -32px !important;
                margin-bottom: -32px !important;
            }

            & .sliderMarkLabel {
                top: 26px;
                color: var(--gray600);
                font-size: 11px;
                font-weight: 400;

                &:nth-child(5) {
                    /* 1 day - fix label position */
                    padding-left: 34px;
                }

                &:nth-child(9) {
                    /* 7 days - fix label position */
                    padding-right: 34px;
                }
            }
        }
    }

    & .topAreaActionsLabel {
        margin-right: 28px;
        & > * {
            margin: 0;
            font-weight: 600;
        }
    }

    & .usedSlotsContainer {
        padding-top: 4px;
    }
}

.measurementPeriodDisabledTooltip {
    width: 300px;
}
