@import '../../global/theme.css';
@import '../../global/general.css';


:root {
    --header-height: 70px;
    --footer-height: 70px;
    --medium-shelf: 700px;
    --large-shelf: 816px;
    --xlarge-shelf: 1024px;
}


.shelfBody {
    background-color: var(--bodyBackgroundColor) !important;
    margin: 0;

    &.enterAnimationDisabled {
        transition-property: transform, width !important;
    }

    &.xlargeShelf {
        width: var(--xlarge-shelf);
    }

    &.largeShelf {
        width: var(--large-shelf);
    }

    &.mediumShelf {
        width: var(--medium-shelf);
    }
}

.headerContainer,
.footerContainer {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

.outsideZone {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1400;

    &.mediumShelf {
        right: var(--medium-shelf);
    }

    &.largeShelf {
        width: var(--large-shelf);
    }

    &.xlargeShelf {
        width: var(--xlarge-shelf);
    }
}

.headerContainer {
    padding: 20px 30px;
    height: var(--header-height);
    background-color: var(--blue600);
    color: var(--white);
    font-size: 24px;
    font-weight: 300;
    top: 0;
    display: flex;
    align-items: center;
    line-height: 30px;

    & .headerText {
        width: calc(100% - 35px);
    }

    & .headerImageContainer {
        position: relative;

        & .headerImage {
            height: 36px;
            border-radius: 5px;
            background-color: var(--white);
            margin-right: 18px;
        }

        & .statusCircle {
            height: 14px;
            width: 14px;
            position: absolute;
            bottom: -3px;
            right: 11px;
            border-radius: 50%;
            border: 1px solid var(--gray700)
        }
    }

    & .closeButton {
        margin-left: auto;
        width: 12px;
        fill: var(--white);
        cursor: pointer;
    }
}

.contentContainer {
    height: calc(100% - var(--footer-height) - var(--header-height) - 5px);
    overflow: auto;

    &.hasFooter {
        margin-bottom: var(--footer-height);
    }

    &.padding > div {
        padding: 20px 30px;
    }
}

.footerContainer {
    background-color: var(--gray300);
    bottom: -1px;

    & > div {
        padding: 17px 20px;
        height: var(--footer-height);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & button {
            margin-left: 10px;
        }

        & .linkButton {
            background: none;
            border: none;
            width: 78px;
            font-size: 16px;
            font-weight: 500;
            color: var(--gray700);
        }
    }
}
