@import "../../global/theme.css";
@import "./Shelf.css";

.headerContainer {
    padding: 20px 30px;
    height: var(--header-height);
    background-color: var(--gray700);
    color: var(--white);
    font-size: 24px;
    font-weight: 300;
    top: 0;
    display: flex;
    align-items: center;
    line-height: 28px;
    & .closeButton {
        margin-left: auto;
        width: 12px;
        fill: var(--white);
        cursor: pointer;
    }
}