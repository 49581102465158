@import "../../global/theme.css";

.topArea {
    display: flex;
    align-items: center;
    height: 58px;
    padding: 0 16px 0 11px;
    background-color: var(--white);

    & .appSearch {
        width: 388px;
    }
}

