@import "../../global/theme.css";

.container {
    display: flex;
    min-width: 420px;
    max-width: 587px;
    flex: 1 1 0;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--gray400);
    border-radius: 16px;
    color: var(--gray700);
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.divider {
    width: 100%;
    margin: 24px 0;
    height: 1px;
    border-bottom: 1px solid var(--gray400);
}

.title {
    color: var(--gray800);
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
}

.tierBadge {
    border-radius: 80px;
    font-weight: 600;
    font-size: 16px;
    padding: 4px 16px;

    &.free {
        border: 1px solid var(--turquoise100);
        color: var(--teal300);
        background: var(--teal100);
    }

    &.standard_unified {
        border: 1px solid var(--blue300);
        color: var(--blue300);
        background: var(--blue100);
    }
    &.enterprise {
        border: 1px solid var(--indigo);
        color: var(--indigo);
        background: var(--marketing-gradient);
    }
}

.capInfoContainer {
    width: 100%;
    height: 50%;
}

.capInfoBox {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    & .capInfoIcon {
        width: 32px;
    }

    & .capInfoDetails {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        & .cap, & .endDate, & .planPrice {
            color: var(--gray800);
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;

        }

        & .capInfoText {
            font-size: 14px;
            font-weight: 400;
            line-height: 38px;
            display: flex;
            align-items: center;
        }
   }
}

.moreInfo {
   display: flex;
   flex-direction: column;
   width: 100%;

    & .moreInfoTitle {
        margin-bottom: 24px;
    }

    & .localeInfo {
        display: flex;
        margin-bottom: 16px;
    }

    & .timezone, & .currency, & .startDate {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    & .currency {
        margin-left: 10px;
    }

    & .currencyIcon, & .timezoneIcon, & .startDateIcon {
        vertical-align: middle;
        margin-right: 8px;
    }

    & .timezoneIcon, & .startDateIcon {
        fill: var(--gray700)
    }

    & .currency {
        fill: var(--gray700);
    }
}

.totalNumber {
   font-size: 40px;
   line-height: 0.88;
   margin-top: 17px;
}


.progressbarWrapper {
   margin: 0 auto;
   width: 35%;
}

.timeToContractEnd {
   line-height: 1.43;
   color: var(--gray700);

   & .daysLeft {
       font-weight: 600;
   }
}
