
.container {
    border: 1px solid #CCCCCC;
    width: 394px;
    border-radius: 8px;
    margin-top: 24px;

    & .gridContainer {
        display: flex;
        gap: 16px;
        width: 100%;
        padding: 16px;
        flex-direction: column;

        & .rowContainer {
            display: flex;
            gap: 16px;

            & .nameContainer {
                border: 1px solid #CCCCCC;
                border-radius: 2px;
                width: 252px;
                padding: 6px 8px;
                display: flex;
                align-items: center;
                gap: 8px;

                & .icon {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    margin-right: 8px;
                }
            }

            & .sizeContainer {
                border: 1px solid #CCCCCC;
                border-radius: 2px;
                width: 94px;
                display: flex;
                align-items: center;
                color: black;
                padding: 6px 8px;

                &.disabled {
                    background: var(--primaryDisabledLight) !important;
                    color: var(--primaryButtonTextDisabled) !important;
                }
            }

            & .networkHeader {
                width: 252px;
                text-align: left;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: var(--gray700);
            }
            & .sizeHeader {
                width: 94px;
                text-align: left;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: var(--gray700);
            }
        }
    }
}