@import "../global/theme.css";

.pageHeaderWrapper {
    background-color: white ;
    display: flex;
    justify-content: space-between;
}

.pageWrapper {
    overflow-x: initial;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--gray400);
    margin-top: -5px;
}

.subHeaderText {
    color: var(--gray700);
    font-size: 14px;
}

.search {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    & .searchInput {
        box-sizing: border-box;
        width: 600px;
    }
}

.pageHeader {
    padding-left: 30px;
    padding-top: 15px;
}

.alertTypeHeaderText {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin-top: 10px;
    margin-bottom: 6px;
    color: var(--gray700);
}

.emptyState {
    padding-top: 200px;
}
