@import "../../../../../global/theme.css";

.formAutoComplete {
    margin: 0 0 13px 0;
    width: 100%;
}

.formButton {
    width: 105px;
    justify-content: center;
    align-self: end;
}

.tooltip {
    margin-top: 3px;
}