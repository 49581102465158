@import '../../../global/theme.css';

:root {
    --dimensionsDropdownGap: 16px;
}

.collapsableSection {
    width: 100%;
    margin-top: 24px;
    padding: 2px;
    border-radius: 8px;
    border: solid 1px var(--purple100);
    box-sizing: border-box !important;
    background-image: linear-gradient(
        to right,
        #fdffff,
        rgba(234, 230, 255, 0.59) 50%,
        rgba(207, 216, 255, 0.18)
    );
}

.dimensionFilterSection {
    display: flex;
    height: fit-content;
    flex-direction: column;
    position: relative;
    padding: 16px;

    & .content {
        display: flex;
        align-items: center;
        gap: var(--dimensionsDropdownGap);
        flex-wrap: wrap;

        & .dimensionDropdown {
            min-width: 250px;
            max-width: 300px;
            width: calc(20% - (var(--dimensionsDropdownGap) * 2));

            &.addDimensionButton {
                font-weight: 600;
                height: 40px;
                display: flex;
                justify-content: space-between;
            }

            &.dimensionFilter {
                background-color: var(--white);
                cursor: pointer;
            }
        }
    }
}
