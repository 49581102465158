@import '../../../global/theme.css';

.headerWrapper {
    flex: 0 1 auto;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 24px;

    & .filters {
        display: flex;
        flex-direction: row;
        gap: 16px;

        & .input {
            min-width: 250px;
            max-width: 350px;
            min-height: 40px;
        }
    }

    & .downloadCsvContainer {
        border: 1px solid var(--gray400);
        width: 38px;
        height: 38px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        & .downloadCsvIcon {
            cursor: pointer;

            &.disabled {
                fill: var(--gray500);
                pointer-events: none;
            }
        }
    }
}

.tooltip {
    font-size: 11px !important;
    margin: 7px !important;
}