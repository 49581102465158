.planBanner {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 2px 24px;
    align-items: center;
    gap: 10px;
    border: 1px solid;
    border-radius: 8px;
    font-size: 14px;


    &.free {
        border-color: var(--turquoise100);
        background-color: var(--teal100);

        .planTitle {
            color: var(--teal300);
            font-weight: 600;
        }

        .planText {
            color: var(--gray700);
            font-weight: 400;
        }
    }
}