@import "../../../../global/theme.css";

.addEventButton {
    margin-bottom: 10px;
    font-weight: 400;
    color: var(--blue300);
    cursor: pointer;

    &.disabled {
        font-weight: 200;
        color: var(--gray600);
        cursor: default;
        pointer-events: none;
    }
}