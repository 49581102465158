@import "../global/theme.css";

.displayNone{
    display: none;
}

button:focus {
  outline: none !important;
}

.mutedText {
  color: var(--gray600);
}

.lineThroughText {
    text-decoration: line-through;
}

.boldFont {
  font-weight: 400;
}

.smoothOpenAnimationEnter {
  opacity: 0.01;
  transform: scaleY(0);
}

.smoothOpenAnimationEnter.smoothOpenAnimationEnterActive {
  opacity: 1;
  transform: scaleY(1);
  transition: all 300ms ease-in;
}

.smoothOpenAnimationLeave {
  opacity: 1;
  transform: scaleY(1);
}

.smoothOpenAnimationLeave.smoothOpenAnimationLeaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
  transform: scaleY(0);
}

:global(.tippy-popper){
    max-width: 500px !important;
}

:global(.tippy-tooltip.light-theme[data-animatefill]) {
  background-color: white !important;
}

:global(.tippy-popper[x-placement^=top-start] [x-arrow]) {
  left: 6px!important;
}

:global(.tippy-popper[x-placement^=top-end] [x-arrow]) {
  right: 6px!important;
}

:global(.tippy-tooltip--big) {
  padding: 10px;
  font-size: 12px;
  font-family: open-sans, sans-serif;
}

.buttonMarginLeft {
    margin-left: 10px;
}

.supTag {
    color: var(--pink100);
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
    padding-left: 5px;
    letter-spacing: 0.5px;
}

:global(.grecaptcha-badge) {
    visibility: hidden;
}

.anchorLink {
    color: var(--blue300);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
     }
}

code {
    background-color: var(--gray200);
    color: var(--gray700);
    &.codeBlock {
        font-weight: 600;
        display: block;
        padding: 10px;
        margin: 10px;
        word-wrap: break-word;
    }
}
