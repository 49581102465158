@import "../global/theme.css";


.athenaTable {
    & th, & td {
        min-width: 130px;
        white-space: nowrap;
    }

    & th {
        background: var(--blue300) !important;
        color: white !important;
    }

    & td {
        padding: 5px 10px !important;
    }
}