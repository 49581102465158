@import "../../global/theme.css";

.container {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--white);
}

.rightSide {
    margin-left: auto;
    & > * {
        margin-left: 10px;
    }
}