@import "../../global/theme.css";
@import "../../global/general.css";

.button {
    height: 50px;
    border-radius: 3px;
    border: solid 1px var(--gray400);
    background-color: var(--white);
    display: flex;
    align-items: center;
    padding: 0 15px;
    & .image {
        height: 30px;
      }
    & .label {
        font-size: 16px;
        font-weight: 600;
        color: var(--gray700);
        margin-left: 10px;
      }

    &:disabled {
        cursor: default;
        background-color: var(--gray100);
        & .label {
          color: var(--gray400);
        }
        & .image {
         opacity: 0.4;
        }
     }
}