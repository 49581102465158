.select {
    width: 208px;
    font-size: 14px !important;
}

.label {
    font-size: 14px !important;
}

.option {
    font-size: 14px !important;

     &:hover {
        cursor: pointer;
        background-color: var(--blue100) !important;
    };
}