@import "../../global/theme.css";

.container {
  background-color: var(--white);
  position: relative;
  &.expanded, &.hasFooter {
    & .headerContainer {
      border-bottom: 1px solid var(--gray300);
    }
  }
}

.headerStickinessContainer {
  top: 0;
  z-index: 3;
}

.sticky {
  position: sticky;
}

.headerContainer {
  height: 78px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  background-color: var(--white);
}

.contentArea {
  background-color: var(--white);
  padding-top: 20px;
}

.container.disabled {
  opacity: 0.7;

  & .arrow {
    opacity: 0;
  }
  & .headerContainer {
    cursor: default;
  }
}

.container.notExpandable {
  & .arrow {
    opacity: 0;
  }
  & .headerContainer {
    cursor: default;
  }

}


.arrowArea {
  margin-left: 26px;
  margin-right: 26px;
}

.arrow {
  border-left: 6px solid var(--gray600);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  width: 8px;
  height: 8px;
}

.externalHeaderArea {
  flex-grow: 1;
  margin-right: 30px;
}

.expanded .arrow {
  transform: rotate(90deg);
}

/*.footerContainer {*/
  /*margin-left: 60px;*/
  /*margin-right: 20px;*/
/*}*/

/*.contentContainer {*/
  /*margin-left: 60px;*/
  /*margin-right: 55px;*/
/*}*/

