@import "../../global/theme.css";

.tray {
    border-bottom: 1px solid var(--gray400);
    margin-top: 40px;
    margin-bottom: 20px;
    height: 30px;
}

.tab {
    color: var(--gray600);
    font-size: 14px;
    line-height: 18px;
    margin-right: 18px;
    cursor: pointer !important;
    display: inline-block;
    height: 100% !important;

    &.selected {
        color: #455154;
        border-bottom: 4px solid #4996e7 !important;
    }
}