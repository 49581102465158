.cardActions {
    float: right;
    padding: 8px 25px 16px 0 !important;
    & > * {
        margin: 0 !important;
        margin-left: 10px !important;
    }
}

.container {
    box-shadow: none !important;
}