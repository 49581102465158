.TableCellMultiIcons {
    display: flex;
    align-self: center;
    flex-direction: row;

    & .multiIcon {
        margin: 0 4px;
    }

    & .imageIcon {
        height: 30px;
        width: 30px;
        margin: 0 4px;
        border-radius: 4px;
    }
}
