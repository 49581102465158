@import "../../../global/theme.css";

.dimensionsPopover {
    border-radius: 6px;
    margin-left: 10px;
    padding: 10px;

    & .dimensionsPopoverContent {
        height: 260px;
        width: 400px;
        overflow: auto;
    }
}
