@import "../../global/theme.css";

.singularIcon {
  color: var(--gray700);
  transition: color 0.2s ease-in-out;

  /* Sizes */
  &.x-small {
    width: 16px;
    height: 16px;
  }

  &.small {
    width: 18px;
    height: 18px;
  }

  &.large {
    width: 24px;
    height: 24px;
  }

  /* Variants */
  &.dark {
    color: var(--gray700);

    &.interactive:hover {
      color: var(--gray800);
    }
  }

  &.light {
    color: var(--gray500);

    &.interactive:hover {
      color: var(--gray600);
    }
  }

  &.white {
    color: var(--white);
  }

  &.active {
    color: var(--blue300);

    &.interactive:hover {
      color: var(--blue400);
    }
  }

  &.success {
    color: var(--green300);

    &.interactive:hover {
      color: var(--green400);
    }
  }

  &.warning {
    color: var(--orange300);

    &.interactive:hover {
      color: var(--orange400);
    }
  }

  &.error {
    color: var(--red400);
  }

  /* States */
  &.disabled {
    cursor: not-allowed;
    color: var(--gray400);
    pointer-events: none;

    &:hover {
      color: var(--gray400);
    }
  }

  &.interactive {
    cursor: pointer;
  }
} 