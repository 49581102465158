@import "../../global/theme.css";

.container {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: var(--gray300);
  left: 0;
  right: 0;
  padding: 0 20px;
  z-index: 10;
  &.overflow {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
  }
}

.buttonsContainer {
  margin-left: auto;
}

.buttonHolder {
  margin-right: 10px;
  display: inline-block;
}

.buttonHolder:last-child {
  margin-right: 0;
}