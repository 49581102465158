@import "../../global/theme.css";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
}

.container.disabled, .button[disabled] .buttonInner {
    & .button {
        cursor: default;

        & .buttonIcon {
            fill: var(--gray600);
            stroke: var(--gray600);
        }
    }

    & .button:hover {
        color: var(--gray600);
    }

}

.button[disabled] {
    cursor: not-allowed;
}

.button {
    background: transparent;
    border: none;
    outline: none;
    color: var(--gray600);
    border-radius: 0;
    padding: 0 8px;
    position: relative;
    cursor: pointer;
}

.button:last-child {
    border-right: none;
    padding-right: 0;
}

.buttonWithLabel {
    padding-right: 20px;
}

.buttonIcon {
    padding-right: 5px;
}

.buttonIconWithLabel {
    margin-right: 3px;
}

.button:hover {
    color: var(--gray800);
}

.buttonInner {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.buttonInner:hover {
    color: var(--gray800);

    & svg {
        fill: var(--gray800);
    }
}

.buttonInner svg {
    fill: var(--gray700);
}

.buttonInnerDisabled svg {
    fill: var(--gray400);
    cursor: default;
}

.buttonInnerDisabled {
    color: var(--gray400);
}

.buttonInnerDisabled:hover svg {
    fill: var(--gray400);
    cursor: default;
}
