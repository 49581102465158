@import "../../global/theme.css";

.container{
  width: 240px;
  height: 29px;
  background-color: var(--white);
  color: var(--primaryColor);
  display: inline-block;
  border: var(--primaryColor) 1px dashed;
  outline: none;
  margin-right: 3px;
  margin-bottom: 3px;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  @apply --font-button;
  vertical-align: top;
}

.container:hover {
  cursor: pointer;
  background-color: var(--gray100);
}
