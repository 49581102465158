@import "../../../global/theme.css";

.section {
    display: flex;
    margin-bottom: 15px;
    position: relative;

    & .index {
        width: 20px;
        height: 19px;
        border-radius: 50%;
        color: white;
        background-color: var(--blue300);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    }

    & .content {
        display: flex;
        flex-direction: column;
        width: 100%;

        & .titleContainer {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            user-select: none;

            & .title {
                font-weight: 600;
                margin-left: 26px;
            }

            & .divider {
                flex-grow: 1;
                height: 1px;
                background-color: var(--gray200);
            }
        }
    }
}
