@import "../../global/theme.css";

.dataDestinationShelf {
    padding: 30px 30px 80px;

    & .field {
        margin-bottom: 10px;
    }

    & .schemaField {
        border-top: 1px solid var(--gray400);
        margin-top: 20px;
    }
}

.textArea {
    resize: none;
    -webkit-text-security: disc;
}
