@import '../../global/theme.css';

.row {
    border-top: 1.5px solid var(--bodyBackgroundColor);
    background-color: white;
}

.row:first-child {
    border-top: none;
}

.row:last-child {
    border-bottom: 1px solid #e0e0e0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}