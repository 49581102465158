@import '../../global/theme.css';

.tag {
    --dynamicColor: var(--gray400);

    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    color: var(--gray700);
    display: inline-block;
    text-transform: capitalize;
    padding: 2px 6px;
    margin-right: 8px;
    margin-top: 8px;
    background-color: color-mix(in srgb, var(--dynamicColor), white 85%);
    border: 1px solid var(--dynamicColor);
    border-radius: 4px;
}