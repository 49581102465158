@import "../../../global/theme.css";

.fieldsContainer {
    position: relative;
    display: flex;

    & .field {
        display: flex;
        width: 80px;
    }

    & .checkableField {
        background-color: var(--gray100);
    }

    & .checkedField {
        background-color: var(--blue300) !important;
        color: var(--white) !important;
    }
}