@import "../../../global/theme.css";

.partnerConfigurationShelf {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    & .tabContent {
        overflow: auto;
        height: calc(100% - 65px);

        & .topArea {
            display: flex;
            align-items: center;
            position: relative;
            padding: 30px;
            border-bottom: 1px solid var(--gray400);
            justify-content: space-between;

            & .guide {
                display: flex;
                align-items: center;
                position: absolute;
                top: 40px;
                right: 40px;
                color: var(--primaryColor);
                font-weight: 600;
                cursor: pointer;
                margin-left: 10px;

                & .icon {
                    margin-right: 8px;
                    fill: var(--primaryColor);
                }
            }
        }

        & .section {
            display: flex;
            flex-direction: column;
            padding: 30px;

            &:not(:first-child) {
                border-top: 1px solid var(--gray400);
            }

            & .title {
                display: flex;
                align-items: center;
                font-weight: 600;
                margin-bottom: 10px;
                cursor: default;
                flex-direction: row;

                & .icon {
                    margin-right: 8px;
                }

                & .titleIcon {
                    margin-left: 8px;
                }
                & .toggleIaWindow {
                    margin-left: 8px;
                    border-left: 1px solid var(--gray400);
                    padding-left: 4px;
                }
            }

            & .subtitle {
                margin-bottom: 20px;
            }

            & .subsection {
                background-color: white;

                &:not(:first-child) {
                    margin-top: 10px;
                }

                & .title {
                    margin: 16px 20px;
                }

                & .slider {
                    margin: 0px 40px 15px 20px;
                    display: flex;

                    & .sliderLabel {
                        width: 275px;
                        font-size: 13px;
                        color: var(--gray800);
                        display: grid;
                        grid-template-columns: 4fr 1fr;
                        grid-template-rows: 1fr 1fr 1fr 1fr;

                        & .sliderLabelText {
                            width: 100%;
                            grid-row: 1/5;
                        }

                        & .sliderTooltip {
                            grid-row: 1/5;
                        }

                        & .sliderTooltipUseSubTitle {
                            margin-left: 0;
                            grid-row: 2/2;
                        }

                    }

                    & .sliderContainer {
                        width: 100%;
                    }
                }
            }
            & .onelineSliderTitle {
                    width: 70%;
            }
        }

        & .tableRow {
            height: 50px;
        }

        & .attributionTypeCell {
            & > * {
                color: black;
            }
        }

        & .editAction {
             margin: 0px 15px !important;
         }

        & .dropdownCell {
            width: 155px;

            & .dropdown {
                width: 135px;
            }
        }

        & .autocompleteCell {
              width: 205px;

            & .autocomplete {
                  width: 185px;
              }
        }

        & .tableCell {
            text-align: center;
            vertical-align: center;

            &.enabledCell {
                background-color: white;
            }

            &.textCell {
                width: 135px;
            }

            &.windowCell {
                width: 135px;
            }

            &.checkboxCell {
                width: 80px;

                & .checkbox {
                    margin-top: auto;
                    margin-left: 10px;
                    justify-content: center;
                    display: flex;
                }
            }
        }

        & .textInputCell {
            width: 60px;
            margin: auto;

            & input {
                text-align: center;
            }
        }

        .inputError {
            display: flex;
            max-width: 140px;
            width: max-content;
            top: -2px;
            left: 50%;
            transform: translate(-50%, -110%);
            color: black !important;
            background-color: white;
            line-height: normal;
            border-radius: 2px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
            padding: 5px 8px;
            text-align: left;
            text-wrap: balance;

            &:before {
                content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0ieGY0MGo0YTFxYSIgZD0iTTE1LjU4MiAxMC40OTNsLTUuMjk1LTkuMTdDOS44MTcuNTA3IDguOTQgMCA3Ljk5NyAwYy0uOTQgMC0xLjgxOC41MDctMi4yODkgMS4zMjNMLjM1MyAxMC41OTdjLS40NzEuODE1LS40NyAxLjgyOSAwIDIuNjQ0LjQ3MS44MTUgMS4zNDggMS4zMjMgMi4yOSAxLjMyM2gxMC43MTNsLjAyMi0uMDAxQzE0LjgyNiAxNC41NSAxNiAxMy4zNyAxNiAxMS45MTljMC0uNTA5LS4xNDUtMS0uNDE4LTEuNDI2ek03LjA3IDMuMzZoMS45NmMuMTQ5IDAgLjI3LjA5LjI3LjIwM2wtLjU0IDUuNTgyYzAgLjExMi0uMTIyLjIwMy0uMjcuMjAzaC0uODhjLS4xNDggMC0uMjctLjA5LS4yNy0uMjAzTDYuOCAzLjU2M2MwLS4xMTIuMTIxLS4yMDMuMjctLjIwM3pNOCAxMi43MmMtLjcwNyAwLTEuMjgtLjU3My0xLjI4LTEuMjggMC0uNzA3LjU3My0xLjI4IDEuMjgtMS4yOC43MDcgMCAxLjI4LjU3MyAxLjI4IDEuMjggMCAuNzA3LS41NzMgMS4yOC0xLjI4IDEuMjh6Ii8+CiAgICA8L2RlZnM+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnPgogICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIzMSAtMTQ1KSB0cmFuc2xhdGUoMTIwMCAtMSkgdHJhbnNsYXRlKDI4IDg1KSB0cmFuc2xhdGUoMCA1OSkgdHJhbnNsYXRlKDMgMykiPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSIjRTY0RDVGIiB4bGluazpocmVmPSIjeGY0MGo0YTFxYSIvPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
                transform: scale(0.8);
                height: fit-content;
                width: fit-content;
                padding-right: 5px;
            }
        }
    }

    & .footerBtn {
        &:hover {
            & .arrow {
                fill: white;
            }
        }

        & .arrow {
            fill: var(--gray700);
            width: 11px;
            height: 8px;

            &.next {
                margin-left: 10px;
                transform: rotate(-90deg);
            }

            &.back {
                margin-right: 10px;
                transform: rotate(90deg);
            }
        }

        &.disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    & .cancelButton {
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}