@import "../../global/theme.css";

.container {
    padding: 30px;
    height: 100%;
    overflow: auto;
}

.dataRow {
    margin-bottom: 10px;
}

.dataRowTitle {
    margin-bottom: 4px;
}

.dataRowDetails {
    font-size: 14px;
}

.dataRowTitleSmall {
    margin-bottom: 2px;
}

.dataRowValue {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.adminLabel {
    font-size: 16px !important;
    text-transform: none;
}

.adminTaskRun {
    flex-direction: column;
    padding: 0 24px 0 !important;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}


.panelContainer {
    display: flex;
    width: 100%;
    padding: 0 !important;
    justify-content: flex-end;
    align-items: center;
    &.expanded {
        & .panelArrow {
            transform: rotate(90deg);
            position: relative;
            top: 2px;
        }
    }
}

.panelCell {
    margin-right: 30px;
    display: flex;
    align-items: center;
    &:last-child {
        margin-right: 0;
    }
}
.panelName {
    flex-grow: 1;
}

.taskContainer {
    margin: 6px 0;
    &:first-child {
        margin-top: 0;
    }
}

.distributionsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.divider {
    background-color: var(--gray400);
    height: 1px;
    width: 100%;
    margin: 20px 0;
}

.bold {
    font-weight: 500;
}

.statusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.panelSeparator {
    background-color: var(--gray300);
    height: 1px;
    margin-bottom: 8px;
}

.panelArrow {
    border-left: 6px solid var(--gray600);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    width: 8px;
    height: 8px;
    margin-right: 10px;
}

.panelDetailsHolder {
    display: flex;
    flex-wrap: wrap;
}