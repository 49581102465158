@import "../../global/theme.css";

.actions {
     display: flex;
     justify-content: right;
     margin: 18px 0;

     & .action {
          width: 13px;
          margin-right: 10px;
          cursor: pointer;
          position: relative;
          fill: var(--textColor);

          &:last-child {
              margin-right: 0px;
          }

          &.dot {
               &::before {
                     content: '';
                     width: 8px;
                     height: 8px;
                     background-color: var(--primaryColor);
                     border-radius: 50%;
                     position: absolute;
                     top: -2px;
                     left: 100%;
                     border: 1px white solid;
               }
          }

          &.add {
               fill: var(--primaryColor);
          }

          &.hidden {
               cursor: default;
               pointer-events: None;
               opacity: 0 !important;
          }

          &.disabled {
               cursor: default;
               pointer-events: None;
               fill: var(--gray500);
          }
     }
}

