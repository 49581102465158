@import "../../global/theme.css";

.animatedBox {
    margin: 10px;
}
.boxContainer {
    width: 290px;
    height: 118px;
    border-radius: 5px;
    background-color: white;
    padding: 18px;
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;

    & .boxTitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--gray600);
        margin-bottom: 11px;
      }

    & .imagesContainer {
        display: flex;
        align-items: center;
        margin-top: 4px;
        & .boxImage {
            margin-bottom: 2px;
            height: 40px;

            &, & g, & path {
                fill: var(--gray700);
            }
        }
        & .imagesSeparator {
            width: 1px;
            height: 49px;
            transform: rotate(10deg);
            background-color: var(--gray500);
            margin: 0 17px;
        }
      }
    &.disabledBoxContainer {
        cursor: default;

        & .boxTitle {
            color: var(--gray500);
        }

    }
    &:not(.disabledBoxContainer):hover {
        & .boxTitle {
            color: var(--gray800);
        }
        & .boxImage {
            &, & g, & path{
                fill: var(--blue300);
            }
        }
    }

}
