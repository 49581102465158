.title {
    display: block;
}
.content{
    width: 60%;
    display: block;
    margin-bottom: 30px;
}
.revenueAccordionFaq {
    width: 717px;
    margin: 8px 0 20px;
}