@import "../../../global/theme.css";

.content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 70px);

    & .title {
        color: var(--gray800);
        font-weight: 600;
        font-size: 14px;

        & .checkmark {
            width: var(--icon-size-small);
            height: auto;
            fill: black;
        }
    }

    & .subtitle {
        color: var(--gray700);
        margin: 10px 0;
        font-size: 14px;
    }

    & .agency {
        margin-top: 20px;
    }

    & .option {
        position: relative;
        padding: 20px;
        color: var(--gray700);
        background-color: white;
        margin: 8px 0;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:last-child {
            margin-bottom: 0;
        }

        & .optionCheckmark {
            position: relative;
            top: auto;
            left: auto;
            margin-right: 10px;
        }
    }

    & .allowedDomainItem {
        margin: 8px 0;
        padding: 3px 0;
        color: var(--gray700);
        border-bottom: transparent dashed 1px;
    }

    & .allowedDomainsContainer {
        padding: 0 30px 30px;
        background-color: white;

        & .addAllowedDomain {
            display: flex;
            align-items: end;

            & .allowedDomainInput {
                width: 460px;
                margin: 0 15px 0 0;

                & label {
                    color: var(--gray700);
                    font-weight: 600;
                }
            }
        }

        & .allowedDomainsListTitle {
            font-weight: 600;
            color: var(--gray700);
            margin-top: 30px;
        }

        & .noAllowedDomainsConfigured {
            color: var(--gray400);
            margin: 10px 0;
        }

        & .allowedDomainItem {
            display: flex;
            justify-content: space-between;

            & .deleteIcon {
                height: 16px;
                cursor: pointer;
                fill: var(--gray500);
                opacity: 0;
                transition: all 200ms ease;

                &:hover {
                    fill: red;
                }
            }

            &:hover {
                border-bottom-color: var(--gray400);

                & .deleteIcon {
                    opacity: 1;
                }
            }
        }
    }
}
