@import "../../global/theme.css";



.contentSeparator {
    height: 1px;
    margin: 0 40px 25px 67px;
    background-color: var(--gray400);
}

.numberIconsLayout {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    bottom: 20px;

    & .number {
        margin-left: -15%;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: 1px solid var(--gray700);
        background-color: white;
        text-align: center;
        font-weight: bold;
        padding-top: 1px;

    }
    & .chosenNumber {
        background-color: var(--gray700);
        color: white;
    }


    & > * {
        margin-left: -10px;
        cursor: pointer;
    }
}

.contentTitleContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 26px 0 67px;
    height: 70px;

    & .contentTitleButton {
        align-self: center;
        height: 30px;
        right: 15px;
    }

    & .contentTitleText {
        font-size: 20px;
        align-self: center;
    }
}

.contentContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 67px;
    overflow: auto;
    height: 100%;
    font-size: 14px;
    min-width: 750px;

        & .content {
            width: 50%;
            
            & ul {
                padding: 10px 0 0 20px;
            }
        }
        & .contentButtonContainer {
            display: flex;
            justify-content: end;
            margin-top: 32px;
          }

        & .contentSectionButton {
            height: 30px;
            right: 15px;
        }

        & .contentMarkAsDoneButton {
            margin-right: 15px;
            border: 1px solid var(--gray800)
          }

        & .contentNoMedia {
            width: 80% !important;
        }

        & .mediaContainer {
            width: 48%;

            & .mediaImage {
                width: 100%;
            }

            & .mediaVideo {
                width: 100%;
                height: 350px;
            }
        }
}

