@import "../styles.css";

.counter {
  display: flex;
  align-items: center;
}

.vSeperator {
  height: 16px;
  width: 1px;
  background-color: #a7a7a7;
  margin: 0 9px;
}

.preamble {
  margin: 0;
  flex-grow: 1;
}