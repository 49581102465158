@import "../../../../../global/theme.css";

.container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        margin-right: 5px;
    }

    & .dropdown {
        width: 250px;
    }

    & .dropdownLabel {
        margin-right: 10px;
    }
}