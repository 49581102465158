
.percentContainer {
    display: flex;
    justify-content: space-around;
    font-weight: 600;
    font-size: 14px;
    width: 100%;

    &.disabled {
        background: var(--primaryDisabledLight) !important;
        color: var(--primaryButtonTextDisabled) !important;
    }
    & .percentContentContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;

        & .inputClassName {
            border: none !important;
            font-weight: 600;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 15px !important;
        }
    }

    & .arrowContainer {
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;

        & .arrow {
            width: 12px;
            height: 7px;
            cursor: pointer;
            fill: var(--gray500);

            &.disabled {
                fill: var(--primaryButtonTextDisabled) !important;
                cursor: not-allowed;
            }
        }

        & .arrowFlipped {
            cursor: pointer;
            width: 12px;
            height: 7px;
            transform: rotate(180deg);
            fill: var(--gray500);
            &.disabled {
                fill: var(--primaryButtonTextDisabled) !important;
                cursor: not-allowed;
            }
        }
    }
}
