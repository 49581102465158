@import "../../../global/theme.css";

.container {
    display: flex;
    flex-direction: column;

    & .duplicatedSite {
        margin-bottom: 25px;

        & img {
            height: 26px;
            margin: 0 10px;
            border-radius: 5px;
        }
    }

    & .duplicatedSite, & .duplicateToSite {
        display: flex;
        align-items: center;

        & .icon {
            width: 30px;
        }

        & .label {
            font-weight: 600;
            width: 260px;
            font-size: 14px;
            color: var(--gray800);
        }
    }
}