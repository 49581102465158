@import '../../global/theme.css';

.loader {
  & .image {
    width: 100px;
    height: 160px;
    transform-origin: bottom center;
    animation: rotate 1.5s infinite;
    opacity: 0;
    margin: 25px auto auto auto;
  }

  & .circle {
    width: 138px;
    height: 138px;
    border-radius: 50%;
    border: 1px solid var(--gray400);
    margin: -90px auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .countUp {
    font-size: 40px;
    color: var(--blue300);
    margin: auto;
  }
}
@keyframes rotate {
    0% {
        transform: rotate(90deg);
    }
    10% {
        opacity: 0;
    }
    35% {
        transform: rotate(0deg);
        opacity: 1;
    }
    65% {
        transform: rotate(0deg);
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        transform: rotate(-90deg);
    }
}
