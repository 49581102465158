.container {
  height: 60px;
  width: 100%;
  background-color: var(--blue150);
  border: 1px solid #a1d7f7;
  color: #009bf5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  padding: 16px;
  margin-top: 20px;
}

.readyContainer {
  display: flex;
  align-items: center;
}

.readyText {
  margin-right: 8px;
}