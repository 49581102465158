@import "../../global/theme.css";
@import "../../global/general.css";

.header {
    background-color: var(--gray700);
    color: var(--white);
    width: 100%;
    text-align: center;
    line-height: 28px;
    height: 28px;
    font-weight: 400;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    font-size: 11px;
    border-radius: 2px 2px 0 0;


    & .questionMarkIcon {
        fill: white;
        stroke: var(--gray700);
        stroke-width: 0.5px;
    }
}

.content{
    font-size: 12px;
    padding: 0 3px;
    color: var(--gray800);

    & .contentText {
        color: var(--gray700);
        font-style: italic;
        font-size: 10px;
        font-weight: 400;
    }

    & > div {
        display: flex;
        flex-direction: row;
        padding: 0 5px;
        margin: 10px 0;
        justify-content: space-between;

        & .sourceDetails {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-width: 100px;

          & .usernameText {
            font-size: 10px;
            font-weight: 400;
            color: var(--gray700);
          }

          &.mutedText {
            color: var(--gray700);

            & .usernameText {
              font-weight: 300;
            }
          }

          & span:last-child {
            text-decoration: none!important;
          }
          &.sourceDetailsAdRevenue {
            width: 140px;
          }
        }

        & .sourceValue {
            margin-left: 10px;
            text-align: right;
            min-width: 70px;

            &.mutedText {
              color: var(--gray700);
            }
        }
    }

    & .sourceLine {
        font-weight: 600;

        &.mutedText {
            font-style: italic;
            font-size: 11px;
            font-weight: 300;
        }
    }

    & .sourceLine:before {
        content: ' ';
        width: 14px;
        height: 5px;
        border-radius: 2px;
        margin: 7px 7px 0 0;
    }

    & .calculatedLineColor:before {
       background-color: var(--blue400);
    }

    & .ad_revenue_sourceLineColor:before {
        background-color: var(--purple200);
    }

    & .trackerLineColor:before {
        background-color: var(--gray500);
    }

    & .networkLineColor:before {
        background-color: var(--blue200);
    }
}

.transparencyPopover {
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px 4px !important;
}
