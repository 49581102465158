@import "../../../global/theme.css";

.table {
    border: 1px solid var(--gray400);
    font-size: 14px;
    display: block;
}

.tableOverflow {
    overflow-y: scroll;
    max-height: 75%;

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: var(--gray400);
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
    }
}

.theadClass {
    display: none;
}

.stepContainer {
    margin: 20px auto;
    min-width: 650px;
    max-width: 816px;
    height: var(--onboardingPlanningHeight);

    &.summaryStepContainer {
        height: 80vh;
    }
}

.stepTitle {
    margin: 50px auto 30px auto;
    color: var(--gray800);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    display: block;
}

.summaryEditTitle {
    font-size: 20px;
    text-align: center;
    width: 100%;
    color: var(--gray800);
}

.summarySubtitleContainer {
    display: flex;
    justify-content: center;
}

.summarySubtitle {
    text-align: center;
    line-height: 33px;
    margin-bottom: 20px;
}

.editPlanButton {
    display: inline;
    padding: 0 5px !important;

    & .editPlanButtonIcon {
        width: 30px;

        & path {
            fill: var(--gray700);
        }
    }
}
