@import "../../global/theme.css";

.tableContainer {
    border: 1px solid #CCCCCC;
}

.sitesTable {
    overflow: visible !important;
    display: flex;
    align-items: center;

    & .loadingContainer {
        margin-top: 35vh;
    }

    & .tableHeader {
        position: sticky;
        top: -1px;
        z-index: 1;
    }

    & .tableCell {
        height: 60px;
    }

    & .thClass {
        text-align: left;
        font-weight: 600;
    }

    & .appsiteRowCell {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 2px 10px;
        align-items: center;
        gap: 6px;
        text-align: left;
        width: 270px;
        height: inherit;
    }

    & .disturbutionsCell {
        text-align: center;
        max-width: 220px;
    }

    & .actionCell {
        opacity: 1 !important;
        -webkit-box-shadow: none !important;
        width: 30px;

          & div {
            width: 30px !important;
            background-color: transparent !important;
            -webkit-box-shadow: none !important;
            transform: rotate(90deg) !important;
            right: 21% !important;
            top: 29px !important;

            & .action {
                position: relative;
                cursor: pointer;
                display: none !important;
                opacity: 0 !important;
                -webkit-box-shadow: none !important;
                top: calc((100% - var(--actionButtonSize)) / 2) !important;
            }
        }
    }

    & .statusCell {
        width: 142px;
        height: 50px;
        font-weight: 600;
    }

    & .editStatusCell {
        width: 142px;
        height: 40px;
        font-weight: 400;
        padding: 2px 0;
        border-radius: 40px;
        font-style: italic;
        background-color: var(--gray50);
    }


    & .dateCell {
        font-weight: 400;
        width: 100px;
        text-align: left;
        min-width: 95px;
    }

    & .testNameCell {
        min-width: 290px;
        padding-left: 12px;
        text-align: left;
        font-weight: 400;
    }

    & .appsiteIcon {
        min-width: 30px !important;
        width: 30px !important;
        height: 30px;
        border-radius: 4px;
    }

    & .appsiteColumn {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
    }

    & .subTextContainer {
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .dropdownCell {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        z-index: 1000;


        & ul {
            margin-right: 10px;
            width: 170px;
        }

        & button {
            border-radius: 3px;
            border: none;
            background: inherit;
            color: var(--blue300);
            font-size: 16px;
            cursor: pointer;
            position: relative;
            width: 100%;
            text-align: center;
            height: 40px;

            &:hover {
                color: var(--blue400);
            }

            &.active {
                color: var(--blue500);
            }
        }
    }
}