@import "../../global/theme.css";

.auditSector {
    width: 100%;
    margin-bottom: 60px;
}

.textSector {
    width: 90%;
}

.auditButton {
    width: 175px;
    margin-top: 15px;
}

.auditButtonText {
    width: 100%;
}

.auditsTextSector {
    width: 50%;
    display: inline-block;
}

.auditsSchemaImg {
    width: 50%;
    float: right;
    max-height: 100%;
}