@import '../../../global/theme.css';

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    & .metrics {
        display: grid;
        column-gap: 25px;
        row-gap: 10px;

        &.maxMetricsSelected {
            gap: 6px;
            justify-content: space-between;
        }

        & .metric {
            display: flex;
            font-size: 13px;
            gap: 2px;
            align-items: center;

            & .metricLabel {
                font-weight: 600;
                white-space: nowrap
            }

            & .metricIcon {
                margin-right: 2px;
                fill: var(--gray400);

                &.smallIcon {
                    width: 21px;
                    height: 21px;
                }
            }
        }
    }

    & .osListContainer {
        display: flex;
        gap: 8px;

        & .osIconContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--gray400);
            padding: 2px;
            width: 22px;
            height: 22px;

             & .osIcon {
                fill: var(--gray100);
            }
        }
    }
}
