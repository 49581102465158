@import '../../global/theme.css';

.button {
  color: var(--blue300);
  cursor: pointer;
  background-color: transparent;
  border: none;
  line-height: 14px;
  @apply --font-button;
  padding: 0;
  text-align: left;
  &:hover:not([disabled]):not(:active) {
    color: var(--blue400);
  }
  &[disabled] {
    color: var(--blue100);
    cursor: default;
  }
  &:active:not([disabled]) {
    color: var(--blue500);
  }
}