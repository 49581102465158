@import "../../global/theme.css";

.container {
    display: flex;

    & .navBar {
        display: flex;
        flex-direction: column;
        position: relative;
        height: fit-content;
        width: 110px;
        margin-right: 35px;

        & .line {
            position: absolute;
            width: 1px;
            height: 100%;
            margin-left: 50%;
            border-right: 1px solid var(--primaryColor);
            z-index: -1;
        }

        & .navBarSection {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 0;
            margin-bottom: 35px;
            font-weight: 600;
            color: var(--flatButtonText);
            background-color: var(--bodyBackgroundColor);
            cursor: pointer;
            text-align: center;
            transition: all 0.1s linear;

            & .navBarNumber {
                width: 17px;
                height: 17px;
                color: white;
                background-color: var(--gray700);
                border-radius: 50%;
                font-size: 12px;
                text-align: center;
                margin-bottom: 3px;
            }

            &.active {
                color: var(--primaryColor);
                & .navBarNumber {
                    background-color: var(--primaryColor);
                }
            }

            &:hover {
                color: var(--blue500);
                & .navBarNumber {
                    background-color: var(--blue500);
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    & .activeSection {
        width: 520px;
        overflow: auto;

        & .title {
            font-weight: 600;
            margin-bottom: 15px;
        }
    }
}
