@import "../../global/theme.css";

.singularSelect {
    outline: none;
    border-color: var(--gray400) !important;
    box-shadow: none !important;
    background-color: var(--white) !important;
    border-radius: 3px;
    height: 100%;

    :global(.Select__menu-notice--no-options) {
        padding: 0;
        height: 100%;
        align-content: center;
        cursor: default;
    }

    & :global(.Select__control) {
        border-radius: 2px !important;
        border-color: var(--gray400) !important;
        background-color: var(--white);
        min-height: 36px;
        height: 100%;
        width: 100%;

        &:hover {
            box-shadow: none;
        }

        &:global(.Select__control--is-focused) {
            box-shadow: none;
        }

        &:global(.Select__control--is-disabled) {
            color: var(--primaryButtonTextDisabled) !important;
            background-color: var(--primaryDisabledLight);
            border-color: var(--gray300) !important;
            opacity: 1 !important;

            & * {
                color: var(--primaryButtonTextDisabled) !important;
            }

            & :global(.Select__dropdown-indicator) {
                z-index: 0;

                & svg {
                    fill: var(--primaryButtonTextDisabled) !important;
                }
            }
        }

        & :global(.Select__value-container) {
            & :global(.Select__placeholder) {
                color: var(--gray400);
                font-style: italic;
            }
        }

        & :global(.Select__value-container--is-multi) {
            padding: 4px 0 0 4px;

            & > div:not(.tagContainer) {
                margin-left: 5px;
                font-size: 14px;

                &:global(.Select__placeholder) {
                    color: var(--gray400);
                    font-style: italic;
                }
            }
        }

        & :global(.Select__indicators) {
            cursor: pointer;

            & :global(.Select__dropdown-indicator) {
                padding: 7px;

                & svg {
                    fill: var(--blue300);
                }
            }
        }
    }

    &.error {
        & :global(.Select__control) {
            border-color: var(--red300) !important;

            &:global(.Select__control--is-focused) {
                border-color: var(--red300) !important;
            }
        }
    }

    &.big {
        & :global(.Select__control) {
            min-height: 45px;
        }
    }
}

:global(.Select__group) {
    padding: 0;

    & :global(.Select__group-heading) {
        margin: 0;
        background-color: #EAEAEA;
        color: #555555;
        cursor: default;
        font-style: italic;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 300;
        text-transform: initial;
    }
}
