@import "../../global/theme.css";

.container {
    background-color: var(--warning1BGColor);
    color: var(--warning1Color);
    display: flex;
    border: 1px solid var(--warning1BorderColor);
    font-size: 14px;
    font-weight: 500;

    align-items: flex-start;
    border-radius: 8px !important;
    padding: 16px;
    gap: 10px;

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        background-color: var(--warning1BGColor);
        border-color: var(--warning1BorderColor);
        color: var(--warning1Color) !important;
    }

    & .messageContainer {
        font-size: 14px;
        color: var(--gray800);
        font-weight: normal;
        line-height: 20px;

        a {
            color: var(--blue300);
            font-weight: 600;
            text-decoration: none;
        }
    }
}

.container.externalLink {
    background-color: var(--blue100);
    border-color: var(--blue200);
    color: var(--blue300);

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        width: 34px !important;
        color: var(--blue300) !important;
        background-color: var(--blue100) !important;
    }

    & .messageContainer {
        font-size: 14px;
        color: var(--gray800);
        font-weight: normal;
        line-height: 20px;

        a {
            color: var(--blue300);
            font-weight: 600;
            text-decoration: none;
        }
    }
}

.container.error {
    background-color: var(--error1BGColor);
    border-color: var(--error1BorderColor);
    color: var(--error1Color);

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        background-color: var(--error1BGColor);
        border-color: var(--error1BorderColor);
        color: var(--error1Color) !important;
    }
}

.container.info {
    background-color: var(--info1BGColor);
    border-color: var(--info1BorderColor);
    color: var(--info1Color) !important;

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        background-color: var(--info1BGColor);
        border-color: var(--info1BorderColor);
        color: var(--info1Color) !important;
        margin-top: 4px;
        fill: var(--info1Color);
    }
}

.container.whiteInfo {
    background-color: var(--white);
    border-color: var(--gray500);
    color: var(--gray800) !important;

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        background-color: var(--white);
        border-color: var(--gray800);
        color: var(--gray800) !important;
        fill: var(--gray800);
    }
}


.container.success {
    background-color: var(--success1BGColor);
    border-color: var(--success1BorderColor);
    color: var(--success1Color);
    fill: var(--success1Color);

    & svg, & svg:hover, & svg:hover:not([disabled]):not(:active) {
        background-color: var(--success1BGColor);
        border-color: var(--success1BorderColor);
        color: var(--success1Color) !important;
    }
}

.container.warning:nth-child(n+2) {
    background-color: var(--orange100);
}

.messageContainer {
    margin-right: auto;

    &.textAlignCenter {
        margin-left: auto;
    }
}

.icon {
    color: var(--warning1Color) !important;
    opacity: 0.3;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.icon:hover {
    opacity: 1;
}

.typeIcon {
    height: 15px;
    width: 15px;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 2px;
}

.spacer {
    margin: 0 8px;
    width: 0;
    align-self: stretch;
}

.solid {
    border-right: 1px solid;
    border-color: inherit;
}