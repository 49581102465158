@import "../global/theme.css";

.organizationPlanPage {
    background-color: var(--white);
    & .pageHeaderContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 0 2%;

        & .pageHeader {
            font-family: OpenSans, Helvetica, Arial, sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.04;
            text-align: left;
            color: var(--gray800);
            max-width: 1200px;
            min-width: 756px;
            flex: 1 1 0;
        }
    }


    & .pageContent {
        width: 100%;
        background-color: var(--white);
        margin-top: 1%;
        padding: 1% 2%;

        & .noActivePlan {
            font-size: 14px;
            font-weight: 600;
            line-height: 2.5;
            color: var(--gray700);
        }

        .planDetailsContainer {
            display: flex;
            gap: 32px;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    & .warningMessage {
        top: 0;
        width: 100%;
        left: 0;
        margin-bottom: 40px;
        border-radius: 0 !important;
    }
}









