@import '../../global/theme.css';

.container {
    display: flex;
    position: relative;
    padding-left: 20px;

    & .image {
        margin-right: 10px;
        width: 15px;
        height: 15px;
    }

    & .labels {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.bold {
        font-weight: bold;
    }
}