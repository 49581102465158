.subHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 24px;
    padding-top: 24px;

    & .searchContainer {
     width: 290px;

        & :global(.Select__control) {
            border-radius: 1px !important;
            cursor: text !important;
        }
    }
}
