@import "../../global/theme.css";

.container {
    font-size: 16px;
    font-weight: 300;
    margin-left: 60px;
    margin-right: 55px;

    & * {
        outline: none;
    }
}

.title {
}

.arrow {
    border-left: 6px solid var(--primaryColor);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    width: 8px;
    height: 8px;
    transform: rotate(0deg) translateX(2px) translateY(2px);
}

.expanded .arrow {
    transform: rotate(90deg) translateX(4px);
}

.uanHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 55px;
    width: fit-content;
}

.uanIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.uanIcon {
    width: 24px;
    height: 24px;
}

.uanHeaderItem {
    margin-right: 8px;
}

.uanHeaderItem:last-child {
    margin-right: 0;
}

.uanName {
    font-weight: 500;
}

.warningContainer {
    margin-bottom: 20px;
}

.uansInputContainer {
    margin-bottom: 20px;

    & .uanEventText {
        font-size: 13px;
        margin-right: 20px;
        font-weight: 600;
        border: 1px solid var(--gray400);
        padding: 5px 10px;
        user-select: none;
    }
}

.calculatedMetricsContainer {
    margin-top: 10px;
    margin-bottom: 55px;
}

.calculatedMetricsTitle {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    & .calculatedMetricsTitleText {
        margin-right: 8px;
    }
}

.divider {
    height: 1px;
    background-color: var(--gray400);
    margin: 10px 0 10px 0;
}

.suggestionsContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.suggestionsLabel {
    margin-right: 13px;
}

.suggestion {
    background-color: var(--white);
    border: 1px solid #DAE3E8;
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
    margin-right: 4px;
}

.seeMoreToggle {
    margin-top: 10px;
    color: var(--blue400);
    cursor: pointer;
}

.uniqueToggleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.uniqueToggleLabel {
    margin-right: 8px;
    font-weight: 500;
}