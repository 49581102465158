@import '../../../../../global/theme.css';

.container {
    width: 755px;
}

.content {
    margin-bottom: 20px;

    & .icon {
        width: 20px;
        fill: var(--gray700);
    }

    & b {
        font-weight: 600;
        color: var(--gray800);
    }
}

.eventsFqaTitle {
    margin-top: 30px;
}

.eventsAccordionFaq {
    width: 717px;
    margin: 8px 0 20px;
}

:global(.sdkIntegrationEventsQaFiveBulletList) {
    margin: 0;
    list-style-type: none;

    & > li {
        text-indent: -10px;

        &:before {
            content: "-";
            padding-right: 10px;
        }
    }
}

