@import "../../global/theme.css";

.title {
    background-color: var(--blue600);
    display: flex;
    justify-content: space-between;
    color: var(--white);
    align-items: center;
    font-size: 18px;
    padding: 8px 16px;

    .crossIcon {
        color: var(--white);
        cursor: pointer;
    }
}