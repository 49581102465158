@import '../../../global/theme.css';

.container {
    display: flex;
    gap: 4px;
    align-items: center;


    & .chip {
        height: fit-content;
        background-color: var(--gray100);
        border: unset;
        font-size: 11px;

        & .chipLabel {
            padding-right: 5px;
        }
    }

    .statusIndication {
        width: 8px;
        height: 8px;
        border-radius: 100%;

        &.live {
            background-color: var(--success1Color);
        }

        &.not_live {
            background-color: var(--gray400);
        }
    }
}
