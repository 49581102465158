@import "../../global/theme.css";

.container {
    display: flex;
    flex-direction: column;
}

.container.horizontal {
    flex-direction: row;

    & .radioLabel {
        margin-right: 20px;
        margin-bottom: 12px;
        color: var(--gray700);
    }
}

.radioLabel {
    @apply --font-input;
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 4px;
    color: var(--gray600);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & .tag {
        margin-left: 8px;
        font-size: 13px;
        color: var(--blue400);
    }
}

.description {
    font-size: 11px;
    margin: 6px 0;
    color: var(--gray500);
}

/* Hide the browser's default radio button */
.radioLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--white);
    border: 1px solid var(--gray400);
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioLabel:hover input ~ .checkmark {
}

/* When the radio button is checked, add a blue background */
.radioLabel input:checked ~ .checkmark {
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioLabel input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioLabel .checkmark:after {
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primaryColor);
}

.tooltip {
    display: flex !important;
    margin-left: 6px;
}