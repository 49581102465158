@import "../../components/Main.css";

.container {
    padding: 0px 30px 20px 30px;
    line-height: 1.6;
    margin: 25px 0 6px;
    font-size: 14px;
    max-width: 100%;
    color: var(--gray700);
    display: flex;
    flex-direction: column;

    & * {
        outline-width: 2px;
    }
}

.text {
    font-size: 14px;
}

.aboutTitle {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin-top: 22px;
    margin-bottom: 15px;
    color: var(--gray800);
}

.sectionHeader {
    display: flex;
    margin-bottom: 10px;
    & .text {
        margin-left: 10px;
        margin-top: -3px;
    }
}

.slackContainer {
    align-items: flex-start;
    display: inline-flex;
    margin-top: 8px;
    margin-bottom: 35px;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    & .slackText {
        width: 100%;
        font-size: 13px;
        color: var(--blue300);
        display: flex;
    }

    & .slackIcon {
        width: 18px;
        height: 18px;
        margin: 2px 8px 0 0;
        object-fit: contain;
    }
}
