@import "../../components/Main.css";

.container {
    text-align: center;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 150px;

    & .title {
        font-size: 18px;
        color: var(--gray800);
    }

    & .text {
        font-size: 14px;
        color: var(--gray700);
    }
}
