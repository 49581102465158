@import "../../../global/theme.css";

:root {
    --container-border: 1px solid var(--gray400);
    --section-item-border: 1px solid var(--gray400);
}

.pageContainer {
    display: flex;
    flex-direction: column;
    border: var(--container-border);
    background-color: var(--white);
    max-width: 1200px;
    margin-bottom: 10px;
}

.pageTitle {
    @apply --3rd-title;
    padding: 10px 10px 10px 27px;
}

.pageTitleBorder {
    border-bottom: var(--container-border);
    margin: 0 10px 0 27px;
}

.pageSubTitle {
    padding: 10px 10px 20px 27px;
}

.sectionContainer {
    display: flex;
    max-width: 100%;
    height: 100%;
    overflow-x: auto;
    z-index: 1;
}

.sectionList {
    width: 33%;
    min-width: 295px;

    &.nested {
        width: 100%;
    }
}

.sectionContentContainer {
    width: inherit;
    height: inherit;
}

.sectionContent {
    border-left: var(--section-item-border);
    width: 67%;
    background-color: var(--white);
    padding: 60px 90px 15px;
    overflow-x: auto;
}

.contentWithoutNavigation {
    padding: 60px 90px 15px;
    justify-content: center;
}

.contentWithNavigation {
    padding: 28px 41px 28px 41px;
}

.tooltip {
    width: 222px;
}