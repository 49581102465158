@import "../../global/theme.css";

.container {
    display: flex;
    align-items: center;
    min-width: 0;

    & .modelName {
        padding-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100px;
        max-width: 430px;
    }

    & .icon {
        width: 25px;
    }

    & .modelHeaderFieldContainer {
        display: flex;
        align-items: center;
        font-size: 13px;

        & .modelHeaderField {
            display: inline-flex;
            align-items: flex-start;

            & .modelHeaderFieldValue {
                font-weight: 600;
                margin-left: 5px;
            }
        }
    }

    & .rightArea {
        margin-left: auto;
        display: flex;
        align-items: center;
        pointer-events: none; /* so clicking on text still triggers the expander */
    }

    & .optimizedModelButtonLabel {
        background-image: linear-gradient(var(--skanPlusGradientStart), var(--skanPlusGradientEnd));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        & .optimizedModelButtonLabelSpan {
            margin-left: 5px;
        }
    }

    & .optimizedModelButtonLabelHover {
        color: var(--white);
        -webkit-text-fill-color: var(--white);
    }

    & .optimizedModelButton {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        pointer-events: auto;
        border-radius: 31px;
        background-clip: content-box, border-box;
        background-image: linear-gradient(white, white), linear-gradient(var(--skanPlusGradientStart), var(--skanPlusGradientEnd));
        padding: 1px;
        font-size: 12px;
        margin: 2px 14px 2px 0px;
        font-weight: 600;
        min-width: 197px;
        width: 220px;
        height: 30px;
    }

    & .optimizedModelButtonHover {
        background-image: linear-gradient(103deg, var(--skanPlusGradientStart), var(--skanPlusGradientEnd));
        background-clip: content-box;
    }

    & button {
        margin-right: -15px;
    }
}

.separator {
    height: 30px;
    width: 1px;
    background-color: var(--gray500);
    margin: 0 10px;
}

.actionMenuContainer {
    pointer-events: all;

    & .actionMenuButton {
        width: 36px;
        display: block;

        & ul {
            /* so menu isn't cut by overflow hidden */
            margin-right: 9px;
        }

        &:hover g {
            fill: var(--gray700);
        }
    }
}

.modelToggle {
    margin-top: 3px;
    margin-right: 10px;
    margin-left: 15px;
    width: 32px !important;
    left: 5px;
}
