@import "../../global/theme.css";

.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.contentContainer {
    padding: 30px 30px 84px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: var(--gray400);
    margin: 20px 0;
}

.groupItemContainer {
    margin-bottom: 20px;
}

.groupItemSpinner {
    margin-top: 120px;
}

.osLabel{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #555;
    margin-bottom: 15px;
    height: 25px;
    & .osIcon {
        width: 24px;
        height: 24px;
        fill: var(--gray600);
        margin-right: 10px;
    }
    & .osLabelCheckbox {
        margin: 0;
    }
    & .osLabelTooltip {
        margin-left: 6px;
        display: flex;
    }
    &.disabled {
        & .osIcon, & .osLabelText, & .osLabelTooltip {
            opacity: 0.4;
            cursor: default;
        }
    }
}

.lineLoaderContainer {
    margin-bottom: 20px;
}

.toggleExtendedContainer {
    display: flex;
    align-items: center;
    & .toggleExtendedInfo {
        margin-left: 6px;
        display: flex;
    }
    margin-bottom: 5px;
}