@import "../../../global/theme.css";

.geoRestrictionsContainer {
    display: flex;
    align-items: center;

    & .filterSection {
        label:first-child {
            display: flex;
        }

        & .filterInput {
            width: 100px;
            margin-bottom: 4px;
        }

        & .filterInputButton {
            height: 40px;
        }
    }

    & .label {
        font-size: 14px;
        color: var(--gray800);
        width: 200px;
    }

    & .countriesSelection {
        width: 500px;
        margin-left: 20px;
    }
}