@import "../../../global/theme.css";

:root {
    --expandable-container-padding: 60px;
}

.container {
    & .headerContainer {
        overflow: visible; /* for actions menu */
        background-color: var(--gray100);
        border: none !important;
        height: 60px;

        &.enabled {
            background-color: var(--blue100) !important;
        }
    }

    & .expandableOverrideExternal {
        margin-right: 8px;
        flex-shrink: 1;
        min-width: 0;
    }

    & .contentArea {
        padding-top: 0;

        & .warningMessage {
            display: flex;
            align-items: center;
        }

        & .partnerContainer {
            display: inline-flex;
            align-items: center;
            margin: 0 6px;

            & .partner {
                color: var(--primaryColor);
                text-decoration: none;
                margin-left: 2px;
                cursor: pointer;
                font-weight: 600;
            }

        }

        .tabsContainer {
            margin: 0 var(--expandable-container-padding) 0;
        }

        & .tab, .tab:hover {
            width: 60px;
            padding-bottom: 5px;
        }

        & .activeTab, .activeTab:hover {
            color: var(--green400);
            border-bottom-color: var(--green400);
            fill: var(--green400);
        }

        & .cardContent {
            display: flex;
            justify-content: space-between;

            & .rightSection {
                padding: 25px var(--expandable-container-padding);

                & .measurementPeriod {
                    display: flex;

                    & .measurementPeriodDays {
                        font-weight: 600;
                        padding-left: 3px;
                    }
                }

                & .lastUpdate {
                    color: var(--gray500);
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
        }
    }
}

.modelInfo {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 15px 60px;
}