@import "../../global/theme.css";

:global(.json-key) {
    color: var(--red300);
    display: inline-block;
    font-weight: 600;
    padding-bottom: 3px;
}

:global(.MuiTableRow-root) {
    height: 41px;

    &.expandedRow tr {
        & td {
            border-top: none;
        }

        &:last-child td {
            border-bottom: none;
        }

        &:hover {
            background-color: var(--white);
            cursor: default;
        }

        height: 39px;
    }

    & td {
        border-bottom: 1px solid var(--gray300);
        padding: 8px 20px 8px 0;

         &.bundleId {
             padding-right: 0;
         }

        & .overflowingText {
            line-height: 18px;
        }

        & .overflowingTextWrapper {
            max-width: 100%;
            display: inline-block;
        }

        &.bundleId, &.device, &.eventName, &.appName {
            min-width: 150px;
            max-width: 180px;
        }

        &.appName {
            & .overflowingTextWrapper {
                max-width: 85%;
            }
        }
    }

    &:last-child > td {
        border-bottom: none;
    }

    &:first-child > td {
        border-top: 1px solid var(--gray300);
    }

    &:nth-last-child(1) > td {
        border-bottom: 1px solid var(--gray300);
    }

    &:not(.expandedRow, :global(.MuiTableRow-head)){
        &:hover {
            background-color: #e4f1fd;
            cursor: pointer;
        }
    }
}

:global(.MuiTableCell-root) {
    font-size: 13px !important;
    color: var(--gray700) !important;
    font-family: OpenSans, Helvetica, Arial, sans-serif !important;

    &.logJsonKey {
        font-weight: 600;
        max-width: 100px;
    }

    &.logJsonValue{
        max-width: 100px;
        line-height: initial;
    }

    &:global(.MuiTableCell-paddingNone) {
        &:last-child {
            padding-right: 15px;
        }
    }
     &:global(.MuiTableCell-head) {
         padding: 0;
         line-height: inherit;
         border-bottom: none;
    }
}
.tableSubtitleHeader {
    padding: 5px 0;
    display: flex;
    background-color: var(--gray100);
}

.sandWatchIcon {
    margin: 7px;
}

.tableSubtitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray700);
    padding: 3px;
}

.tableHeader {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray800);
}

.tableWrapper {
    padding: 10px 25px 0;
    background-color: var(--white);
}
.innerLogDetails {
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
}

.innerLogDetailsTable {
    width: 70%;
    background-color: var(--white);
}
.jsonToggleWrapper {
    display: none;
    justify-content: space-between;
}

.jsonToggleText {
    line-height: 30px;
    padding-right: 5px;
}

.collapseIcon {
    width: 12px;
    fill: var(--gray600)
}

.expandIcon {
    width: 12px;
    fill: var(--gray600);
    transform: rotate(-90deg);
}


.smoothOpenAnimationEnter {
  opacity: 0.01;
  transform: scaleY(0);
}

.smoothOpenAnimationEnter.smoothOpenAnimationEnterActive {
  opacity: 1;
  transform: scaleY(1);
  transition: all 300ms ease-in;
}

.smoothOpenAnimationLeave {
  opacity: 1;
  transform: scaleY(1);
}

.smoothOpenAnimationLeave.smoothOpenAnimationLeaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
  transform: scaleY(0);
}

.appsiteIcon {
    margin-right: 5px;
    width: 22px;
    height: 22px;
    vertical-align: -3px;
}