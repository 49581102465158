@import '../../../../global/theme.css';

.header {
    display: flex;
    justify-content: space-between;

    & .collapsedIcon {
        position: absolute;
        top: 0;
        right: 0;
        margin: 16px;
        cursor: pointer;
    }

    & .title {
        display: flex;
        align-items: center;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: var(--gray800);
        padding-bottom: 24px;
        cursor: default;

        & .aiIcon {
            margin-right: 4px;
            stroke: var(--gray800);
        }

        & .filtersIndication {
            font-weight: normal;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin-left: 8px;
            gap: 8px;

            & .clearAll {
                color: var(--blue300);
                display: flex;
                align-items: center;
                border-left: 1px solid var(--gray500);
                cursor: pointer;

                &.disabled {
                    cursor: none;
                    pointer-events: none;
                    color: var(--gray500);

                    & .clearIcon {
                        fill: var(--gray500);
                    }
                }

                & .clearIcon {
                    fill: var(--blue300);
                    margin: 0 8px;
                }
            }
        }
    }
}
