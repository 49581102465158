@import "../global/theme.css";

a {
  cursor: pointer;
}

body {
  height: 100%;
  background-color: var(--bodyBackgroundColor);
  margin: 0;
  overflow-x: hidden;
  font-family: 'open-sans','Helvetica Neue', 'Helvetica', sans-serif;
  @apply --font-paragraph;
}

