@import "../../global/theme.css";

.pageContainer {
    display: flex;
    flex-direction: column;
    border: var(--container-border);
    background-color: var(--white);
    margin: 20px;
}

.pageTitle {
    padding: 35px 10px 0 35px;
    @apply (--2nd-title);
}

.pageTitleBorder {
    border-bottom: var(--container-border);
    margin: 0 10px 0 27px;
}

.pageSubTitle {
    padding: 35px 10px 20px 35px;
}