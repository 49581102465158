@import "../../components/Main.css";

.page {
    padding: 30px 30px 80px;
    color: var(--gray700);
    margin: 0 20px 30px 20px;
    & tr {
        height: 50px;
    }
}

.shelfTitle{
    font-weight: bold;
    margin-bottom: 10px;
}

.description{
    margin-bottom: 30px;
}

.dataSection{
    padding-top: 20px;
    background-color: var(--white);
}

.tableContainer{
    margin-top: 20px;
    max-height: 50px;
    padding: 0 10px 30px 10px;
    overflow-y: scroll;
}

.thead {
    position: sticky;
    font-weight: bold;
}

.tbody{
    text-align: center;
    border-bottom: solid 1px var(--gray200);;
}

.monthDiv {
    display: flex;
    margin: 0 auto 0 auto;
    max-width: fit-content;
    padding-bottom: 20px;

}

.dataDestinationShelf {
    padding: 30px 30px 80px;

    & .field {
        margin-top: 20px;
    }

    & .fieldText {
        margin-top: 10px;
        display: flex;
        font-weight: 400;
        color: var(--gray600);
        font-size: 13px;

        & .mandatory {
            color: var(--red300);
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
        }

        & > * {
            margin-left: 6px;
        }
        & > *:first-child {
            margin-left: 0;
        }
    }

    & .schemaField {
        border-top: 1px solid var(--gray400);
        margin-top: 20px;
    }

    & .sheetsTable {
        padding-bottom: 10px;
    }
}

.addSheetDiv {
    padding: 15px 0 0 20px;
    background-color: var(--gray50);
    width: 100%;
    min-height: 55px;
}

.sheetName {
    margin-top: 3px;
}

.tableButton {
    margin-left: 15px;
    height: 25px !important;
}

.spinnerIcon {
    height: 26px;
    width: 26px;
}

.checkmark {
    fill: var(--primaryColor);
}