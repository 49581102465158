@import "../../../global/theme.css";

.content {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 70px);

    & .subDomainInputContainer {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        & > *:not(last-child) {
            margin-right: 10px;
        }

        & .subDomainInput, & .subDomainInput:not(:last-child) {
            width: 250px;
            margin-bottom: 0;
        }

        & .dnsZones {
            position: relative;
            width: 150px;
        }

        & .addButton {
            margin-left: auto;
        }
    }

    & .subDomainItemHolder {
        margin-top: 10px;
    }

    & .warningMessage {
        margin-top: 10px;
    }
}
